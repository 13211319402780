.FileImage__IconFileWrapper {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 10;
}

.FileImage__IconFileWrapper .inner-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.FileImage__IconFileWrapper .inner-wrapper .component__text {
    font-size: 10px;
    line-height: 1.1;
    text-align: center;
}

.FileImage__IconFileWrapper svg {
    fill: #00AAE7;
    margin: auto;
    margin-bottom: 0.25rem;
    height: 1.875rem;
}

.FileImage__IconFileWrapper img {
    max-width: 8rem;
    max-height: 8rem;
}