.ContactCustomerCare .Container {
    padding-top: 1rem;
    max-width: 340px;
}

.ContactCustomerCare .Container>.title {
    margin-bottom: 2rem;
}

.ContactCustomerCare .ContactWrapper {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;
    width: 100%;
}

.ContactCustomerCare .ContactOption .title {
    font-size: 1rem;
    color: var(--primary-color);
}

.ContactCustomerCare .OptionCard {
    padding: 1.125rem;
    border-radius: 0.25rem;
    box-shadow: 0 0 12px rgba(0, 0, 0, .2);
}

.ContactCustomerCare .component__text {
    color: inherit;
    font-weight: 400;
}

.ContactCustomerCare .OptionCard .component__text:first-of-type, .ContactCustomerCare .OptionCard .component__text:nth-of-type(3) {
    margin-bottom: 0.75rem;
    font-weight: 400;
}

.ContactCustomerCare .OptionCard .phoneNumber {
    font-size: 1.25rem;
    font-weight: bold;
    color: var(--primary-color);
    text-decoration: underline 3px;
    margin-top: 0.75rem;
}