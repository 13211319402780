.ConfirmationModal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 1.25rem;
    line-height: 2rem;
    padding-top: 1.5rem;
}

.ConfirmationModal h1 {
    font-size: 1.5rem;
    font-weight: 700;
}
  
.ConfirmationModal .CloseButton span{
      position: absolute;
      top: 1rem;
      right: 1rem;
      padding: 0.2rem;
      margin-left: 3rem;
      color: var(--primary-color);
      font-weight: bolder;
      font-size: 2rem;
      cursor: pointer;
}

.secondary {
    background-color: transparent;
}

.noMarginTop {
    margin-top: 0 !important;
}