.TransitionMessage {
    margin-bottom: 1.875rem;
}

.TransitionMessage .congrats-message {
    background-color: var(--lightGreen);
}

.TransitionMessage .failed-message {
    background-color: #FFF;
    color: var(--orangeWarning);
}

.TransitionMessage .icon {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--primary-color);
    border-radius: 50%;
    padding: 5px;
    height: 10px;
    width: 10px;
}

.TransitionMessage .congrats {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    margin-left: 10px;
    font-weight: bold;
    color: var(--primary-color);
    line-height: 0;
    margin-bottom: -4px;
    font-size: 1rem;
}

.TransitionMessage .congrats-message {
    color: var(--primary-color);
}

.TransitionMessage .successfully-inner-container {
    padding: 20px;
    background-color: var(--lightGreen);
    border-radius: 0.3125rem;
}

.TransitionMessage .failed-innder-container {
    background-color: #333;
}

.TransitionMessage .TransitionMessageHeader {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 10px;
}