.PetcoLogoSharedComponent-v2 .Container .top-logo-wrapper {
    justify-content: center;
}

.pet-selection-page-v2 {
    padding-bottom: 1rem;
}

@media (max-width: 670px) {
    .pet-selection-page-v2 .back-to-container {
        margin-left: 5px;
    }


}

@media (min-width: 670px) {
    .pet-selection-page-v2 {
        padding-left: 1rem;
        padding-right: 1rem;
    }
}

@media (min-width: 670px) {
    .pet-selection-page-v2 .Container {
        width: 60rem;
        max-width: 100%;
    }
}

.pet-selection-page-v2 .Container>.title {
    font-size: 1.5625rem;
    font-weight: 700;
    line-height: 2rem;
    letter-spacing: 0.25px;
    color: #333333;
    text-align: center;
    padding-bottom: 38px;
}

.pet-selection-page-v2 .Container>.component__text {
    margin-bottom: 1rem;
    font-size: var(--paragraph);
}

.pet-selection-page-v2 .Container>.component__text {
    text-align: center;
}

.pet-selection-page-v2 .Container .TagInfo {
    display: block;
    max-width: 16.875rem;
    margin: 0.9375rem auto 1.875rem;
    text-align: center;
}

.pet-selection-page-v2 .Container .component__button {
    margin-top: 0.375rem;
}

.pet-selection-page-v2 .Container .component__button.button-active {
    color: white;
    background-color: var(--primary-color);
}

@media (min-width: 670px) {
    .pet-selection-page-v2 .Container .component__button {
        max-width: 18.75rem;
        margin-top: 1.5rem;
        margin-right: auto;
        margin-left: auto;
    }
}

.pet-selection-page-v2 .Container .component__button.Button--Dark {
    margin-top: 1rem;
    background-color: #0a598f;
    color: white;
}

.pet-selection-page-v2 .add-new-pet-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
    margin-top: 30px;
    text-align: center;
}

@media only screen and (min-width: 670px) {
    .pet-selection-page-v2 .add-new-pet-container {
        padding-left: 0;
        padding-right: 0;
    }
}

.pet-selection-page-v2 .add-new-pet-spans {
    min-width: 70px;
    flex-grow: 2;
}

.pet-selection-page-v2 .add-new-pet-text {
    min-width: 110px;
    color: #000;
    font-size: 0.75rem;
    font-weight: 700;
    line-height: 1.125rem;
    letter-spacing: 2px;
    text-transform: uppercase;
}

.pet-selection-page-v2 .cancel-button-container {
    padding-left: 16px;
    padding-right: 16px;
}

.pet-selection-page-v2 .join-now-button {
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.375rem;
    letter-spacing: 1px;
    text-transform: uppercase;
    width: 340px;
    max-width: 100%;
}

.pet-selection-page-v2 .join-now-button.mt-24 {
    margin: 24px auto 41px;
}

.pet-selection-page-v2 .join-now-button.mt-34 {
    margin: 34px auto 41px;
}

.pet-selection-page-v2  .BackButton {
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 1.25rem;
    letter-spacing: 0.25px;
    color: #011954;
    margin-top: 0;
}

.pet-selection-page-v2  .BackButton p {
    margin-top: 0;
}

.pet-selection-page-v2 .Button-v2.secondary {
    margin: 12px auto;
    line-height: 1.375rem;
    letter-spacing: 1px;
}

@media (max-width: 420px) {
    .pet-selection-page-v2 .Container {
        padding: 0;
    }
}

@media (max-width: 576px) {
    .pet-selection-page-v2 .Container {
        max-width: 340px;
        padding: 0 6px;
    }
}

@media (max-width: 670px) {
    .pet-selection-page-v2 .Container>.title {
        margin-bottom: 29px;
    }
    .pet-selection-page-v2  .BackButton {
        margin-top: 21px;
    }
}
