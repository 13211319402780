.ToggleableCheckbox {
    min-width: 99px;
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    justify-content: space-between;
}
.ToggleableCheckbox .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}

.ToggleableCheckbox .switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.ToggleableCheckbox .checkbox-label.unchecked {
    color:   #656565;
}
.ToggleableCheckbox .checkbox-label {
    font-weight: 700;
    width: 40px;
    font-size: 1rem;
    line-height: 1.375rem;
    color: var(--primary-color);
    text-align-last: center;
    margin-bottom: 5px;
    letter-spacing: 1px
}

.ToggleableCheckbox .slider img{
    left: 36px;
    position: absolute;
    top: 10px;
}

.ToggleableCheckbox .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
    max-height: 30px;
}

.ToggleableCheckbox .slider:before {
    position: absolute;
    content: "";
    height: 32px;
    width: 32px;
    left: -2px;
    bottom: -3px;
    background-color: white;
    transition: .4s;
}

.ToggleableCheckbox input:checked+.slider {
    background-color: var(--primary-color);;
}

.ToggleableCheckbox input:focus+.slider {
    box-shadow: 0 0 1px var(--primary-color);;
}

.ToggleableCheckbox input:checked+.slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

/* Rounded sliders */

.ToggleableCheckbox .slider.round.checked {
    border-radius: 32px;
    margin-right: 0;
    background:  var(--primary-color);;
    border: 1.71429px solid var(--primary-color);;
}

.ToggleableCheckbox .slider.round.unchecked {
    border-radius: 32px;
    margin-right: 0;
    background: #656565;
    border: 1.71429px solid #656565;
}

.ToggleableCheckbox .slider.round:before {
    border-radius: 50%;
    border: 1.71429px solid var(--primary-color);;
}

.ToggleableCheckbox .slider.round.unchecked:before {
    border-radius: 50%;
    border: 1.71429px solid  #656565;
}

.ToggleableCheckbox .inactive-toggle {
    background-color: rgb(204, 204, 204) !important;
    pointer-events: none;
}
.ToggleableCheckbox input:focus+.slider {
    outline: 3px solid var(--outline-color);
    outline-offset: 3px;
}