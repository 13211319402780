
.Container.pet-creation-page{
  max-width: 46.125rem;
}
.pet-creation-page .page-title {
  margin-bottom: 0.75rem;
  padding: 0;
  text-align: center;
  color: #000;
  margin-top: 33.63px;
}

.pet-creation-page .page-title.UM-title {
  margin-top: 29px;
  margin-bottom: 0.563rem;
  color: #333;
}

.pet-creation-page .subtitle {
  text-align: center;
  color: var(--primary-color);
}
.pet-creation-page .subtitle {
  text-align: center;

  margin-bottom: 0.75rem;
}

.pet-creation-page .text {
  color: var(--primary-color);
  font-size: 0.875rem;
  text-align: center;
}

.pet-creation-page .bottom {
  text-align: center;
  margin-top: 1.5625rem;
  color: var(--primary-color);
}

@media (max-width: 670px) { 
  .pet-creation-page .page-title {
    margin-bottom: 7px;
  }
  .pet-creation-page .page-title.UM-title {
    margin-top: 24px;
    margin-bottom: 7px;
  }
}
