.Card {
    border-radius: 0.375rem;
}


@media (min-width: 992px) {
    .Card {
        max-width: 61.5rem;
    }
}

.Card__Header {
    display: flex;
    align-items: center;
    height: 2.9375rem;
    padding-left: 1.25rem;
    font-size: 1.25rem;
    color: black;
    font-weight: 'bold';
}


/* ${({ contentAlign }) => contentAlign === 'center' && `
display: flex;
justify-content: center;
padding: 0;
`}
`; */

.Card__Body {
    padding: 1.25rem;
}


/* ${({ noPadding }) => noPadding && `padding: 0`}; */
