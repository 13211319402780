.ClaimWrapper {
    max-width: 71.25rem;
    margin: 0 auto;
    padding-right: 1rem;
    padding-left: 1rem;
}

@media (min-width: 670px) {
    .ClaimWrapper {
        padding-right: 1rem;
        padding-left: 1rem;
    }
}