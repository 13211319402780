.SelectPaymentMethod button:nth-of-type(2) {
    margin-top: 15px;
}

.SelectPaymentMethod .UM_ActivationErrorMessage{
    background-color: #FBEEEC;
}

.aci-payment-frame {
    min-height: 730px;
    height: auto;
    width: 100%;
}

.aci-payment-modal .modal-dialog {
    min-width: 320px;
    max-width: 720px;
    width: 70%;
}


.PaymentModalHeader {
    text-align: center;
    font-weight: bold;
}