.NRCFrameWrap {
    position: relative;
}

.NCR-help-text {
    padding: 12px;
    margin-top: 16px;
    margin-bottom: 16px;
    border-radius: 5px;
    font-size: 12px;
    font-weight: bold;
    line-height: 1.5;
    color: var(--primary-color);
    background-color: var(--primary-color-five);
  }

/* iframe element */
.NRCFrame  {
    position: relative;
    background-color: white;
}
