.Subtotal-v2 {
    display: flex;
    justify-content: space-between;
    margin-top: 0.5rem;
    font-size: 1.375rem;
    font-weight: 700;
    flex-wrap: wrap;
}

.Subtotal-v2 .subtotal-text, .Subtotal-v2 .price {
    font-size: 1.25rem;
    color: var(--primary-color);
    line-height: 1.5rem;
}

.Subtotal-v2 .styled {
    color: #000000;
}

.Subtotal-v2 .styled.price {
    color: #000000;
}

.Subtotal-v2>div {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.Subtotal-v2>div .type {
    font-size: 0.75rem;
    color: #AFAFAF;
}

.Subtotal-v2 .price--pre-discount {
    text-decoration: line-through;
    margin-left: 5px;
    display: none;
}

.tenure {
    font-size: 1.25rem;
}
@media screen and (max-width:370px) {
    .Subtotal-v2>span {
        font-size: 1rem;
    }
    .Subtotal-v2 .price{
        font-size: 1rem;
    }
}

@media screen and (max-width:670px) {
    .finishtYourPurchase-mt {
        margin-top: 3.563rem;
    }
    .Subtotal-v2 .price--pre-discount {
        /*display: none;*/
    }
    .Subtotal-v2 .price{
        font-size: 1.25rem;
        line-height: 1.5rem;
    }

}
