.back-to-desktop-wrapper {
  display: flex;
  justify-content: end;
}

.back-to-container {
  margin-top: 21px;
  margin-bottom: 15px;
  position: relative;
  height: 18px;
  min-width: 122px;
}

.back-to {
    width: 100%;
    height: 18px;
    display: flex;
    line-height: 1;
    margin-bottom: 10px;
    cursor: pointer;
    color: var(--primary-color);
    align-items: center;
}


.back-to:focus{
  outline-color: var(--outline-color);
}

@media only screen and (max-width: 670px) {
  .back-to h4 {
    max-width: 100%;
  }

  .back-to-desktop-wrapper {
    justify-content: start;
  }
}

.back-to p {
  font-size: 0.75rem;
  font-family: var(--primary-font-bold);
  line-height: 1;
  margin-left: 0.313rem;
  font-weight: bold;
  color: var(--primary-color);
}