.RedemptionDashboardPage-v2 {
  height: auto;
}

@media (min-width: 992px) {

    .RedemptionDashboardPage-v2>.Container>.Card>.List {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-height: 12rem;
  }

  .RedemptionDashboardPage-v2>.Container>.Card>.List>.List__Item {
    display: flex;
    width: 50%;
    height: 4rem;
  }

  .RedemptionDashboardPage-v2>.Container>.Card>.List>li {
    display: flex;
    width: 50%;
    height: 4rem;
  }

  .RedemptionDashboardPage-v2>.Container>.Card>.List>li {
    display: flex;
    width: 50%;
    height: 4rem;
  }

  .RedemptionDashboardPage-v2>.Container>.Card>.List>.List__Item:last-of-type {
    justify-self: left;
    /* margin-left: auto; */
  }
}

@media (min-width: 670px) {
  .RedemptionDashboardPage-v2 .dashboard-welcome-container {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
  }

  .RedemptionDashboardPage-v2 .dashboard-welcome-message-container {
    margin: 0;
  }

  .RedemptionDashboardPage-v2 .dashboard-welcome-message-container h1 {
    margin: 0;
    padding: 0;
  }

  .RedemptionDashboardPage-v2 .dashboard-welcome-container .PetcoLogoSharedComponent .Container .top-logo-wrapper {
    padding: 0.625rem 0rem;
  }
}

@media (max-width: 670px) {
  .RedemptionDashboardPage-v2 .dashboard-welcome-container {
    display: flex;
    flex-direction: column;
  }

  .RedemptionDashboardPage-v2 .dashboard-welcome-message-container {
    margin: 0;
  }

  .RedemptionDashboardPage-v2 .Container.dashboard-welcome-container {
    padding: 0;
    margin: 0;
  }

  .RedemptionDashboardPage-v2 .dashboard-welcome-message-container h1 {
    margin-top: 14px;
  }
}

@media (min-width: 992px) {
  .RedemptionDashboardPage-v2 {
    padding-bottom: 3.75rem;
  }
}

.RedemptionDashboardPage-v2 .Container {
  max-width: 37.5rem;
}

.RedemptionDashboardPage-v2 .Container .Card {
  margin: 0 auto 1rem auto;
}

.RedemptionDashboardPage-v2 .Container .Card .Card__Header .usage-wrapper {
  padding-top: 16px;
}

.RedemptionDashboardPage-v2 .Card svg path {
  fill: var(--primary-color);
}

.RedemptionDashboardPage-v2 button {
  font-weight: 600;
}

.RedemptionDashboardPage-v2 .congratulations-text {
  margin-left: 1rem;
  font-size: 0.9375rem;
  font-weight: 600;
  color: var(--primary-color);
}

@media (min-width: 993px) {
  .RedemptionDashboardPage-v2 .Card {
    width: 100%;
    margin-right: 0;
    margin-left: 0;
  }
}

.RedemptionDashboardPage-v2 .manage {
  margin-top: 1.875rem;
}

@media (min-width: 992px) {
  .RedemptionDashboardPage-v2 .manage {
    margin-left: 1.6875rem;
  }

  div.RedemptionDashboardPage-v2>div.Container.dashboard-welcome-container {
    max-width: 61.5rem;
    margin: 28px auto;
  }

  div.RedemptionDashboardPage-v2>div.DashboardHeader-v2>div>div {
    width: auto;
  }

  .RedemptionDashboardPage-v2 .Container {
    max-width: 61.5rem;
  }

  .RedemptionDashboardContainer-v2>.Card {
    max-width: 61.5rem;
  }
}

.RedemptionDashboardPage-v2 .List__Item {
  min-height: 2.375rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  width: 100%;
  background-color: transparent;
  border-top: none;
  border-right: none;
  border-left: none;
}

.RedemptionDashboardPage-v2 .List__Item .addNewPet img {
  width: 32px;
  margin-left: -4px;
  margin-right: -5px;
}

.RedemptionDashboardPage-v2 .List__Item .call img {
  padding: 0 2px;
}

.RedemptionDashboardPage-v2 .List__Item .heart img {
  width: 24px;
}

.RedemptionDashboardPage-v2 .List__Item span {
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.375rem;
  letter-spacing: 0.5px;
  color: #001952;
  text-align: left;
}

.RedemptionDashboardPage-v2 .List__Item span:first-of-type button {
  border: none;
}

.RedemptionDashboardPage-v2 .List .List__Item:first-of-type {
  border-top: none;
}

.RedemptionDashboardPage-v2 .List__Item .Icon:first-of-type {
  margin-right: 0.75rem;
}

.RedemptionDashboardPage-v2 .List__Item .alignRight {
  margin-left: auto;
  margin-right: 0;
}

.RedemptionDashboardPage-v2 .List__Item .blueColorText {
  color: var(--primary-color);
}

.RedemptionDashboardPage-v2 .List__Item .blueColorText.noPointer {
  cursor: unset;
}

.RedemptionDashboardContainer-v2 .switchToYearlyContainer {
  width: auto;
  background-color: #DAF1E6;
  padding: 19px 24px 12px 24px;
  display: flex;
  flex-wrap: wrap;
  gap: 0 24px;
}

.RedemptionDashboardContainer-v2 .switchToYearlyContainer .iconContainer {
  margin-top: 10px;
}

.RedemptionDashboardContainer-v2 .switchToYearlyContainer .txtDescription {
  margin-top: 10px;
}

.RedemptionDashboardContainer-v2 .switchToYearlyContainer .txtDescription :first-child {
  font-weight: 700;
  font-size: 1.5625rem;
  line-height: 1.875rem;
  letter-spacing: 0.25px;
  color: #001952;
  position: relative;
  margin-bottom: 12px;
}

.RedemptionDashboardContainer-v2 .switchToYearlyContainer .txtDescription :last-child {
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 1.656rem;
  color: #001952;
}

.RedemptionDashboardContainer-v2 .switchBtnContainer {
  display: flex;
  flex-direction: column;
}

.RedemptionDashboardContainer-v2 .switchToYearlyContainer .txtLearnMore {
  text-align: center;
  position: relative;
  line-height: 22px;
  letter-spacing: 0.15px;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 5px;
}

.RedemptionDashboardContainer-v2 .switchToYearlyContainer .switchNowBtn {
  width: 342px;
  height: 56px;
  background-color: #001952;
  border-radius: 5px;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.375rem;
  letter-spacing: 1px;
  text-transform: uppercase;
  border: none;
  cursor: pointer;
  color: #FFFFFF;
  position: relative;
  margin-bottom: 5px;
}

.RedemptionDashboardContainer-v2 .switchToYearlyContainer .txtLearnMore {
  text-align: center;
  position: relative;
  line-height: 1.375rem;
  letter-spacing: 1px;
  font-size: 1rem;
  font-weight: 700;
  margin-bottom: 5px;
}

.switchToYearlyPaymentPlanDetails {
  display: flex;
  justify-content: center;
}

/* Mobile responsive style below */
@media only screen and (max-width: 670px) {
  .RedemptionDashboardContainer-v2 .switchToYearlyContainer {
    width: auto;
    flex-wrap: wrap;
    padding: 18px 51px 16px 51px;
  }

  .RedemptionDashboardContainer-v2 .switchToYearlyContainer .txtDescription {
    margin-right: 0px;
    min-width: 0px;
    margin-left: 0px;
    text-align: center;
  }

  .RedemptionDashboardContainer-v2 .switchToYearlyContainer .txtDescription :first-child {
    font-weight: 900;
    font-size: 1rem;
    line-height: 1.375rem;
    letter-spacing: 0.5px;
    position: relative;
    margin-bottom: 8px;
  }

  .RedemptionDashboardContainer-v2 .switchToYearlyContainer .txtDescription :last-child {
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #001952;
    letter-spacing: 0.25px;
    white-space: nowrap;
    position: relative;
    margin-bottom: 8px;
  }

  .RedemptionDashboardContainer-v2 .switchToYearlyContainer .switchNowBtn {
    width: 273px;
    height: 48px;
    margin-bottom: 0px;
  }

  .RedemptionDashboardContainer-v2 .switchToYearlyContainer .txtLearnMore {
    font-size: 0.875rem;
    letter-spacing: 0.5px;
    bottom: 0px;
    margin-right: 0px;
    margin-top: 12px;
    margin-bottom: 0;
  }
}

.RedemptionDashboardPage-v2 .PetcoLogoSharedComponent-v2 .Container .top-logo-wrapper .UM-logo {
  width: 270px;
  height: 36px;
}

.RedemptionDashboardPage-v2 .PetcoLogoSharedComponent-v2 .Container .top-logo-wrapper .UM-logo img {
  max-width: 100%;
  max-height: 100%;
}

@media only screen and (max-width: 932px) {
  .RedemptionDashboardContainer-v2 .switchToYearlyContainer .iconContainer {
    position: absolute;
    left: 22px;
    top: 10px;
  }

  .RedemptionDashboardContainer-v2 .switchBtnContainer {
    padding: 0px 0px 0px 0px;
  }
}

@media only screen and (max-width: 991px) {
    .RedemptionDashboardContainer-v2 .switchToYearlyContainer {
      position: relative;
      justify-content: center;
    }

    .RedemptionDashboardContainer-v2 .switchToYearlyContainer .txtDescription {
        text-align: center;
        position: static;
        min-width: auto;
        margin: 0;
    }

    .RedemptionDashboardContainer-v2 .switchToYearlyContainer .txtDescription :last-child {
        margin-bottom: 8px;
    }

    .RedemptionDashboardContainer-v2 .switchToYearlyContainer .iconContainer {
        position: absolute;
        left: 22px;
        top: 10px;
    }
}

@media only screen and (min-width: 992px) {
    .RedemptionDashboardContainer-v2 .switchBtnContainer {
    margin-left: auto;
    }
}
