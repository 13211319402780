.ClaimsVetSelectionPage-v2 {
    padding: 1rem 0;
}

.ClaimsVetSelectionPage-v2 .ClaimsVetSelectionPage__Container-v2 {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    max-width: 44.5rem;
    padding: 0;
}

.ClaimsVetSelectionPage-v2 .PetcoLogoSharedComponent-v2 {
    display: none;
}

.ClaimsVetSelectionPage-v2 .ClaimsVetSelectionPage__Container-v2 .Icon {
    align-self: center;
}

.ClaimsVetSelectionPage-v2 .title {
    padding-top: 0;
    font-size: 1.563rem;
    line-height: 2rem;
    color: var(--primary-color);
    letter-spacing: 0.25px;
    font-weight: 700;
}

.ClaimsVetSelectionPage-v2 .MembershipFloatLabelInput .MembershipFloatLabelInput__input {
    font-weight: 400;
    line-height: 1.375rem;
    letter-spacing: 0.5px;
}

.ClaimsVetSelectionPage-v2 .MembershipFloatLabelInput .MembershipFloatLabelInput__label {
    font-weight: 400;
    line-height: 1.125rem;
    letter-spacing: 0.4px;
}

.ClaimsVetSelectionPage-v2 .vet-office-wrapper {
    margin-top: 15px;
    margin-bottom: 5px;
}

.ClaimsVetSelectionPage-v2 .vet-office-text {
    color: var(--primary-color);
    font-weight: bold;
    font-size: 0.9375rem;
    display: contents;
}

.ClaimsVetSelectionPage-v2 .search-form {
    width: 100%;
}

.ClaimsVetSelectionPage-v2 .CMEmb {
    width: unset;
    padding-top: 0.8125rem;
    padding-bottom: 0.8125rem;
}

.ClaimsVetSelectionPage-v2 label {
    color: var(--primary-color);
}

.ClaimsVetSelectionPage-v2 label span {
    margin-right: 4px;
    color: red;
}

.ClaimsVetSelectionPage-v2 .results {
    font-size: 0.75rem;
    margin: 1rem 0;
    color: #656565;
    font-weight: 700;
    letter-spacing: 0.4px;
    line-height: 1.125rem;
}

.ClaimsVetSelectionPage-v2 .add-clinic-link:focus {
    outline: none;
}

.ClaimsVetSelectionPage-v2 .add-clinic-link:hover {
    cursor: pointer;
}

.ClaimsVetSelectionPage-v2 .red {
    color: red;
}

.ClaimsVetSelectionPage-v2 .no-results-list {
    margin-top: 0.625rem;
}

.ClaimsVetSelectionPage-v2 .vet-clinic-list li {
    list-style: none;
}

.ClaimsVetSelectionPage-v2 .item-wrapper {
    border-top: 1px solid #C4C4C4;
    padding: 1rem 0;
}

.ClaimsVetSelectionPage-v2 .border-gray {
    border-bottom: 1px solid #C4C4C4;
}

.ClaimsVetSelectionPage-v2 .item-wrapper .item-title {
    font-weight: 700;
    color: #656565;
    text-align: left;
    font-size: 1rem;
    margin-bottom: 0.5rem;
    line-height: 1.125rem;
    letter-spacing: 0.4px;
}

.ClaimsVetSelectionPage-v2 .item-title-container {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}

.ClaimsVetSelectionPage-v2 .item-wrapper .item-description {
    line-height: 1.25rem;
    color: #656565;
    font-size: 1rem;
    height: 20px;
    font-weight: 400;
    letter-spacing: 0.4px;
}

.ClaimsVetSelectionPage-v2 .item-wrapper .item-description--nowrap {
    white-space: nowrap;
    margin-left: 0.5rem;
    line-height: 1.125rem;
}

.ClaimsVetSelectionPage-v2 .item-wrapper .item-description.item-description-phone {
    color: var(--primary-color);
}

.ClaimsVetSelectionPage-v2 .item-wrapper label {
    margin: auto;
}

.ClaimsVetSelectionPage-v2 #vet-clinic {
    padding: unset;
}

.ClaimsVetSelectionPage-v2 input[type="radio"] {
    height: 1.5rem;
    width: 1.5rem;
}

.ClaimsVetSelectionPage-v2 .load-more-button {
    margin-top: 1rem;
    font-weight: 700;
    letter-spacing: 1px;
    line-height: 1.375rem;
}

.ClaimsVetSelectionPage-v2 .next-button {
    margin-top: 20px;
    background-color: var(--primary-color);
}

.ClaimsVetSelectionPage-v2 .add-clinic-link {
    margin: 1.25rem 0rem;
    text-align: center;
    font-weight: 700;
    line-height: 1.375rem;
    letter-spacing: 0.5px;
}

.ClaimsVetSelectionPage-v2 .load-more-button span {
    width: 100%;
}

.ClaimsVetSelectionPage-v2 .select-vet-button {
    margin-bottom: 0;
    max-width: 100%;
    font-weight: 700;
    letter-spacing: 1px;
    line-height: 1.375rem;
}

.ClaimsVetSelectionPage-v2 .select-vet-button-div-parent{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 56px;
    border: 0;
    border-radius: 5px;
    box-sizing: border-box;
    font-size: 1rem;
    font-weight: 700;
    text-align: center;
    text-decoration: none;
    color: #FFFFFF;
    background-color: var(--primary-color);
    cursor: pointer;
    margin: 9px auto;
    margin-bottom: 0;
    max-width: 100%;
    font-weight: 700;
    letter-spacing: 1px;
    line-height: 1.375rem;
}

.ClaimsVetSelectionPage-v2 .persisted-clinic {
    margin: 0 auto;
    width: 100%;
}

.ClaimsVetSelectionPage-v2 .persisted-clinic .item-wrapper {
    border: none;
}

#no-padding-top {
    padding-top: 0;
}

.persisted-clinic-line {
    position: relative;
    border-top: 1px solid #E5E5E5;
    width: 100%;
    margin: 0.5rem 0 1.5rem;
    text-align: center;
    font-family: var(--primary-font-bold);
}

.persisted-clinic-line span {
    position: absolute;
    top: -8px;
    left: 50%;
    transform: translateX(-50%);
    width: 40px;
    font-family: var(--primary-font-bold);
    background-color: #ffffff;
}

@media (min-width: 760px) {
    .ClaimsVetSelectionPage-v2 .PetcoLogoSharedComponent-v2 {
        display: block;
        margin-top: -40px;
    }

    .ClaimsVetSelectionPage-v2 .ClaimsVetSelectionPage__Container-v2 {
        max-width: 70rem;
    }

    .ClaimsVetSelectionPage-v2 .MembershipFloatLabelInput {
        width: 344px;
        margin: 0 auto;
    }

    .ClaimsVetSelectionPage-v2 .title {
        margin: 0 auto;
    }

    .ClaimsVetSelectionPage-v2 .title {
        text-align: center;
    }

    .ClaimsVetSelectionPage-v2 .component__text {
        margin-left: auto;
        margin-right: auto;
    }

    .ClaimsVetSelectionPage-v2 .vet-clinic-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .ClaimsVetSelectionPage-v2 .vet-clinic-list li {
        width: 342px;
    }

    .ClaimsVetSelectionPage-v2 .results {
        text-align: center;
    }

    .ClaimsVetSelectionPage-v2 .persisted-clinic {
        max-width: 344px;
    }
}