.CardPlan {
	border-radius: 0.25rem;
	box-shadow: 0.25rem 0.25rem 0.25rem rgba(0, 0, 0, 0.07);
	overflow: hidden;
}

.CardPlan .CardPlan__Header {
    display: flex;
    align-items: center;
    height: 2.9375rem;
    padding-left: 1.25rem;
    font-size: 1.25rem;
    background-image: linear-gradient(120deg, #555555, #888888);
    color: white;
}

.CardPlan.alignCenter {
    display: flex;
    justify-content: center;
    padding: 0;
}

.CardPlan .CardPlan__Body {
	padding: 1.25rem;
}

.CardPlan .CardPlan__Body.noPadding {
    padding: 0;
}