.FileImageWrapper .border {
    border: dashed 0.125rem #C4C4C4;
}

.FileImageWrapper img {
    max-width: 7rem;
    width: 100%;
    height: 9.375rem;
    object-fit: cover;
    object-position: center;
}

.FileImageWrapper #remove-button {
    margin-top: 0.5rem;
    padding: 0;
    border: 0;
}

.FileImage__IconFileWrapper {
    width: 100%;
    height: 9.375rem;
    object-fit: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 10;
}

.FileImage__IconFileWrapper .inner-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.FileImage__IconFileWrapper svg {
    fill: #00AAE7;
    margin: auto;
    margin-bottom: 0.25rem;
    height: 1.875rem;
}