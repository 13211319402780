.MembershipInfoPage-v2 {
    height: auto;
    margin-bottom: 1.5rem;
}

.MembershipInfoPage-v2 .PageHeader {
    padding: 1rem;
    display: flex;
    flex-direction: column-reverse;
}

.MembershipInfoPage-v2 .PageHeader .title {
    margin-top: 1.5rem;
}

.MembershipInfoPage-v2 .PageHeader .title.UM-Title {
    font-size: 1.563rem;
    line-height: 2rem;
    color: var(--primary-color);
    letter-spacing: 0.25px;
}

.MembershipInfoPage-v2 .PageHeader .petcoVitalCareLogo img {
    width: 166px;
    height: 34px;
    display: block;
}

.MembershipInfoPage-v2 .PageHeader .petcoVitalCareLogo.UM-logo img{
    width: 270px;
    height: 36px;
}

.MembershipInfoPage-v2 .QuoteSelection .Table__Data--ColouredBackground.UM-Background {
    background-color: #DAF1E6;
    line-height: 1.063rem;
    font-weight: 400;
}

@media (min-width: 670px) {
    .MembershipInfoPage-v2 .PageHeader {
        margin: 0 auto;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
}

.MembershipInfoPage-v2 .Container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: auto;
    margin: 0 auto;
    padding-right: 1rem;
    padding-left: 1rem;
}

.MembershipInfoPage-v2 .Container .title,
.MembershipInfoPage-v2 .Container .component__text {
    color: (--primary-color);
}

.MembershipInfoPage-v2 .Container .title {
    font-size: var(--header1);
    line-height: 1.2;
}

.MembershipInfoPage-v2 .Container .component__text {
    font-size: var(--paragraph);
}

.Benefit__Title {
    font-size: 0.75rem;
    word-break: keep-all;
}

@media (min-width: 670px) {

    .MembershipInfoPage-v2 .Container,
    .MembershipInfoPage-v2 .PageHeader {
        max-width: 55.5rem;
    }

    .MembershipInfoPage-v2 .Container {
        padding-right: 0;
        padding-left: 0;
    }
}

.MembershipInfoPage-v2 .Container .CardPlan {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
}

.MembershipInfoPage-v2 .Container .Button--Dark {
    width: 100%;
    margin-top: 16px;
    background-color: var(--primary-color);
    color: white;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 1.5rem;
    line-height: 1.375rem;
    letter-spacing: 1px;
}

@media (min-width: 670px) {
    .MembershipInfoPage-v2 .Container .Button--Dark {
        max-width: 342px;
        margin-top: 1.5rem;
    }
}

@media (min-width: 992px) {
    .MembershipInfoPage-v2 .Container .component__text {
        text-align: center;
    }
}

.MembershipInfoPage-v2 .CoreCareCardPlan {
    position: relative;
    align-self: center;
    width: 100%;
}

@media (min-width: 992px) {
    .MembershipInfoPage-v2 .CoreCareCardPlan {
        margin-top: 1.25rem;
    }
}

.MembershipInfoPage-v2 .CoreCareCardPlan .CardPlan {
    position: relative;
    background-color: white;
}

.MembershipInfoPage-v2 .CoreCareCardPlan .CardPlan__Body {
    position: relative;
    z-index: 1;
}

.MembershipInfoPage-v2 .MembershipPrice {
    display: flex;
    justify-content: center;
    margin-bottom: 16px;
    font-size: 1.875rem;
    font-weight: bold;
    color: var(--primary-color);
}

.MembershipInfoPage-v2 .QuoteSelection__BenefitHeader {
    padding: 1.5rem 0;
}

.MembershipInfoPage-v2 .QuoteSelection__BenefitText {
    font-size: 1rem;
    font-weight: 700;
    margin-left: 1rem;
    color: #001952;
    line-height: 1.063rem;
    letter-spacing: 0.25px;
}

.MembershipInfoPage-v2 .MembershipInfoPage__LearnMore {
    font-size: 1rem;
    font-weight: 700;
    color: #001952;
    cursor: pointer;
    background-color: transparent !important;
    border-bottom: 1px solid #001952;
    line-height: 1.375rem;
    letter-spacing: 0.5px;
    text-decoration: none;
}

.MembershipInfoPage-v2 .QuoteSelection__BenefitBanner {
    padding: 1.5rem;
    padding-bottom: 8px;
}

.MembershipInfoPage-v2 .QuoteSelection__BenefitBanner .title {
    margin-top: 0;
    font-size: 1.25rem;
    font-weight: 700;
    letter-spacing: normal;
    color: #333333;
    padding-bottom: 10px;
}
.MembershipInfoPage-v2 .QuoteSelection__BenefitBanner .title-heading-2 {
    padding-top: 24px;
    letter-spacing: normal;
}
.MembershipInfoPage-v2 .QuoteSelection__BenefitBanner .component__text {
    font-size: 1rem;
    font-weight: 400;
    color: #333333;
    text-align: left;
    line-height: 1.375rem;
    letter-spacing: 0.5px;
}

.MembershipInfoPage-v2 .QuoteSelection .Table {
    width: 100%;
    border-collapse: collapse;
    border-top: 2px solid #CCCCCC;
}

.MembershipInfoPage-v2 .QuoteSelection .Table .Table__Row {
    border: 1px solid #CCCCCC;
}

.MembershipInfoPage-v2 .QuoteSelection .Table .Table__Row:first-child {
    /* border-top: none; */
}

.MembershipInfoPage-v2 .QuoteSelection .Table .Table__Data {
    font-size: 0.75rem;
    padding: 1rem;
    letter-spacing: 0.25px;
}

@media (max-width: 669px) {
    .MembershipInfoPage-v2 .QuoteSelection .Table .Table__Data {
        text-align: left;
    }
}

.MembershipInfoPage-v2 .QuoteSelection .Table__Data--Bold {
    font-weight: bold;
    text-align: left;
}

.MembershipInfoPage-v2 .QuoteSelection .Table__Data--ColouredBackground {
    background-color: #E9FDF2;
}

@media (min-width: 670px) {
    .MembershipInfoPage-v2 .QuoteSelection .Table__Data--ColouredBackground {
        text-align: center;
    }
}

.MembershipInfoPage-v2 .MembershipInfoPage__AdditionalInfo {
    margin: 1rem 0 0;
}

.MembershipInfoPage-v2 .MembershipInfoPage__AdditionalInfo p {
    font-size: 0.75rem;
    font-weight: 400;
    color: #000000;
    text-align: center;
    line-height: 1.125rem;
    letter-spacing :0.4px;
}

.Table caption {
    font-size: 1.875rem;
    font-weight: bold;
    color: var(--primary-color);
    margin-bottom: 0.625rem;
}

@media only screen and (max-width: 546px) {
    .MembershipInfoPage-v2 .QuoteSelection__BenefitBanner .title {
        padding-bottom: 0 0.5rem;
    }

    .MembershipInfoPage-v2 .QuoteSelection__BenefitText {
        font-size: 0.875rem;
    }

    /* below is the style to fix button positon at the bottom of screen */
    /* style below to fix button at bottom position */
    .MembershipInfoPage-v2 .PageHeader .petcoVitalCareLogo img {
        display: none;
    }

    .MembershipInfoPage-v2 .PageHeader {
        padding: 0 1rem;
    }

    .MembershipInfoPage-v2 .PageHeader .title {
        margin-top: 0.5rem;
        padding-bottom: 0.5rem;
    }

    .MembershipInfoPage-v2 .QuoteSelection__BenefitBanner .title-heading-2{
        padding-top: 10px;
    }
    .MembershipInfoPage-v2 .Container {
        overflow-y: scroll;
        margin-bottom: 3rem;
        width: calc(100% - 3px);
        padding: 1px;
    }

    .MembershipInfoPage-v2 .Container .Button--Dark {
        position: absolute;
        /* position: sticky; */
        bottom: 0;
        min-width: 100%;
        z-index: 10;
        border-radius: 0;
        margin: 0;
    }

    .MembershipInfoPage-v2 .PageHeader {
        padding: 0 0.5rem;
    }

    .MembershipInfoPage-v2 .QuoteSelection__BenefitBanner {
        padding: 0 0.5rem;
    }

    .col-9 {
        min-width: 100%;
    }
}

/* X-Small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) { 
    .MembershipInfoPage-v2 .QuoteSelection__BenefitBanner .component__text {
        font-size: 0.875rem;
        line-height: 1.25rem;
        letter-spacing: 0.25px;
    }
    .MembershipInfoPage-v2 .QuoteSelection__BenefitText {
        font-size: 0.875rem;
    }
    .MembershipInfoPage-v2 .QuoteSelection .Table__Data--ColouredBackground.UM-Background {
        line-height: 1.125rem;
        letter-spacing: 0.4px;
    }
}
