.modal-title, .modal-body, .modal-body p, .modal-body label {
    color: var(--primary-color);
    text-align: left;
}

.modal-body p {
    font-size: 16px;
    margin-bottom: 10px;
}

.modal-body label {
    font-weight: 400;
}