.ReviewClaim {
    padding-top: 0.9375rem;
    text-align: left;
}

.ReviewClaim .FileImage__IconFileWrapper {
    min-height: 100px;
}

.ReviewClaim .Card {
    padding-left: 0.75rem;
    padding-top: 0.5rem;
    padding-right: 0.75rem;
    padding-bottom: 0.75rem;
}

.ReviewClaim .title {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    color: var(--primary-color);
}

@media (min-width: 670px) {
    .ReviewClaim .title {
        text-align: center;
    }
}

.ReviewClaim .pet-name {
    font-size: 1.125rem;
    color: var(--primary-color);
    margin-top: 0px;
    margin-bottom: 0px;
    padding-top: 0px;
}

.ReviewClaim .edit {
    font-size: 0.75rem;
    align-self: start;
}

.ReviewClaim .ItemTitle {
    color: var(--primary-color);
    font-size: 0.875rem;
}

.ReviewClaim .SubItem {
    color: var(--primary-color);
}

.ReviewClaim .ItemRightWrapper {
    color: #337AA7;
}

.ReviewClaim .first-line-grid {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 80% 20%;
}

.ReviewClaim .List__Item {
    display: flex;
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
    border-bottom: unset;
}

.ReviewClaim .List__Item:first-of-type {
    border-bottom: 0.0625rem solid var(--primary-color-five);
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
}

.ReviewClaim .List__Item .inner-wrapper {
    padding: 0.375rem;
}

.ReviewClaim .List__Item .component__text {
    word-break: break-all;
    text-align: center;
    line-height: 1.2;
}

.ReviewClaim .continue-button {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 1.875rem;
    margin-bottom: 1.875rem;
    background-color: var(--primary-color);
}

@media (min-width: 670px) {
    .ReviewClaim .continue-button {
        max-width: 30rem;
    }
}