.BreedSelectionPage .Container {
    max-width: 25rem;
    padding: 0;
}

.BreedSelectionPage fieldset {
    box-shadow: unset;
}

.BreedSelectionPage .react-select div {
    color: #333333;
}