.TypeSelectionPage .Container {
    width: 100%;
    max-width: 23.75rem;
    padding: 0;
}

.TypeSelectionPage .Container h1 {
    margin-bottom: 24px;
}

.type-button {
    color: var(--primary-color);
    text-decoration: none;
    width: 100%;
    min-width: 5.75rem;
    height: 2.75rem;
    outline: none;
    padding: 0.9rem;
    border: none;
    border-radius: 0.313rem;
    margin: 0px;
    -webkit-box-align: center;
    align-items: center;
    display: flex;
    flex-direction: row;
    -webkit-box-pack: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.25s ease 0s;
    background-color: white;
    box-shadow: rgb(216 216 216 / 67%) 0px 0.125rem 1.25rem;
}