.PurchaseSuccess-v2 {
    padding: 16px 0;
}

.PurchaseSuccess-v2 .main-title,
.PurchaseSuccess-v2 .main-intro {
    color: #000000;
}

.PurchaseSuccess-v2 .title {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    font-size: 1.25rem;
    font-weight: 700;
    gap: 20px;
    padding: 8px 0;
    line-height: 1.5rem;
}

.PurchaseSuccess-v2 .title :first-child {
    height: 50px;
    width: 50px;
}

.footer-text {
    margin-top: 29px;
}

.PurchaseSuccess-v2 .component__text {
    font-size: 0.875rem;
    line-height: 1.25rem;
    margin-bottom: 1rem;
    text-align: center;
    letter-spacing: 0.25px;
    font-weight: 400;
}

.PurchaseSuccess-v2 .CardPlan.pro-care .CardPlan__Header {
    background: linear-gradient(45deg, #f18213, #F6AA19);
}

.PurchaseSuccess-v2 .CardPlan.pro-care .CardPlan__Header svg {
    width: 140px;
}

.PurchaseSuccess-v2 .CardPlan.pro-care .CardPlan__Header p {
    font-size: 12px;
}

.PurchaseSuccess-v2 .CardPlan.pals-id .pals-logo {
    display: flex;
    justify-content: center;
    margin-bottom: 16px;
    font-size: 26px;
    color: var(--primary-color);
}

.PurchaseSuccess-v2 .CardPlan.pals-id .CardPlan__Body p {
    font-weight: 600;
    font-size: 0.8125rem;
    line-height: 1.4;
    color: var(--primary-color);
}

.PurchaseSuccess-v2 .CardPlan.pals-id .CardPlan__Body .palsId {
    display: block;
    margin-top: 0.5rem;
    margin-right: auto;
    margin-left: auto;
    transform: translateX(-0.375rem);
    font-weight: 600;
    font-size: 1rem;
    letter-spacing: 0.0625rem;
    text-align: center;
}

.PurchaseSuccess-v2 .PurchaseSuccess__BarcodeWrapper {
    height: 3.75rem;
    margin-top: 0.375rem;
    overflow: hidden;
}

.PurchaseSuccess-v2 .PurchaseSuccess__BarcodeWrapper svg {
    width: 17.5rem;
    height: 5rem;
}

.PurchaseSuccess-v2 .PurchaseSuccess__BarcodeWrapper text {
    font: bold 1.5rem sans-serif !important;
}

.PurchaseSuccess-v2 .Notice {
    margin-top: 2.3125rem;
}

.PurchaseSuccess-v2 .Notice p {
    font-size: 0.6875rem;
    line-height: 0.9375rem;
    color: var(--primary-color);
}

.PurchaseSuccess-v2 .Notice:first-of-type {
    text-decoration: underline;
}

.PurchaseSuccess-v2 .Notice:not(:last-of-type) {
    margin-bottom: 1rem;
}

.PurchaseSuccess-v2 .Container {
    width: 100%;
    margin: 0 auto;
    padding-right: 16px;
    padding-left: 16px;
    box-sizing: border-box;
}

.PurchaseSuccess-v2 .Container.Container--larger {
    max-width: 50.5rem;
}

.PurchaseSuccess-v2 .CardPlan {
    width: 342px;
    max-width: 100%;
    border-radius: 0.25rem;
    box-shadow: none;
    border: 1px solid #CCCCCC;
    overflow: hidden;
    display: flex;
}

.PurchaseSuccess-v2 .CardPlan .component__button {
    background-color: var(--primary-color);
    color: white;
}

.PurchaseSuccess-v2 .CardPlan__Header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 2.9375rem;
    padding: 0 1.25rem;
    font-size: 1.25rem;
    background-image: linear-gradient(to bottom, #EEEEEE, #FFFFFF);
}

.PurchaseSuccess-v2 .CardPlan__Header p {
    color: white;
}

.PurchaseSuccess-v2 .CardPlan__Header p+p {
    font-size: 11px;
}

.PurchaseSuccess-v2 .CardPlan__Body {
    padding: 1rem 1.25rem;
}

.PurchaseSuccess-v2 .CardPlan__Body .petcoVitalCareLogo {
    width: 7rem;
    margin-top: 0.2rem;
    z-index: 10;
    background-color: #ffffff;
}

.PurchaseSuccess-v2 .CardPlan__Body .um {
    width: 165px;
    height: 22px;
}

.PurchaseSuccess-v2 .CardPlan__Body .um img {
    max-width: 100%;
    max-height: 100%;
}

.PurchaseSuccess-v2 .CardPlan__Body img {
    display: block;
    margin: 0;
}

.PurchaseSuccess-v2 .CardPlan__List {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 16px;
    margin: 0;
    padding: 0;
    max-width: 710px;
    margin: 0 auto;
}

.PurchaseSuccess-v2 .CardPlan__List.CardPlan__List--oneitem {
    justify-content: center;
}

.PurchaseSuccess-v2 .CardPlan__ListItem>span {
    font-size: 0.875rem;
    white-space: nowrap;
    line-height: 1.25rem;
    letter-spacing: 0.25px;
    font-weight: 400;
}

.PurchaseSuccess-v2 .CardPlan__ListItem {
    list-style: none;
}

.PurchaseSuccess-v2 .CardPlan__ListItem strong,
.PurchaseSuccess-v2 .CardPlan__ListItem span {
    color: #000000;
}

.PurchaseSuccess-v2 .CardPlan__ListItem span {
    display: block;
}

.PurchaseSuccess-v2 .CardPlan__PetInfo {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #E5E5E5;
    margin-bottom: 1rem;
    padding-bottom: 1rem;
}

.PurchaseSuccess-v2 .CardPlan__PetInfo .pet-name {
    display: flex;
    max-width: 10rem;
}

.PurchaseSuccess-v2 .CardPlan__PetInfo .pet-name .Icon {
    display: flex;
    align-items: center;
    min-height: 30px;
}

.PurchaseSuccess-v2 .CardPlan__PetInfo .pet-name .Icon img {
    width: 24px;
    max-height: 24px;
}

.PurchaseSuccess-v2 .CardPlan__PetInfo .pet-name .title {
    margin: 0 10px 0;
    padding: 0;
    color: #000000;
    line-height: 1.5rem;
}

.PurchaseSuccess-v2 .InsuranceBannerWrapper {
    margin: 2rem auto;
}

.PurchaseSuccess-v2 .InsuranceBannerWrapper .InsuranceBanner {
    box-sizing: border-box;
    overflow: hidden;
    border-radius: 0.25rem;
    padding: 2rem 1.25rem;
}

.PurchaseSuccess-v2 .InsuranceBannerWrapper .InsuranceBanner .BannerInner .ImageWrapper {
    max-width: 14rem;
}

.PurchaseSuccess-v2 .InsuranceBannerWrapper .InsuranceBanner .BannerInner .ContentWrapper .title {
    line-height: normal;
    font-size: 1.3rem;
}

.PurchaseSuccess-v2 .InsuranceBannerWrapper .InsuranceBanner .ContentWrapper .IconList .ListItem .component__text {
    margin: 0;
}

.PurchaseSuccess-v2 .InsuranceBannerWrapper .InsuranceBanner .ContentWrapper .ButtonGroup .component__button {
    box-sizing: border-box;
}

.PurchaseSuccess-v2 .InsuranceBannerWrapper .InsuranceBanner .ContentWrapper .ButtonGroup .component__button span {
    font-size: 1rem;
}

.PurchaseSuccess-v2 .notice-to-residents {
    display: flex;
    flex-direction: column;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.4px;
    margin: 1rem 0;
}

.PurchaseSuccess-v2 .notice-to-residents>p {
    padding: 0.625rem 0rem;
}

.PurchaseSuccess-v2 .notice-to-residents p {
    color: var(--primary-color);
}

.PurchaseSuccess-v2 .view-dashboard-button {
    font-weight: 700;
    margin: 24px auto 24px;
    line-height: 1.375rem;
    letter-spacing: 1px;
}

@media (min-width:670px) {
    .PurchaseSuccess-v2 .component__text {
        margin-bottom: 1.5rem;
    }
}

@media (max-width: 760px) {
    .PurchaseSuccess-v2 .Container {
        padding-left: 0;
        padding-right: 0;
    }

    .PurchaseSuccess-v2 .CardPlan {
        max-width: 342px;
        width: 100%;
        min-height: 161px;
    }

    .PurchaseSuccess-v2 .CardPlan__Body {
        width: 100%;
    }

    .PurchaseSuccess-v2 .CardPlan__List {
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }

    .PurchaseSuccess-v2 .Container {
        padding: 0 2px;
    }

    .PurchaseSuccess-v2 .CardPlan__ListItem {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        overflow: hidden;
    }

    .PurchaseSuccess-v2 .CardPlan__ListItem span {
        max-width: 100%;
        white-space: pre-wrap;
    }
}

@media only screen and (max-width: 360px) {
    .PurchaseSuccess-v2 .CardPlan {
        max-width: 342px;
        width: calc(100% - 20px);
    }
}

.footer-text p {
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.125rem;
    letter-spacing: 0.4px;
    color: #001952;
    text-align: justify;
}

.footer-text {
    margin-top: 24px;
}

.PurchaseSuccess-v2 .view-dashboard-button {
    width: 342px;
}

@media only screen and (max-width: 670px) {
    .PurchaseSuccess-v2 .main-intro {
        width: 216px;
        margin: auto;
        margin-bottom: 1.5rem;
    }

    .footer-text {
        max-width: 342px;
        margin: auto;
    }
}

@media only screen and (max-width: 360px) {
    .footer-text {
        width: calc(100% - 20px);
        margin: auto;
    }

    .PurchaseSuccess-v2 .view-dashboard-button {
        width: calc(100% - 20px);
    }
}
