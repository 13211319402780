.Inverted {
    background-color: #78d0f0;
}

.Inverted div {
    background-color: white;
}

.Inverted div svg {
  fill: white;
}

.component__StepProgress {
    margin: 3rem 0 1.5rem;
    position: relative;
}

@media (min-width: 670px) {
    .component__StepProgress {
        margin: 5rem 0 2rem;
    }
}

.LinkBack {
    width: 30px;
    height: 30px;
    position: absolute;
    top: -35px;
    right: 0;
    bottom: 0;
    left: 5px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.LinkBack svg {
    width: 20px;
    height: 20px;
    height: auto;
}

.ButtonBack {
    width: 30px;
    height: 30px;
    cursor: pointer;
    border: none;
    background: none;
    padding: 0;
    top: -35px;
    right: 0;
    bottom: 0;
    left: 5px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.ButtonBack svg {
    width: 20px;
    height: 20px;
    height: auto;
}

.ProgressContainer {
    background-color: var(--greyLight1);
    height: 0.5rem;
    width: 100%;
    border-radius: 0.25rem;
    display: flex;
}

.ProgressBar {
    height: 100%;
    background-color: var(--greenLight2);
    border-radius: 0.25rem;
    display: flex;
    transition: width 0.2s;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    /* position: relative; */
}

.ProgressBar img {
    color: var(--primary-color);
    width: 45px;
    height: 45px;
    fill: var(--placeholder);
    margin-bottom: 0.5rem;
}

.ProgressBar img svg  {
    fill: var(--primary-color);
}

.ProgressLabel {
    font-size: 0.8125rem;
    font-weight: 700;
    color: var(--placeholder);
    margin-top: 0.625rem;
    display: block;
}
