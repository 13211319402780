.ComboBoxWrapper .ComboBoxWrapper-ValueContainer {
    height: 45px;
}

.ComboBoxWrapper .ComboBoxWrapper-control, .ComboBoxWrapper {
    height: 48px;
}

.ComboBoxWrapper .ComboBoxWrapper-control {
    border: 1px solid #a9b5ba;
}

.ComboBoxWrapper .ComboBoxWrapper-indicatorSeparator {
    display: none;
}