.form {
    display: flex;
    flex-direction: column;
}

.form .form-group {
    display: flex;
    flex-direction: column;
    flex: 1 1 0px;
}

.form label {
    margin-bottom: 0.4375rem;
    width: 100%;
    font-size: 0.8125rem;
    margin-top: 15px;
    margin-bottom: 5px;
    font-weight: bold;
    color: var(--primary-color);
}

.form .input-error {
    margin-top: 0.25rem;
    font-size: 0.8125rem;
    color: #E02924;
}

.form .form-row {
    display: flex;
}

.form .form-row .form-group+.form-group {
    margin-left: 15px;
}

.form .InputWrapper input {
    border-radius: 5px;
    font-size: 1rem;
    padding: 0;
    height: 2.75rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    box-sizing: border-box;
}