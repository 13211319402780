.PetcoLogoSharedComponent-v2 .Container .top-logo-wrapper {
  justify-content: center;
}

.choose-your-plan-page-v2 {
  padding-bottom: 1rem;
}

@media (min-width: 670px) {
  .choose-your-plan-page-v2 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .choose-your-plan-page-v2 .Container {
    width: 60rem;
    max-width: 100%;
  }
}

.choose-your-plan-page-v2 .content-wrapper>.title {
  font-size: 1.563rem;
  color: #333333;
  text-align: center;
  margin-top: 30px;
  padding: 0px;
  letter-spacing: 0.25px;
  line-height: 2rem;
}

.choose-your-plan-page-v2 .content-wrapper>.component__text {
  line-height: 1.25rem;
  font-size: 0.875rem;
  text-align: center;
  margin-bottom: 32px;
  margin-top: 9px;
  color: #000000;
  letter-spacing: 0.016rem;
}

.choose-your-plan-page-v2 .content-wrapper>.component__text{
  font-size: 1rem;
}

.choose-your-plan-page-v2 .Card-Item-Container {
  min-height: 62px;
  box-sizing: content-box;
}

.choose-your-plan-page-v2 .Card-Item-Container:not(:last-child) {
  border-bottom: 2px solid #E5E5E5;
}

.choose-your-plan-page-v2 .Card_Item-v2 {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 20px 16px;
}

.choose-your-plan-page-v2 .Card_Item-v2 .card-Name {
  display: flex;
  align-items: center;
}

.choose-your-plan-page-v2 .Card_Item-v2 .card-Name.yearly-Plan {
  align-items: unset;
}

.choose-your-plan-page-v2 .Card_Item-v2 .card-info {
  display: flex;
  flex-direction: column;
}

.choose-your-plan-page-v2 .Card_Item-v2 .card-info.monthly-Plan {
  flex-direction: unset;
}

.choose-your-plan-page-v2 .Card_Item-v2 .card-info-row {
  display: flex;
  align-items: center;
  line-height: 22px;
  letter-spacing: 0.5px;
}

.choose-your-plan-page-v2 .cardList-chooseYourPlan {
  border: 2px solid #001952;
  border-radius: 4px;
  box-sizing: border-box;
  outline: none;
  overflow: hidden;
  width: 100%;
  background-color: #FFFFFF;
}

.choose-your-plan-page-v2 .plan-heading {
  color: #001952;
}

.choose-your-plan-page-v2 .cardList-chooseYourPlan .petIcon {
  width: 26px;
  height: 30px;
}

.choose-your-plan-page-v2 .cardList-chooseYourPlan .petIcon img {
  max-width: 100%;
  max-height: 100%;
}

.choose-your-plan-page-v2 .price {
  font-size: 1rem;
  font-weight: 700;
  color: #011954;
  margin-left: 5px;
}

.choose-your-plan-page-v2 .perMonth-price {
  text-align: right;
  font-size: 0.875rem;
  color: #656565;
  line-height: 1.25rem;
  margin-top: 4px;
  letter-spacing: 0.25px;
}

.choose-your-plan-page-v2 .tenure {
  font-family: var(--primary-font-bold);
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1.25rem;
  letter-spacing: 0.25px;
  color: #011954;
}

.choose-your-plan-page-v2 .price--pre-discount {
  text-decoration: line-through;
  color: #656565;

}

.choose-your-plan-page-v2 .cardList-chooseYourPlan .title {
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.313rem;
  letter-spacing: 0.15px;
  text-align: center;
  color: #000000;
  margin-left: 12px;
  margin-bottom: 2px;
  padding: 0px;
}

.choose-your-plan-page-v2 .savings-banner {
  background: #06FF86;
  border-radius: 5px;
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.choose-your-plan-page-v2 .savings-banner.UM {
  background: #DAF1E6;
}

.choose-your-plan-page-v2 .inner-banner {
  margin: 8px 16px;
  display: flex;
  width: 100%;
}

.choose-your-plan-page-v2 .button-link {
  color: #011954;
  font-size: 0.75rem;
  line-height: 1.25rem;
  letter-spacing: 0.25px;
  font-weight: 400;
}

.choose-your-plan-page-v2 .savingAmmount {
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.313rem;
}

.choose-your-plan-page-v2 .plan-heading {
  margin-top: 30px;
  margin-bottom: 20px;
  font-size: 1.25rem;
  line-height: 24px;
  letter-spacing: normal;
}

.choose-your-plan-page-v2 .inner-banner .Icon {
  margin-right: 14px;
}

.choose-your-plan-page-v2 .inner-banner span {
  color: #000000;
  line-height: 1.375rem;
  letter-spacing: 0.15px;

}

.choose-your-plan-page-v2 .Button-v2 {
  width: 100%;
  max-width: none;
  margin-top: 16px;
  line-height: 1.375rem;
  letter-spacing: 1px;
}

.choose-your-plan-page-v2 .content-wrapper {
  width: 100%;
  max-width: 342px;
  margin: 0 auto;
}

.choose-your-plan-page-v2 .Icon {
  width: 27px;
  text-align: center;
}

.choose-your-plan-page-v2 .bird-icon {
  margin-top: 0;
}

.choose-your-plan-page-v2 .cat-icon img {
  height: 25px;
  width: 25px;
}

.choose-your-plan-page-v2 .BackButton {
  height: 20px;
  margin-top: 24px;
  align-items: center;
}

.choose-your-plan-page-v2 .BackButton p {
  margin-top: 0;
}

@media only screen and (max-width: 670px) {
  .choose-your-plan-page-v2 .content-wrapper>.title {
    font-weight: 700;
    line-height: 1.875rem;
    margin-top: 24px;
  }

  .choose-your-plan-page-v2 .content-wrapper {
    max-width: 100%;
  }

  .choose-your-plan-page-v2 .back-to-container {
    margin-left: 0.313rem;
  }

  .choose-your-plan-page-v2 .content-wrapper>.component__text {
    margin-bottom: 36px;
    margin-top: 11px;
  }

  .choose-your-plan-page-v2 .plan-heading {
    margin-top: 16px;
    margin-bottom: 7px;
  }

  .choose-your-plan-page-v2 .savings-banner {
    margin-top: 10px;
    min-height: 58px;
  }

  .choose-your-plan-page-v2 .Card_Item-v2 {
    padding: 20px 16px;
  }

  .choose-your-plan-page-v2 .Card_Item-v2 .card-Name {
    align-items: center;
  }

  .choose-your-plan-page-v2 .bird-icon {
    margin-top: 0;
  }
  .choose-your-plan-page-v2 .cardList-chooseYourPlan .title{
    font-size: 1rem;
    letter-spacing: 0.5px;
    line-height: 1.375rem;
  }
  .choose-your-plan-page-v2 .inner-banner{
    margin: 9px 16px;
  }
}