.BirthdaySelectionPage .Container {
    max-width: 25rem;
    padding: 0;
}

.BirthdaySelectionPage .Container fieldset {
    box-shadow: none;
}

.BirthdaySelectionPage .Container fieldset .dropdownWrapper:first-of-type {
    margin-bottom: 1rem;
}

.BirthdaySelectionPage .Container .component__button.button-active {
    background-color: var(--primary-color);
    color: white;
}

.BirthdaySelectionPage .react-select div {
    color: #333333;
}