.Container {
    max-width: 37.5rem;
    margin: 0 auto;
    padding-right: 1rem;
    padding-left: 1rem;
}

@media (min-width: 670px) {
    .Container {
        padding-right: 0;
        padding-left: 0;
    }
}