.FinishYourPurchase-v2 .TermsSection .terms-checkbox-row-section {
    display: flex;
    align-items: center;
    margin-top: 0.9375rem;
    margin-bottom: 0.9375rem;
}

.FinishYourPurchase-v2 .TermsSection .component__Checkbox {
    align-self: flex-start;
    margin-top: 0.6rem;
}

.FinishYourPurchase-v2 .TermsSection .terms-checkbox-row-section p {
    line-height: 1.125rem;
    font-size: 0.75rem;
}

.FinishYourPurchase-v2 .TermsSection .component__Checkbox {
    width: unset;
}

.FinishYourPurchase-v2 .PaymentInformation {
    margin-bottom: 1.25rem;
}

.FinishYourPurchase-v2 .PaymentInformation .membershipType,
.FinishYourPurchase-v2 .PaymentInformation .membershipTax {
    display: flex;
    justify-content: space-between;
}

.FinishYourPurchase-v2 .PaymentInformation .membershipTax {
    margin-top: 4px;
}

.FinishYourPurchase-v2 .PaymentInformation__Item {
    display: flex;
    justify-content: space-between;
    margin-top: 0.5rem;
}

.FinishYourPurchase-v2 .PaymentInformation__Item.membershipListPrices {
    flex-direction: column;
    margin-top: 0.25rem;
}

.FinishYourPurchase-v2 .PaymentInformation__Item>div:not(:last-of-type) {
    margin-bottom: 0.25rem;
}


.FinishYourPurchase-v2 .PaymentInformation__Items {
    padding-top: 1rem;
    border-top: 0.0625rem solid var(--primary-color-five);
}

.FinishYourPurchase-v2 .PaymentInformation__Item:last-of-type {
    padding-bottom: 0.5rem;
    border-bottom: 0.0625rem solid var(--primary-color-five);
}

.FinishYourPurchase-v2 .PaymentInformation .weight {
    font-family: var(--primary-font-bold);
}

@media only screen and (max-width: 670px) {
    .FinishYourPurchase-v2 .PaymentInformation .membershipTax {
        margin-top: 1rem;
    }
    .FinishYourPurchase-v2 .PaymentInformation__Items {
        padding-top: 1.063rem;
        border-top: 0.0625rem solid #CCCCCC;
    }
}