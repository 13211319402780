#error-modal {
  width: 100%;
  margin: 0 auto;
  padding-bottom: 0.9375rem;
  padding-top: 0.9375rem;
}

#error-modal p {
  color: #666;
  font-size: 0.9375em;
  line-height: 1.6em;
  text-align: center;
}

#error-modal .modal-title {
  text-align: center;
  font-size: 1.5625rem;
  margin-bottom: 0;
  line-height: 1.75rem;
}

.error-dog {
  text-align: center;
  margin: 1rem auto 0;
}

.error-dog img {
  max-width: 13.75rem;
}

.retry-button {
  margin-right: 0.9375rem;
}
