.NeedHelp {
    text-align: center;
    margin-left: 1rem;
    margin-right: 1rem;
}

.NeedHelp .phone-number {
    text-decoration: underline;
    font-size: 0.813rem;
}

.NeedHelp .title.need-help-title {
    font-family: var(--primary-font-bold);
    font-size: 1rem;
    line-height: 1.375rem;
    letter-spacing: 0.5px;
    margin-top: 3rem;
    padding: 0;
    /* placing important because wherever we place the need
    help section, it should have that font color and client
    pages are also adding some styling that is colliding with it
    (title & component_text classes).
    */
    color: var(--primary-color-fourteen) !important;
}

.NeedHelp .text {
    color: var(--primary-color-fourteen) !important;
    font-size: 0.813rem;
    line-height: 0.938rem;
}