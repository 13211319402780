.invalid-button {
  background-color: gray;
}

.valid-button {
  background-color: #2a4fa1;
  color: white;
}

#root {
  background-color: white;
}

#root,
.main-body {
  height: auto;
  overflow: auto;
  min-height: 100vh;
}

#flex-child {
  flex: auto;
  margin: 0.03125rem;
}

iframe {
  height: 93vh;
  width: 93vw;
}

.payment-selection-ctas .column:first-child {
  margin-right: 0.625rem;
}

iframe#add-card-iframe {
  width: 100%;
  height: 600px;
}

iframe#add-card-iframe-mobile {
  width: 100%;
  height: 925px;
}

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
}

.transparent-outline {
  outline: none;
}

.component__button:focus {
  box-shadow: 0 0 0 2px white, 0 0 0 4px #a8dac2;
}
.membershipDetailsPage__addWalletButton {
  margin-top: 16px;
  margin-bottom: 16px;
}
.modal-dialog {
  padding-top: 32px;
  overflow: hidden;
}
.modal-close-button {
  position: absolute;
  top: 16px;
  right: 16px;
  font-size: 24px;
  color: var(--primary-color);
  cursor: pointer;
}

.membership-details-modal.modal .modal-dialog {
  padding: 26px 36px;
  height: calc(100vh - 10%);
  overflow: auto;
}

.membership-details-modal.modal #paymentMethod {
  margin-bottom: 20px;
}

.complete-your-purchase-modal .modal-dialog {
  overflow-y: auto;
}

.aci-payment-frame {
 min-height: 730px;
 height: auto;
 width:100%;
}

.aci-payment-modal .modal-dialog {
  min-width: 320px;
  max-width: 720px;
  width: 70%;
}

.membership-details-modal .modal-content .component__button.active-button {
  background-color: var(--primary-color);
  color: white;
}

.membership-details-modal.modal .ComboBoxWrapper {
  height: auto;
}

.membership-details-modal.modal .ComboBoxWrapper span[aria-live="polite"] + div {
  height: 48px;
  align-items: center;
}

.membership-details-modal.modal .ComboBoxWrapper span[aria-live="polite"] + div div {
  display: flex;
  align-items: center;
  height: 46px;
  padding: 0 6px;
  margin: 0 2px;
}

@media (min-width: 976px) {
  .membership-details-modal.modal .modal-dialog {
    max-height: 100%;
  }
  .membership-details-modal-aci.modal .modal-dialog {
    max-height: 100%;
    max-width: 540px;
    padding: 16px 32px;
  }
}

.barcode-modal .modal-dialog {
  /* max-height: 85%; */
  overflow-y: auto;
  top: 10%;
}

.plan-transition-modal .modal-dialog {
  overflow-y: auto;
  max-height: 95%;
  /* top: 10%; */
}

@media (min-width: 640px) {

  #root,
  .main-body {
    min-height: 1200px;
  }

}
/* reusable flex classes below ----------- */
.d-flex {
  display: flex;
}
.d-inline-flex {
  display: inline-flex;
}
.flex-row {
  flex-direction: row;
}
.flex-row-reverse {
  flex-direction: row-reverse;
}
.flex-column {
  flex-direction: column;
}
.flex-column-reverse {
  flex-direction: column-reverse;
}

.justify-content-start {
  justify-content: flex-start;
}
.justify-content-end {
  justify-content: flex-end;
}
.justify-content-center {
  justify-content: center;
}
.justify-content-between {
  justify-content: space-between;
}
.justify-content-around {
  justify-content: space-around;
}
.justify-content-evenly {
  justify-content: space-evenly;
}

.align-items-start  {
  align-items: flex-start;
}
.align-items-end  {
  align-items: flex-end;
}
.align-items-center  {
  align-items: center;
}
.align-items-baseline  {
  align-items: baseline;
}
.align-items-stretch  {
  align-items: stretch;
}

.align-content-start  {
  align-content: flex-start;
}
.align-content-end  {
  align-content: flex-end;
}
.align-content-center  {
  align-content: center;
}
.align-content-baseline  {
  align-content: baseline;
}
.align-content-stretch  {
  align-content: stretch;
}

.align-self-start  {
  align-self: flex-start;
}
.align-self-end  {
  align-self: flex-end;
}
.align-self-center  {
  align-self: center;
}
.align-self-baseline  {
  align-self: baseline;
}
.align-self-stretch  {
  align-self: stretch;
}

.flex-nowrap {
  flex-wrap: nowrap;
}
.flex-wrap {
  flex-wrap: wrap;
}
.flex-wrap-reverse {
  flex-wrap: wrap-reverse;
}
