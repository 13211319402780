.PetCards-v2 {
    position: relative;
}

.PetCards-v2 .CardReviewList-v2 {
    border: 1px solid #CCCCCC;
    border-radius: 6px;
    box-sizing: border-box;
    outline: none;
    overflow: hidden;
}

.PetCards-v2_UM .CardReviewList-v2.multiple_discount {
    height: 246px;
}

.PetCards-v2_UM .CardReviewList-v2.single_discount {
    height: 247px;
}

.PetCards-v2_UM .CardReviewList-v2.no_discount {
    height: 227px;
}

.PetCards-v2 .CardReviewList__Container-v2 {
    padding: 0.25rem 1rem 0 1rem;
}

.PetCards-v2 .CardReviewList__Item-v2 {
    padding: 0.75rem 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
}

.PetCards-v2_UM .CardReviewList__Item-v2 {
    box-sizing: border-box;
    height: 81px;
}

.PetCards-v2_UM .multiple_discount .CardReviewList__Item-v2.Item-2 {
    height: 59px;
}

.PetCards-v2_UM .single_discount .CardReviewList__Item-v2.Item-2 {
    height: 74px;
}

.PetCards-v2_UM .no_discount .CardReviewList__Item-v2.Item-2 {
    height: 85px;
}

.PetCards-v2 .CardReviewList__Item-v2:not(:last-of-type) {
    border-bottom: 1px solid #E2E2E2;
}

.PetCards-v2 .CardReviewList__Item-v2 .title {
    padding: 0;
    margin-bottom: 0rem;
    font-size: 1.125rem;
}

.PetCards-v2 .CardReviewList__Item-v2 .component__text {
    font-size: 0.875rem;
}

.PetCards-v2 .CardReviewList__Item-v2 .buttonEditCoverage {
    display: block;
    margin-top: 0.5rem;
    margin-bottom: 0.875rem;
    font-size: 0.875rem;
    text-decoration: underline;
    color: #005793;
    cursor: pointer;
}

.PetCards-v2 .CardReviewList__Item-v2 .buttonEditCoverage.subtotal {
    padding-top: 8px;
}

.PetCards-v2 .CardReviewList__Subtotal-v2 {
    padding: 12px 16px;
    background-color: #DAF1E6;
}

.PetCards-v2_UM .CardReviewList__Subtotal-v2 {
    padding: 19px 14px 15px 17px;
    box-sizing: border-box;
}

.PetCards-v2_UM .CardReviewList-v2.multiple_discount .CardReviewList__Subtotal-v2 {
    height: 104px;
    padding: 12px 16px;
}

.PetCards-v2 .CardReviewList-v2.multiple_discount .CardReviewList__Subtotal-v2 {
    height: 104px;
    padding: 12px 16px;
}

.PetCards-v2_UM .CardReviewList-v2.single_discount .CardReviewList__Subtotal-v2 {
    height: 89px;
    padding: 12px 16px;
}

.PetCards-v2 .CardReviewList-v2.single_discount .CardReviewList__Subtotal-v2 {
    height: 89px;
    padding: 12px 16px;
}

.CardReviewList-v2.multiple_discount .CardReviewList__MultiPetSavings-v2_UM {
    margin-bottom: 8px;
}

.CardReviewList-v2.single_discount .CardReviewList__MultiPetSavings-v2_UM {
    margin-bottom: 15px;
}

.PetCards-v2_UM .CardReviewList-v2.no_discount .CardReviewList__Subtotal-v2 {
    height: 59px;
    padding: 19px 14px 15px 17px;
}

.PetCards-v2 .CardReviewList-v2.no_discount .CardReviewList__Subtotal-v2 {
    height: 59px;
    padding: 12px 16px;
}

.PetCards-v2 .CardReviewList__Item-v2 .CardReviewList__PetName-v2 {
    display: flex;
    align-items: center;
}

.PetCards-v2 .CardReviewList__Item-v2 .CardReviewList__PetName-v2>* {
    margin-right: 0.4rem;
}

.PetCards-v2 .CardReviewList__Item-v2 .CardReviewList__PetName-v2 img {
    height: 30px;
    width: 30px;
}

.PetCards-v2 .CardReviewList__Row-v2 {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin: 0.25rem 0;
}

.PetCards-v2_UM .CardReviewList__Row-v2 {
    margin: 0;
}

.PetCards-v2 .CardReviewList__Row-v2.row_UM {
    align-items: baseline;
}

.PetCards-v2 .CardReviewList__PlanHeader-v2 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 0 0.5rem;
}

.PetCards-v2 .CardReviewList__PlanHeader.PlanHeader__VitalCare-v2 img {
    width: 7.125rem;
}

.PetCards-v2 .CardReviewList__PlanHeader-v2 .logoPetcoWellness img {
    width: 8rem;
}

/* button */

.PetCards-v2 .CardReviewList__RemoveButton-v2,
.PetCards-v2 #CardReviewList__RemoveButton-v2 {
    font-size: 0.875rem;
    font-weight: 700;
    color: var(--primary-color);
    cursor: pointer;
    background-color: transparent !important;
    border-bottom: 1px solid var(--primary-color);
    letter-spacing: 0.25px;
    line-height: 1.25rem;
}

.PetCards-v2 .CardReviewList__PlanBody-v2 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 1rem;
}

.PetCards-v2 .CardReviewList__PlanBody-v2 .component__text {
    width: 12.5rem;
    margin-bottom: 0;
}

.PetCards-v2 .CardReviewList__PlanBody-v2 .component__text a {
    font-weight: 100;
    color: #0A598F;
}

.PetCards-v2 .CardReviewList__Price-v2 {
    font-weight: 700;
    color: var(--primary-color);
    white-space: nowrap;
    font-size: 1.25rem;
    line-height: 1.5rem;
    letter-spacing: 0.25px;
}

.PetCards-v2 .CardReviewList__Price-v2 span {
    font-size: 0.875rem;
    line-height: 1.25rem;
}

.PetCards-v2 .CardReviewList__MultiPetSavings-v2 {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.PetCards-v2 .CardReviewList__MultiPetSavings-v2 span {
    font-size: 0.875rem;
    color: #000000;
    letter-spacing: 0.25px;
    line-height: 1.25rem;
    font-weight: 400;
}

.PetCards-v2 .CardReviewList__MultiPetSavings-v2_UM span {
    color: #007500;
}

.PetCards-v2 .ProCareProPlan-v2 {
    display: flex;
    justify-content: center;
    margin: 1.5rem 0 1.25rem;
}

.PetCards-v2 .ProCareProPlan__Value-v2 {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 6.375rem;
    padding: 0.5rem;
    border-radius: 0.25rem;
    box-shadow: 0 0 0.375rem rgba(0, 0, 0, 0.15);
    /* border: ${({ showBorder }) => showBorder ? "1px" : "0px"} solid #0A598F; */
}

.PetCards-v2 .ProCareProPlan__Value-v2:first-of-type {
    margin-right: 1rem;
}

/* span */

.PetCards-v2 .ProCareProPlan__BestValue-v2 {
    position: absolute;
    top: -0.5rem;
    left: 1.5625rem;
    padding: 0.125rem 0.5rem;
    border-radius: 1rem;
    font-size: 0.5625rem;
    line-height: 1.2;
    background-color: #60B149;
    color: white;
}

/* span */

.PetCards-v2 .ProCarePro__Period-v2 {
    margin-top: 0.125rem;
    font-size: 0.6875rem;
    font-family: var(--primary-font-bold);
    line-height: 1;
    color: #98A7AC;
}

.PetCards-v2 .EstimatedTotal-v2 {
    border-top: 1px solid #E2E2E2;
    padding: 1.875rem 0 0;
    margin-bottom: 1.625rem;
}

.PetCards-v2 .EstimatedTotal-v2 .weight {
    font-family: var(--primary-font-bold);
}

.PetCards-v2 .EstimatedTotal__PetInfo-v2 {
    display: flex;
    justify-content: space-between;
}

.PetCards-v2 .EstimatedTotal__PetInfo-v2 .title {
    display: flex;
    justify-content: space-between;
    padding: 1rem 0;
}

.PetCards-v2 .slide-arrow-right {
    position: absolute;
    right: -50px;
    top: 110px;
    cursor: pointer;
}

.PetCards-v2 .slide-arrow-left {
    position: absolute;
    left: -50px;
    top: 110px;
    transform: rotate(180deg);
    cursor: pointer;
}

.PetCards-v2 .hide-arrow {
    display: none;
}

.CompleteYourPurchasePage-v2 .CompleteYourPurchasePage__Container-v2 .component__text.price_UM {
    font-weight: 700;
}

@media (min-width: 670px) {
    .PetCards-v2 .EstimatedTotal-v2 {
        border-top: none;
    }

}

@media only screen and (max-width: 670px) {

    .PetCards-v2 .CardReviewList__MultiPetSavings-v2 span {
        color: #007500;
        line-height: 1.25rem;
    }

    .PetCards-v2 .CardReviewList__Price-v2 {
        font-size: 1.25rem;
        line-height: 1.5rem;
        letter-spacing: 0.25px;
    }

    .PetCards-v2 .CardReviewList__RemoveButton-v2,
    .PetCards-v2 #CardReviewList__RemoveButton-v2 {
        line-height: 1.25rem;
    }
}

@media only screen and (max-width: 992px) {

    .PetCards-v2 .slide-arrow-left,
    .PetCards-v2 .slide-arrow-right {
        top: 90px;
        opacity: 0.3;
        height: 24px;
    }

    .PetCards-v2 .slide-arrow-right {
        right: -7px;
    }

    .PetCards-v2 .slide-arrow-left {
        left: -7px;
    }

    .PetCards-v2 .slide-arrow-left img,
    .PetCards-v2 .slide-arrow-right img {
        width: 24px;
    }
}