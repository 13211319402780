.membership-modalV2.modal.show > div {
    margin-top: 1.3125rem;
}

@media (max-width: 420px) {
    .membership-modalV2.modal.show > div {
        padding: 1.3125rem 0.5rem 0.5rem 1.5rem;
    }
}
.membership-modalV2.modal.show > div {
    margin-top: 1.3125rem;
}

.membership-modalV2 .confirmButton {
    margin-top: 0;
    margin-bottom: 0;
    max-width: 331px;
}
.membership-modalV2 .secondary {
    margin-top: 1rem;
    margin-bottom: 0;
    max-width: 331px;
}

.BarcodeModalWrapper-v2 .modal-body .modal-title {
    margin-top: 24px;
    margin-bottom: 0;
    line-height: 2rem;
}

.BarcodeModalWrapper-v2 .modal-title-wrapper {
    display: flex;
    justify-content: space-between;
}

.BarcodeModalWrapper-v2 .v2-close-icon {
    cursor: pointer;
    margin-top: -6px;
}

.BarcodeModalWrapper-v2 .modal-title-wrapper span {
    font-size: 30px;
}

#v2-modal-title {
    color: black;
    font-weight: bold;
    margin-bottom: 6px;
}

#v2-upper-text {
    color: #F94D4E;
    font-weight: bold;
    font-size: 0.875rem;
}

.BarcodeModalWrapper-v2 .modal-body p {
    margin-bottom: 0;
    font-size: 0.875rem;
}

#v2-modal-footer {
    margin-top: 16px;
}

.BarcodeModalWrapper-v2 .component__button {
    background-color: var(--primary-color);
    color: white;
}

.BarcodeModalWrapper-v2 .component__button:last-of-type {
    background-color: transparent;
    color: var(--primary-color);
    border-color: var(--primary-color);
}

.BarcodeModalWrapper__CustomerInfo p, .BarcodeModalWrapper__CustomerInfo div span {
    color: var(--primary-color);
}

.BarcodeModalWrapper__CustomerInfo p {
    text-align: left;
}

.BarcodeModalWrapper__CustomerInfo div {
    display: flex;
    justify-content: space-between;
    margin-top: 8px;
}

.BarcodeModalWrapper__CustomerInfo div span {
    max-width: 200px;
    font-weight: bold;
    font-size: 14px;
    text-align: left;
    line-height: 1.2;
}

.BarcodeWrapper {
    margin-top: 0.625rem;
    margin-bottom: 1rem;
}

.BarcodeWrapper svg {
    width: 100%;
    height: auto;
}

.BarcodeWrapper svg g text {
    fill: var(--primary-color);
}

.UsedBarcodeWrapper {
    height: 2.5rem;
    margin-top: 0.3125rem;
    overflow: hidden;
    position: relative;
}

.UsedBarcodeWrapper svg {
    opacity: .3;
    width: 100%;
    height: 5rem;
}

.UsedBarcodeWrapper svg g {
    transform: translate(10px, 0);
}

.UsedBarcodeWrapper .component__text {
    position: absolute;
    z-index: 1;
    background-color: #FFF;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: var(--primary-color) !important;
    padding: 2px;
}