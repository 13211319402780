.PetPaymentDetailsContainer {
    width: 344px;
    height: 86px;
    box-sizing: border-box;
    border: 0.5px solid #CCCCCC;
    box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.11);
    border-radius: 5px;
    padding: 11px 31px 31px 28px;
    position: relative;
}

.PetPaymentDetailsContainerWithHighlightTitle .PetPriceDetailsContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.PetPaymentDetailsContainerWithHighlightTitle {
    padding: 17px 28px 21px 28px;
}

.SelectedPaymentDetail {
    width: 344px;
    height: 86px;
    box-sizing: border-box;
    border: 2.5px solid #001952;
    box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.11);
    border-radius: 5px;
    position: relative;
}

.PetPaymentDetailsContainer .highlightTitle {
    min-width: 83.57px;
    height: 18px;
    box-sizing: border-box;
    color: #333333;
    background-color: #DAF1E6;
    font-size: 14px;
    vertical-align: text-top;
    line-height: 1.25rem;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 0px 0px 5px 0px;
    padding: 0px 13.57px 0px 5px;
}

.PetPaymentDetailsContainer .highlightTitle.highlightTitle_UM {
    background-color: #DAF1E6;
}

.PetPaymentDetailsContainer .highlightTitle .highlightTitleText {
    font-size: 0.875rem;
    font-weight: 700;
    letter-spacing: 0.25px;
    line-height: 1.25rem;
}

.PetPaymentDetailsContainer .PetPriceDetailsWithNoHighlightTitle {
    display: flex;
    justify-content: space-between;
}

.PetPaymentDetailsContainer .PetPriceDetailsWithNoHighlightTitle span:first-child {
    margin-right: 9px;
}

.PetPriceDetailsContainer .PetDetailsTitle {
    color: #000000;
    letter-spacing: 0.5px;
    margin-bottom: 2px;
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.375rem;
}

.PetPriceDetailsContainer .PetPriceDetails {
    line-height: 1.375rem;
    letter-spacing: 0.5px;
}

.PetPriceDetailsContainer .PetPriceDetails span:first-child {
    margin-right: 9px;
}

.PetPriceDetailsContainerWithNoHighlightTitle {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}

.PetPriceDetailsContainerWithNoHighlightTitle .PetDetailsTitle {
    color: #000000;
    letter-spacing: 0.5px;
    margin-bottom: 2px;
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.375rem;
}

.PetPriceDetailsContainerWithNoHighlightTitle .PetPriceDetailsWithNoHighlightTitle {
    line-height: 22px;
}

.PetPriceDetailsContainerWithNoHighlightTitle .PetPriceDetails span:first-child {
    margin-right: 9px;
}

.PetPriceDetails .ActualPriceStrikeThroughText {
    color: #656565;
    text-decoration: line-through;
    font-weight: 700;
    letter-spacing: 0.5px;
    line-height: 1.375rem;
    font-size: 1rem;
}

.PetPriceDetails .DiscountedPrice {
    font-weight: 700;
    color: #011954;
    font-size: 1rem;
    letter-spacing: 0.25px;
    line-height: 1.5rem;
}

.PetPriceDetailsWithNoHighlightTitle .ActualPriceStrikeThroughText {
    color: #656565;
    text-decoration: line-through;
    font-size: 1rem;
    font-weight: 700;
    letter-spacing: 0.5px;
    line-height: 1.375rem;
}

.PetPriceDetailsWithNoHighlightTitle .DiscountedPrice {
    font-weight: 700;
    color: #011954;
    font-size: 1rem;
    letter-spacing: 0.25px;
    line-height: 1.5rem;
}

.PetPaymentDetailsContainer .PetDetailsSubTitle .PetDetailsSubTitleText {
    display: block;
    font-size: 0.813rem;
    line-height: 1.25rem;
    color: #001952;
    font-weight: 400;
    letter-spacing: 0.25px;
}

.PerMonthPrice {
    display: flex;
    justify-content: flex-end;
    color: #656565;
    font-size: 0.875rem;
    font-weight: 400;
    letter-spacing: 0.25px;
    line-height: 1.25rem;
    margin-top: 4px;
}

/* Mobile responsive style below */
@media only screen and (max-width: 670px) {
    .PetPaymentDetailsContainer {
        width: 318px;
        height: 86px;
        border: 0.5px solid #CCCCCC;
        padding: 11px 18px 31px 15px;
        position: relative;
    }

    .PetPaymentDetailsContainerWithHighlightTitle .PetPriceDetailsContainer {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .PetPaymentDetailsContainerWithHighlightTitle {
        padding: 17px 15px 21px 15px;
    }

    .SelectedPaymentDetail {
        width: 318px;
        height: 86px;
        box-sizing: border-box;
        border: 2.5px solid #001952;
        box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.11);
        border-radius: 5px;
    }

    .PetPriceDetailsContainer .PetDetailsTitle {
        width: auto;
        height: 20px;
        color: #000000;
        letter-spacing: 0.5px;
        margin-bottom: 2px;
        font-weight: 700;
        font-size: 1rem;
        line-height: 1.375rem;
    }
}