.ConfirmationModal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 1.25rem;
    line-height: 2rem;
    padding-top: 1.5rem;
}
  
.ConfirmationModal .CloseButton span{
      position: absolute;
      top: 1rem;
      right: 1rem;
      padding: 0.2rem;
      margin-left: 3rem;
      color: var(--primary-color);
      font-weight: bolder;
      font-size: 2rem;
      cursor: pointer;
}

.secondary {
    background-color: transparent;
}

.modalTitle {
    text-align: center !important;
}

.modal-body-paragraph {
    text-align: center;
    padding-left: 5px;
    padding-right: 5px;
}