.referral-code {
    margin-top: 24px;
    margin-bottom: 24px;
}
.referral-code .button-link{
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 0.25px;
    text-decoration-line: underline;
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 700;
    color: var(--primary-color-fourteen) !important;
}

.referral-code .button-link.button-link-um {
  color: #011954 !important;
}

.referral-code .applied{

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px
}
.referral-code .applied .applied-text {
    font-size: 0.875rem;
    font-weight: 700;
    line-height: 1.25rem;
    display: flex;
    align-items: center;
    letter-spacing: 0.25px;
    color: #007500;
    margin-left: 8px;
    margin-right: 9px;
    font-family: var(--primary-font-bold);
}

.referral-code .applied .button-link-w {
    width: auto;
}

.referral-code .applied .button-link-w.button-link-UM {
  color: #011954 !important;
}

.referral-code .AffiliateForm .form-field-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 24px;
}

.referral-code .AffiliateForm .form-field-col {
    display: flex;
    /* justify-content: center; */
    flex-direction: column;
    margin: 0;
}


.referral-code .AffiliateForm .input-container {
    position: relative;
    background-color: #fff;
    width: 100%;
}

.referral-code .AffiliateForm .input-container .referral-code-error input {
    border: 1px solid #FB4341;
  }

  .referral-code .AffiliateForm .input-container input {
    background-color: #fff;
    width: 14.5rem;
    height: 3.5rem;
    border: 1px solid #656565;
    box-sizing: border-box;
    font-size: .9rem;
    line-height: 1.375rem;
    letter-spacing: 0.5px;
  }


  .referral-code .AffiliateForm .input-container input::placeholder {
    overflow: visible;
    font-weight: normal;
    color: #656565;
  }


  .referral-code .AffiliateForm .input-error {
    position: absolute;
    top: -23px;
    font-size: 0.875rem;
    font-weight: 700;
    line-height: 1.25rem;
    letter-spacing: 0.25px;
    left: 0.25rem;
    font-family: var(--primary-font-bold);
    color: #FB4341;
  }

  .referral-code .AffiliateForm {
    display: flex;
    flex-direction: column;
    align-items: center;
  }


  .referral-code .AffiliateForm form {
    width: 100%;
  }

  .referral-code .AffiliateForm #affiliate-form-apply-button {
    margin-bottom: 0;
    width: 6.1rem;
    margin-top: 0;
    line-height: 1.375rem;
    letter-spacing: 1px;
  }

  .referral-code .AffiliateForm #affiliate-form-apply-button:disabled {
    color: #333333;
  }

  .referral-code .AffiliateForm .field {
    display: flex;
    flex-flow: column-reverse;
    margin-bottom: 1em;
  }


  .referral-code .AffiliateForm .InputWrapper {
    height: 56px;
    margin-bottom: 0;
    margin-top: 0;
  }

  .referral-code .AffiliateForm .form-field-col:first-of-type {
    margin-right: 10px;
  }


  .afterSubmitErrorMessage p {
    text-align: center;
    color: #FB4341;
  }

  .registration-container input {
    text-indent: 0.875rem;
  }

  @media (max-width: 375px) {
    .referral-code .AffiliateForm .form-field-wrapper {
      flex-direction: column;
    }
    .referral-code .AffiliateForm .form-field-col:first-of-type {
        margin-right: 0px;
      }
    .referral-code .AffiliateForm .input-container input {
        width: 100%;
    }
    .referral-code .AffiliateForm #affiliate-form-apply-button {
        width: 100%;
      }
  }
  @media (max-width: 669px) and (min-width: 375px) {

    .referral-code .AffiliateForm .input-container input {
        width: 100%;
    }
    .referral-code .AffiliateForm .form-field-col:first-of-type {
      width: 100%;
    }
    .referral-code {
      margin-top: 30px;
      margin-bottom: 36px;
   }
  }