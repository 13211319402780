.EmptyPetCard {
    border-radius: 0.25rem;
    box-shadow: 0 0 0.75rem rgba(0, 0, 0, 0.15);
    outline: none;
    display: flex;
    flex-direction: column;
    height: auto;
    min-height: 25rem;
    overflow: hidden;
}

.EmptyPetCard__Item {
    list-style: none;
}

.EmptyPetCard__Item:first-of-type {
    display: flex;
    align-items: center;
    flex: 1;
    justify-content: space-between;
}

.EmptyPetCard__Item:last-of-type {
    padding: 1rem;
    box-shadow: 0 -0.25rem 0.75rem rgba(0, 0, 0, 0.0725);
}

.EmptyPetCard__Item .component__text {
    margin-bottom: 1rem;
    font-size: 1rem;
    color: #afafaf;
}

.EmptyPetCard__PetInfo {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 3.125rem auto;
}

/* button */

.EmptyPetCard__ExplorePlans {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 0;
    border: none;
    font-size: 1rem;
    font-weight: bold;
    text-decoration: underline;
    background-color: transparent;
    color: #0A598F;
    cursor: pointer;
}