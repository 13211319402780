.ClaimsThankYouPage-v2 {
    padding: 1rem 0;
}

.ClaimsThankYouPage-v2 .logoPetcoVitalCare {
    display: none;
}

.ClaimsThankYouPage-v2 .Container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 25rem;
}

.ClaimsThankYouPage-v2-UM .Container{
    max-width: 27rem;
}

.ClaimsThankYouPage-v2 .Container .Icon {
    align-self: center;
    padding: 0.5rem 0;
}

.ClaimsThankYouPage-v2 .title {
    padding: 1rem 0;
    font-weight: 700;
    font-size: 1.25rem;
    color: #000000;
    line-height: 1.5rem;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}

.ClaimsThankYouPage-v2 .component__text {
    font-size: 1rem;
}

.ClaimsThankYouPage-v2-UM .component__text {
    color: #333;
}

.ClaimsThankYouPage-v2 .component__text {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    line-height: 1.375rem;
    letter-spacing: 0.5px;
    font-weight: 400;
}

.ClaimsThankYouPage-v2 .return-dashboard {
    text-transform: uppercase;
    padding: 1rem 0;
    letter-spacing: 1px;
    line-height: 1.375rem;
    font-weight: 700;
}

.ClaimsThankYouPage-v2 button.component__button {
    background-color: var(--primary-color);
}

@media (min-width: 670px) {
    .top-logo-wrapper {
        margin: 0 auto;
    }

    .ClaimsThankYouPage-v2 .logoPetcoVitalCare {
        display: block;
    }
}