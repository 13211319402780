.checkbox__input:checked~.checkbox__IconContainer {
    background-color: white;
}

.checkbox__input:checked~.checkbox__IconContainer::before {
    opacity: 1;
}

.checkbox__input .checkbox__IconContainer {
    background-color: white;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 1.5625rem;
}

.checkbox__input .checkbox__IconContainer::before {
    content: '';
    background-color: var(--primary-color);
    height: 0.375rem;
    width: 0.375rem;
    border-radius: 1.5625rem;
    display: block;
    opacity: 0;
}

.checkbox__input .checkbox__IconContainer svg {
    display: none;
}

.checkbox__input.disabled {
    color: var(--greyLight1);
    pointer-events: none;
}

.checkbox__input.disabled .checkbox__IconContainer {
    border-color: var(--greyLight1);
}

.checkbox__input .deactivated {
    color: var(--greyLight1);
}

.checkbox__input .checkbox__IconContainer {
    border-color: var(--greyLight1);
}

.component__Checkbox {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-size: 0.9375rem;
    cursor: pointer;
}

.component__Checkbox .checkbox__input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.component__Checkbox .checkbox__input:checked~.checkbox__IconContainer {
    background-color: var(--primary-color);
    border-color: var(--primary-color);
}

.component__Checkbox .checkbox__input:checked~.checkbox__IconContainer img {
    opacity: 1;
}

.component__Checkbox .checkbox__input:checked~.checkbox__IconContainer:focus+span {
    box-shadow: 0 0 0 2px white, 0 0 0 4px #a8dac2;
}

.component__Checkbox .checkbox__IconContainer {
    font-size: 0.5625rem;
    color: var(--lightGrey1);
    width: 32px;
    height: 32px;
    border: 1.5px solid var(--primary-color);
    border-radius: 2px;
    margin-right: 0.5rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    transform: translateY(3px);
}

.radial {
    background-color: white;
    -webkit-box-align: center;
    align-items: center;
    display: flex;
    flex-direction: row;
    -webkit-box-pack: center;
    justify-content: center;
    border-radius: 1.5625rem;
    margin-right: 0.5rem;
    font-size: 0.5625rem;
    color: rgb(0, 25, 82);
    width: 1rem;
    height: 1rem;
    border: 1.5px solid rgb(0, 25, 82);
}

.radial__IconContainer {
    background-color: white;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-self: center;
    border-radius: 1.5625rem;
}

.radial__IconContainer svg {
    display: none;
}

.radial:checked::before {
    content: "";
    background-color: var(--primary-color);
    height: 11px;
    width: 11px;
    border-radius: 1.5625rem;
    display: block;
    opacity: 1;
}
.component__Checkbox input:focus + .checkbox__IconContainer {
    outline: solid 2px var(--outline-color);
    outline-offset: 3px;
    outline: none;
}