.AddPetNamePage .Container {
    max-width: 25rem;
    padding: 0;
}

.AddPetNamePage fieldset {
    box-shadow: none;
}

.AddPetNamePage fieldset label {
    color: var(--primary-color);
}

.AddPetNamePage fieldset label span {
    margin-right: 4px;
    color: red;
}

.AddPetNamePage fieldset .component__button.button-active {
    background-color: var(--primary-color);
    color: white;
}

.AddPetNamePage input[name="petName"] {
    padding: 0;
    height: 2.75rem;
}

@media screen and (max-width: 790px) {
    .AddPetNamePage input[name="petName"] {
        font-size: 1rem;
    }
}

.AddPetNamePage .input-error {
    margin-top: 0.25rem;
    font-size: 0.8125rem;
    color: #e02924;
}