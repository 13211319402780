.BackButton {
  width: 100%;
  display: flex;
  line-height: 1;
  margin-top: 15px;
  margin-bottom: 15px;
  cursor: pointer;
  justify-content: center;
}

.BackButton p {
  font-family: var(--primary-font-bold);
  text-decoration: underline;
  text-underline-offset: 3px;
  color: var(--primary-color-fifteen);
  font-weight: bold;
  font-size: 0.875rem;
  line-height: 1.25rem;
  letter-spacing: 0.016rem;
  margin-top: 1.25rem;
}

.BackButton_UM p {
  color: #011954;
}