.ClaimsUploadInvoicesPage {
    height: auto;
    background-color: #ffffff;
    max-width: 18.75rem;
    margin: 0 auto;
    padding: 1rem 0;
}

.ClaimsUploadInvoicesPage h2 {
    text-align: left;
    color: var(--primary-color);
}

@media (min-width: 670px) {
    .ClaimsUploadInvoicesPage h2 {
        text-align: center;
    }
}

@media (min-width: 670px) {
    .ClaimsUploadInvoicesPage {
        max-width: 26.25rem;
    }
}

.ClaimsUploadInvoicesPage .FileImageWrapper {
    min-height: 150px;
    min-width: 150px;
}

.ClaimsUploadInvoicesPage .FileImage__IconFileWrapper {
    max-height: 150px;
}

.ClaimsUploadInvoicesPage .FileImage__IconFileWrapper .inner-wrapper {
    padding: 0;
}

.ClaimsUploadInvoicesPage .title {
    display: flex;
    justify-content: left;
}

.ClaimsUploadInvoicesPage .title:nth-of-type(2) {
    margin-bottom: 0.625rem;
}

@media (min-width: 670px) {
    .ClaimsUploadInvoicesPage .title {
        justify-content: center;
    }
}

.ClaimsUploadInvoicesPage .FileDialogInput {
    max-width: unset;
}

@media (min-width: 670px) {
    .ClaimsUploadInvoicesPage .FileDialogInput.Justify {
        justify-self: end;
    }
}

@media (min-width: 670px) {
    .ClaimsUploadInvoicesPage #is-there-anything-else, .ClaimsUploadInvoicesPage .add-more-details-h1 {
        display: none;
    }
}

.ClaimsUploadInvoicesPage .claim_p {
    margin-top: 0.625rem;
    margin-bottom: 0.625rem;
    line-height: 1.2;
}

.ClaimsUploadInvoicesPage #remove-button {
    background-color: transparent;
    color: var(--primary-color);
    cursor: pointer;
    font-size: 0.8125rem;
    font-weight: 500;
    padding: 0;
    text-decoration: none;
}

.ClaimsUploadInvoicesPage .component__button:first-of-type {
    margin-top: 2rem;
    margin-right: auto;
    margin-left: auto;
    border: 1.5px solid #0A598F;
}

.ClaimsUploadInvoicesPage .component__button span {
    color: #0A598F;
}

.ClaimsUploadInvoicesPage #upload-another {
    background-color: transparent;
    margin-right: unset;
    margin-left: unset;
    max-width: unset;
}

.ClaimsUploadInvoicesPage .comment-text-area {
    resize: none;
}

.ClaimsUploadInvoicesPage .component__button.invalid-button {
    background-color: #d4dadc;
    border: 0.0625rem solid #a9b5ba;
    color: #8e9da2;
}

.ClaimsUploadInvoicesPage .component__button.invalid-button span {
    color: #8e9da2;
}

.ClaimsUploadInvoicesPage .component__button.button-active {
    background-color: var(--primary-color);
    color: white;
}

.ClaimsUploadInvoicesPage .component__button.button-active span {
    color: #FFF;
}

@media (min-width: 670px) {
    .ClaimsUploadInvoicesPage .component_button {
        margin-right: unset;
        margin-left: unset;
        justify-self: start;
    }
}

.ClaimsUploadInvoicesPage #message-field {
    color: red;
    display: flex;
    justify-content: center;
    margin-top: 0.9375rem;
}

.ClaimsUploadInvoicesPage #loading-message {
    display: flex;
    justify-content: center;
    margin-top: 0.9375rem;
}

.FileImageGallery {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.FileImageGallery .inner-wrapper {
    padding: 0.5rem;
}

.FileImageGallery .component__text {
    text-align: center;
    font-size: 0.75rem;
    line-height: 1.1;
    word-break: break-all;
}

.Message {
    
}