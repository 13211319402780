.DashboardHeader-v2 .title {
    color: var(--primary-color);
}

@media (min-width: 992px) {
    .DashboardHeader-v2 .Container .PetListGrid {
        margin-right: auto;
        margin-left: auto;
    }

    div.RedemptionDashboardPage-v2>div.DashboardHeader-v2>div {
        max-width: 61.5rem;
    }
}

.DashboardHeader-v2 .change-plan-status-button {
    background-color: transparent;
    padding: 0;
    color: var(--primary-color);
    border: none;
    cursor: pointer;
    pointer-events: visible;
    font-size: 14px;
    border-bottom: 1px solid var(--primary-color);
}

.DashboardHeader-v2 .activationMessage {
    font-weight: normal;
    padding: 12px;
    border: 1px solid #FF3A44;
    border-radius: 6px;
    margin-top: 15px;
    margin-bottom: 16px;
    font-size: 14px;
    line-height: 1.4;
    color: var(--primary-color);
    background-color: var(--primary-color-ten);
}

.flashMessage {
    min-height: 56px;
    box-sizing: border-box;
    padding: 16px;
    margin-top: 15px;
    margin-bottom: 1rem;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    color: var(--primary-color);
    letter-spacing: 0.5px;
    display: flex;
}

.DashboardHeader-v2 .activationErrorMessage {
    font-weight: 400;
    margin-top: 11px;
    color: #333333;
    background-color: #FFF0F0;
    line-height: 1.375rem;
    letter-spacing: 0.5px;
}

.DashboardHeader-v2 .activationErrorMessage span {
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.25rem;
    letter-spacing: 0.25px;
    color: #000000;
}

.DashboardHeader-v2 .expiredErrorMessage {
    font-weight: 400;
    border-radius: 2px;
    margin-top: 11px;
    font-size: 0.875rem;
    color: #333333;
    background-color: #FFF0F0;
    letter-spacing: 0.25px;
    line-height: 1.375rem;
}

.DashboardHeader-v2 .roverBannerContainer {
    width: auto;
    background-color: #DAF1E6;
    display: flex;
    padding: 16px 16px 16px 16px;
    margin-top: 11px;
}

.DashboardHeader-v2 .rovertxtDescription {
    font-weight: 400;
    font-size: 0.875rem;
    color: #000000;
    letter-spacing: 0.25px;
    line-height: 1.375rem;
    align-items: center;
    display: flex;
}

.DashboardHeader-v2 .closeBtnContainer {
    display: flex;
    flex-direction: column;
    margin-left:auto;
}

.DashboardHeader-v2 .CloseButton {
    background-color: transparent;
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;
    overflow: hidden;
    outline: none;
}

.DashboardHeader-v2 .flashMessage span {
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.25rem;
    letter-spacing: 0.25px;
    color: #000000;
}

.DashboardHeader-v2 .flashMessage.successMessage {
    color: #333333;
    padding: 5px 16px;
    display: flex;
    align-items: center;
}

.DashboardHeader-v2 .flashMessage.successMessage img {
    margin-right: 18px;
}

.DashboardHeader-v2 .flashMessage .plan-status-button {
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 1.25rem;
    letter-spacing: 0.25px;
    color: #001952;
}

.DashboardHeader-v2 .plan-status-button {
    background-color: transparent;
    padding: 0;
    color: var(--primary-color);
    border: none;
    cursor: pointer;
    pointer-events: visible;
    font-size: 16px;
    border-bottom: 1px solid var(--primary-color);
}

.DashboardHeader-v2 .activationErrorMessage .Icon {
    display: flex;
    align-items: center;
}

.DashboardHeader-v2 .expiredErrorMessage .Icon {
    display: flex;
    align-items: center;
}

.DashboardHeader-v2 .activationErrorMessage img {
    margin-top: 2px;
    margin-right: 17px;
    margin-left: 7px;
}

.DashboardHeader-v2 .expiredErrorMessage img {
    margin-top: 2px;
    margin-right: 17px;
    margin-left: 7px;
}

.expiredErrorMessage span b {
    font-size: 0.875rem;
}

.expiredErrorMessage span .plan-status-button {
    font-size: 0.875rem;
}

.DashboardHeader-v2 .plan-status-button {
    background-color: transparent;
    padding: 0;
    color: var(--primary-color);
    border: none;
    cursor: pointer;
    pointer-events: visible;
    font-size: 1rem;
    border-bottom: 1px solid var(--primary-color);
    letter-spacing: 0.25px;
    font-weight: 700;
}

@media only screen and (max-width: 670px) {
    .DashboardHeader-v2 .activationErrorMessage {
        letter-spacing: 0.25px;
        line-height: 1.25rem;
    }

    .DashboardHeader-v2 .plan-status-button {
        font-size: 0.875rem;
        line-height: 1.25rem;
    }

    .expiredErrorMessage span {
        line-height: 1.25rem;
    }
}

.DashboardHeader-v2 {
    margin-bottom: 0.25rem;
}


.DashboardHeader-v2 .successMessage {
    background: #E9FDF2;
    ;
    color: #333333;
    min-height: 69px;
    line-height: 1.25rem;
    font-size: 0.875rem;
    font-weight: 400;
    letter-spacing: 0.25px;
    padding: 19px 79px 3px 16px;
}

.DashboardHeader-v2 .successMessage img {
    line-height: 20px;
    margin-right: 20.48px;
}

@media only screen and (max-width: 670px) {
    .DashboardHeader-v2 .successMessage {
        padding: 16px;
    }

    .DashboardHeader-v2 .successMessage img {
        margin-right: 12px;
    }

    .DashboardHeader-v2 .flashMessage span {
        font-size: 0.875rem;
    }

    .DashboardHeader-v2 .activationErrorMessage span {
        font-size: 0.875rem;
        line-height: 1.25rem;
        letter-spacing: 0.016rem;
    }

    .DashboardHeader-v2 .flashMessage.successMessage {
        padding: 8px 12px;
        align-items: start;
    }

    .DashboardHeader-v2 .flashMessage.successMessage img {
        width: 12px;
        height: 12px;
        padding-top: 8px;
        margin-right: 12px;
    }
}