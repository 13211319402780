.AddClinicModal .component__buttonGroup {
    margin-top: 20px;
}

/* do not group these rules */

.AddClinicModal input::-webkit-input-placeholder {
    color: var(--primary-color-five);
}

.AddClinicModal input:-moz-placeholder {
    /* FF 4-18 */
    color: var(--primary-color-five);
}

.AddClinicModal input::-moz-placeholder {
    /* FF 19+ */
    color: var(--primary-color-five);
}

.AddClinicModal input::-ms-input-placeholder {
    /* IE 10+ */
    color: var(--primary-color-five);
}

.AddClinicModal input::-ms-input-placeholder {
    /* Microsoft Edge */
    color: var(--primary-color-five);
}

.AddClinicModal input::placeholder {
    /* modern browser */
    color: var(--primary-color-five);
}

.AddClinicModal #state div[class$="-placeholder"] {
    color: var(--primary-color-five);
}

/* Hide the scroll on the Add Vet Modal but keep functionally */
.add-vet-modal .modal-dialog {
    height: 100%;
    overflow-y: scroll;
    -ms-overflow-style: none;  /* Hide scrollbar for Edge */
    scrollbar-width: none;  /* Hide scrollbar for Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.add-vet-modal .modal-dialog::-webkit-scrollbar {
    display: none;
}
