.PetListGrid-v2 {
    display: flex;
    min-height: 5rem;
    flex-flow: row nowrap;
    overflow: scroll;
    overflow-y: hidden;
    border-bottom: 1px solid var(--primary-color-nine);
}

.PetListGrid-v2::-webkit-scrollbar {
    height: 0;
}

@media (min-width: 992px) {
    .PetListGrid-v2 {
        overflow: visible;
        flex-flow: row wrap;
        justify-content: flex-start;
    }
}

.PetListGrid-v2 .PetListGrid__Item {
    background-color: transparent;
    border: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    list-style: none;
    cursor: pointer;
    margin-top: 0.5rem;
    border-bottom: 0.35rem solid transparent;
}

.PetListGrid-v2 .PetListGrid__Item.PetListGrid__Item--Marked {
    border-bottom: 0.35rem solid var(--primary-color);
}

.PetListGrid-v2 .PetListGrid__Item:not(:last-of-type) {
    margin-right: 38px;
}

.PetListGrid-v2 .PetListGrid__Item:focus:not(:focus-visible) {
    outline: none;
}

.PetListGrid-v2 .PetListGrid__IconWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 44px;
    min-width: 44px;
    height: 44px;
}

.PetListGrid-v2 .PetListGrid__IconWrapper .petNameHeader-v2 {
    font-size: 1rem;
    color: var(--primary-color);
}

.PetListGrid-v2 .PetListGrid__IconWrapper.sellScreenIcon_wrapper {
    border: 2px solid #656565;
}

.PetListGrid-v2 .PetListGrid__IconWrapper {
    border: 2px solid transparent;
    height: 3.75rem;
    width: 3.75rem;
    border-radius: 50%;
    background-image: linear-gradient(white, white),
        linear-gradient(to right, #CCCCCC,
            #CCCCCC);
    background-origin: border-box;
    background-clip: content-box, border-box;
}

.PetListGrid-v2 .PetListGrid__IconWrapper.PetListGrid__IconWrapper--Marked.UM_IconWrapper {
    background-image: linear-gradient(white, white),
        linear-gradient(#001952,#001952);
}

.PetListGrid-v2 .PetListGrid__IconWrapper.PetListGrid__IconWrapper--Marked {
    border: 2px solid transparent;
    height: 3.75rem;
    width: 3.75rem;
    border-radius: 50%;
    background-image: linear-gradient(white, white),
        linear-gradient(to right, var(--primary-color-eight),
            var(--primary-color-seven));
    background-origin: border-box;
    background-clip: content-box, border-box;
}

.PetListGrid-v2 .PetListGrid__IconWrapper.UM__PetListGrid__IconWrapper--Marked {
    border: 2px solid #001952;
}

.PetListGrid-v2 .PetListGrid__IconWrapper .Icon {
    margin-top: 5px;
}

.PetListGrid-v2 .PetListGrid__IconWrapper .iconDog img {
    height: 1.8rem;
}

.PetListGrid-v2 .PetListGrid__IconWrapper .iconCat img {
    height: 2.1rem;
}

.PetListGrid-v2 .PetListGrid__IconWrapper svg {
    transform: translateY(3px);
}

.PetListGrid-v2 .PetListGrid__IconWrapper svg path {
    fill: var(--primary-color);
}

.PetListGrid-v2 .PetListGrid__Item h2.petNameHeader-v2 {
    max-width: 104px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 1rem;
    font-weight: 700;
    color: #001952;
    word-break: break-all;
    margin-top: 0.5rem;
    line-height: 1.375rem;
    letter-spacing: 0.5px;
}

.SellScreen-v2.PetListGrid-v2 {
    /* margin: 0 -16px 25px; */
    padding: 0 16px;
}

.SellScreen-v2.PetListGrid-v2 .PetListGrid__Item h2.petNameHeader-v2 {
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.125rem;
    letter-spacing: 1px;
    color: #656565;
    text-transform: uppercase;
}

.SellScreen-v2.PetListGrid-v2 .PetListGrid__Item.PetListGrid__Item--Marked h2.petNameHeader-v2 {
    font-weight: 700;
    color: #333333;
}

.SellScreen-v2.PetListGrid-v2 .PetListGrid__Item.PetListGrid__Item--Marked {
    border-bottom: 0;
}

.SellScreen-v2.PetListGrid-v2 .PetListGrid__Item.PetListGrid__Item--Marked::after {
    content: '';
    border-bottom: 10px solid #001952;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    margin-bottom: -1px;
}

.badge {
    position: relative;
}

.errorBadge {
    top: 5px;
    position: absolute;
    left:15px;
}

@media only screen and (max-width: 670px) {
    .PetListGrid-v2 .PetListGrid__Item:not(:last-of-type) {
        margin-right: 18px;
    }
}
