.Price {
  font-size: 0.9375rem;
  font-weight: 600;
  position: relative;
}

.Price sup, .Price sub, .Price span {
  font-weight: 700;
  line-height: 1.375rem;
  letter-spacing: 0.5px;
}

.Price sup {
  vertical-align: super;
  font-size: 0.5625rem;
}

.Price sub {
  vertical-align: baseline;
  font-size: 0.625rem;
}

.Price.scratched::before {
  content: '';
  width: 100%;
  position: absolute;
  top: 50%;
  border: 1px solid;
}

.Price__sub {
    font-size: 1rem !important;
}