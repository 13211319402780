.ReviewClaim-v2 {
    padding-top: 0.9375rem;
    text-align: left;
}

.ReviewClaim-v2 .title {
    font-size: 1.565rem;
    line-height: 2rem;
    font-weight: 700;
    letter-spacing: 0.25px;
}

.ReviewClaim-v2 .FileImage__IconFileWrapper {
    min-height: 100px;
}

.ReviewClaim-v2 .logoPetcoVitalCare {
    display: none;
}

.ReviewClaim-v2 .Card {
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 1rem;
    border: 1px solid #CCCCCC;
    box-sizing: border-box;
    border-radius: 6px;
}

.ReviewClaim-v2 .title {
    color: var(--primary-color);
}

.ReviewClaim-v2 .card-header {
    max-height: 1.5rem;
    padding: 1rem 0;
}

.ReviewClaim-v2 .card-header .Icon {
    margin-right: 0.5rem;
}

.ReviewClaim-v2 .pet-name {
    font-size: 1.25rem;
    color: var(--primary-color);
    margin-top: 0px;
    margin-bottom: 0px;
    padding-top: 0px;
    line-height: 1.5rem;
    font-weight: 700;
}

.ReviewClaim-v2 .edit {
    font-size: 1rem;
    align-self: start;
    font-weight: 700;
    letter-spacing: 0.5px;
    line-height: 1.375rem;
}

.ReviewClaim-v2 .ItemTitle h3 {
    color: var(--primary-color);
    font-size: 0.875rem;
    padding-bottom: .30rem;
    font-weight: 700;
    letter-spacing: 0.25px;
    line-height: 1.25rem;
}

.ReviewClaim-v2 .SubItem {
    color: var(--primary-color);
}

.ReviewClaim-v2 .item-description {
    font-size: 0.875rem;
    font-weight: 400;
    letter-spacing: 0.25px;
    line-height: 1.25rem;
    max-width: 230px;
}

.ReviewClaim-v2 .ItemRightWrapper {
    color: var(--primary-color);
    font-size: 0.875rem;
    font-family: var(--primary-font-bold);
    text-decoration: underline;
}

.ReviewClaim-v2 .first-line-grid {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 80% 20%;
}

.ReviewClaim-v2 .List__Item {
    display: flex;
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
    border-bottom: unset;
}

.ReviewClaim-v2 .List__Item:first-of-type {
    border-bottom: 0.0625rem solid var(--primary-color-five);
    padding-bottom: 1rem;    
    border-top: none;
}

.ReviewClaim-v2 .List__Item .inner-wrapper {
    padding: 0.375rem;
}

.ReviewClaim-v2 .List__Item .component__text {
    word-break: break-all;
    text-align: center;
    line-height: 1.2;
}

.ReviewClaim-v2 .continue-button {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 1.875rem;
    margin-bottom: 1.875rem;
    background-color: var(--primary-color);
    text-transform: uppercase;
    letter-spacing: 1px;
    line-height: 1.375rem;
}

@media (min-width: 670px) {
    .ReviewClaim-v2 .Container {
        width: 20.5rem;
    }

    .ReviewClaim-v2 .claim-review-header-wrapper {
        margin: 0 auto;
        max-width: 50rem;
    }

    .ReviewClaim-v2 .PetcoLogoSharedComponent-v2 {
        margin-top: -40px;
    }

    .ReviewClaim-v2 .continue-button {
        max-width: 20.5rem;
        line-height: 1.25rem;
        letter-spacing: 0.25px;
    }

    .ReviewClaim-v2 .title {
        text-align: center;
    }

    .ReviewClaim-v2 .logoPetcoVitalCare {
        margin: 0 auto;
        display: block;
    }
}
