.LogoHeader {
    display: flex;
    align-items: center;
    width: 100%;
    height: 60px;
    padding-right: 16px;
    padding-left: 16px;
    box-sizing: border-box;
}

.LogoHeader.center {
    justify-content: center;
}

.LogoHeader img {
    width: 166px;
    height: 34px;
}