.customModal.SelectPaymentPlanModal .modal-dialog {
    width: calc(100% - 9px);
    max-width: 590px;
    padding: 55px 25px 70px 40px;
}

.SelectPaymentPlanModal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    line-height: 2rem;
}

.SelectPaymentPlanModal .modal-body{
    display: contents;
   }

.SelectPaymentPlanModal .modal-body .PetPaymentDetailsContainer {
    margin-bottom: 14px;
    cursor: pointer;
}

.SelectPaymentPlanTitle.modal-title.h4 {
    height: 48px;
    font-size: 1.125rem;
    font-weight: 700;
    line-height: 1.313rem;
    padding: 0 105px;
    text-align: center;
    letter-spacing: 0.15px;
    margin-bottom: 4px;
    color: #001952;
}

.SelectPaymentPlanSubTitle.modal-title.h4 {
    width: 256px;
    height: 11px;
    margin-bottom: 20px;
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1.25rem;
    letter-spacing: 0.25px;
    text-align: center;
    color: #000000;
    ;
}

.SelectPaymentPlanModal .CloseButton {
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
    color: var(--primary-color);
    cursor: pointer;
}

.SelectPaymentPlanModal .CloseButton span .SelectPaymentPlanCloseIcon img {
    height: 18px;
}

.secondary {
    background-color: transparent;
}

.SelectPaymentPlanModal .modal-footer .Button {
    width: 100%;
    min-width: 344px;
    height: 56px;
    align-items: flex-end;
    letter-spacing: 1px;
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.375rem;
}

.SelectPaymentPlanModal .modal-footer .change-plan-status-button {
    margin-top: 3px;
    color: #001952;
    border-bottom: 0px;
    text-decoration: underline;
    margin-bottom: 0;
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.375rem;
    letter-spacing: 0.5px;
}

.SelectPaymentPlanModal .modal-footer .Button:first-child {
    margin: 0 auto;
}

.TermsSection .component__Checkbox {
    width: 14px;
    height: 14px;
    margin-right: 20px;
}

.SelectPaymentPlan-terms-section .component__Checkbox .checkbox__IconContainer img {
    width: 14px;
    height: 14px;
}

.SelectPaymentPlanModal .modal-dialog .modal-footer {
    margin-top: 1rem;
}

.customModal.SelectPaymentPlanModal .modal-dialog .Vital-care-tip-page-v2 .tip {
    padding-top: 6px;
}

/* Mobile responsive style below */
@media only screen and (max-width: 670px) {
    .customModal.SelectPaymentPlanModal .modal-dialog {
        padding: 55px 18px 22px;
        overflow: auto;
        top: 13%;
        max-width: 357px;
    }

    .SelectPaymentPlanModal .modal-footer .Button {
        width: 100%;
        min-width: 320px;
        height: 56px;
        align-items: flex-end;
        cursor: pointer;
    }

    .SelectPaymentPlanTitle.modal-title.h4 {
        font-size: 1.125rem;
        line-height: 1.313rem;
        height: 48px;
        margin-bottom: 2px;
        box-sizing: border-box;
        max-width: 304px;
        padding: 0px;
    }

    .SelectPaymentPlanSubTitle.modal-title.h4 {
        width: 256px;
        height: 11px;
        margin-bottom: 24px;
        font-size: 0.75rem;
        font-weight: 400;
        line-height: 1.25rem;
        letter-spacing: 0.25px;
        text-align: center;
        color: #000000;
    }

    .SelectPaymentPlanModal .CloseButton {
        top: 1.125rem;
        right: 1.125rem;
    }

    .SelectPaymentPlanBodyData {
        padding: 12px 10px;
    }

    .SelectPaymentPlanBodyData .heading {
        font-size: 0.875rem;
    }

    .SelectPaymentPlanBodyData .description {
        font-size: 0.875rem;
    }

    .TermsSection .terms-checkbox-row-section {
        font-size: 0.75rem;
        margin-top: 0.625rem;
    }

    .SelectPaymentPlanModal .modal-footer .Button:last-child {
        margin-top: 5px;
    }

    .SelectPaymentPlanModal .modal-footer .change-plan-status-button {
        color: #001952;
        border-bottom: 0px;
        text-decoration: underline;
    }
}