.registration-header span {
  color: white;
  font-size: 1.3125rem;
  font-weight: lighter;
}

.registration-header .petco-header {
  font-weight: bold;
}

.registration-logo-header {
  padding-bottom: 16px;
}

.registration-header svg {
  width: 180px;
}

.progress-container {
  max-width: 68.4375rem;
  margin-top: 16px;
  margin-right: auto;
  margin-left: auto;
  padding-left: 1.625rem;
  padding-right: 1.625rem;
}

/* .progress-container .component__StepProgress > div {
  background-color: #d8dfed;
} */

.progress-container .component__StepProgress>div>div {
  background-color: var(--step-progress-bar);
}

.progress-container .component__StepProgress svg path {
  fill: var(--primary-color);
}

.progress-container .component__StepProgress span {
  color: var(--primary-color);
}

.registration-container {
  padding: 16px 0;
}

.registration-container h1,
title {
  color: #001952;
  font-size: 1.5625rem;
  font-weight: 700;
  line-height: 2rem;
  letter-spacing: 0.25px;
}

.registration-container label {
  margin-bottom: 0.4375rem;
  width: 100%;
  font-size: 0.8125rem;
}

.registration-container input {
  width: 99.5%;
  height: 2.75rem;
  border: 0.0625rem solid #9dabc9;
  border-radius: 0.3125rem;
  font-family: var(--primary-font);
  font-size: 0.9375rem;
  line-height: 1.0625rem;
  text-indent: 0.9375rem;
  color: #656565;
}

.registration-container input:focus {
  outline: none;
}

.registration-container .button-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
}

.registration-container .birthday-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: center;
  align-content: center;
}

.registration-container.build-quote-container .component__button {
  margin-top: 1rem;
}

.registration-container label {
  color: #000941;
}

.registration-container label span {
  margin-right: 4px;
  color: red;
}

.registration-container input::placeholder {
  color: #333333;
}

.registration-container input:-ms-input-placeholder {
  color: #333333;
}

.registration-container input::-ms-input-placeholder {
  color: #333333;
}

.registration-note {
  width: 100%;
  height: 2.375rem;
  color: var(--primary-color-two);
  font-family: var(--primary-font);
  font-size: 0.9375rem;
  letter-spacing: 0.005rem;
  line-height: 1.1875rem;
  text-align: center;
  margin-top: 1.125rem;
}

.registration-field-set {
  box-shadow: none;
}

.ACIPaymentModalBackButton {
  border: none;
  background: white;
  color: #001952;
  font-weight: 700;
  margin-top: 0.5rem;
  cursor: pointer;
  line-height: 22px;
  letter-spacing: 0.5px;
}

.ACIPaymentModalCloseButton {
  cursor: pointer;
  position: absolute;
  right: 1.3rem;
  border: 0;
  background-color: transparent;
}

.iframe-close-button {
  float: right;
  background-color: transparent;
  border: 0;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5;
  cursor: pointer;
}

@media only screen and (max-width: 546px) {
  .registration-container {
    padding-top: 0;
  }
}

@media only screen and (max-width: 670px) {
  .registration-container .title {
    padding: 0;
  }
}