.Button-v2 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 56px;
    border: 0;
    border-radius: 5px;
    box-sizing: border-box;
    font-size: 1rem;
    font-weight: 700;
    text-align: center;
    text-decoration: none;
    color: #FFFFFF;
    background-color: var(--primary-color);
    cursor: pointer;
    margin: 9px auto;
}

@media (min-width: 670px) {
    .Button-v2 {
        max-width: 18.75rem;
        margin: 9px auto;
    }

    .Button-v2.secondary.right {
        margin: 0;
        float: right;
    }
}

.Button-v2:disabled {
    border: 0rem solid #a9b5ba;
    color: #5f5f5f;
    background-color: #b8b8b8;
    pointer-events: none;
}

.Button-v2.secondary {
    color: var(--primary-color);
    border: 0px solid var(--primary-color);
    background-color: white;
}

.ButtonLink {
    display: inline-block;
    width: 100%;
    padding: 1rem 1.5rem;
    border: 0;
    border-radius: 0.25rem;
    box-sizing: border-box;
    font-size: 1rem;
    font-weight: bold;
    text-align: center;
    text-decoration: none;
    line-height: 1.2;
    color: #FFFFFF;
    background-color: var(--primary-color);
    cursor: pointer;
}

.ButtonLink:focus{
    outline-color: var(--outline-color);
}

.ButtonLink.TextLink {
    padding: 0;
    text-decoration: underline;
    text-align: left;
    color: var(--primary-color);
    background-color: transparent;
}

.ButtonLink:disabled {
    border: 0.0625rem solid #a9b5ba;
    color: #5f5f5f;
    background-color: #b8b8b8;
    pointer-events: none;
}

.Button-v2.secondary-redemptionACIModal {
  color: var(--primary-color);
  background-color: white;
  text-decoration: underline;
  width: inherit;
}

.Button-v2.secondary {
    color: var(--primary-color);
    border: 1px solid var(--primary-color);
    background-color: white;
}

.Redeemed_ThirdPartyCoupon_Button {
    display: flex;
    justify-content: center;
    width: 100%;
    border: 0;
    border-radius: 0px;
    box-sizing: border-box;
    font-size: 1rem;
    font-weight: 700;
    text-align: center;
    text-decoration: none;
    line-height: 1.375rem;
    background: #E5E5E5;
    color: #000000;
    cursor: pointer;
    margin: 9px auto;
    padding: 1.2rem 1.5rem 1.2rem;
    letter-spacing: 0.5px;
}