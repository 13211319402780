.customModal.ReactivationModal .modal-dialog {
  width: calc(100% - 9px);
  max-width: 590px;
  padding: 42px 35px 70px;
}

.customModal.ReactivationModalYearly .modal-dialog {
  width: calc(100% - 9px);
  max-width: 590px;
  padding: 42px 35px 110px;
}

.ReactivationModalYearly .modal-dialog .modal-footer {
  margin-top: 16px;
}

.ReactivationModal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  line-height: 2rem;
}

.ReactivationTitle.modal-title.h4 {
  text-align: center;
  margin-bottom: 20px;
  letter-spacing: 0.25px;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.5rem;
}

.ReactivationModal .CloseButton {
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  color: var(--primary-color);
  cursor: pointer;
}

.ReactivationModal .CloseButton span .ReactivationCloseIcon img {
  height: 18px;
}

.secondary {
  background-color: transparent;
}

.ReactivationModal .modal-footer .Button {
  width: 100%;
  max-width: 344px;
  height: 56px;
  align-items: flex-end;
  cursor: pointer;
  margin: 0 auto;
  font-size: 1rem;
  letter-spacing: 1px;
  line-height: 1.375rem;
  font-weight: 700;
}

.ReactivationModal .modal-footer .change-plan-status-button {
  margin-top: 17px;
  height: 22px;
  cursor: pointer;
  padding: 0;
}

.ReactivationModal .modal-footer .change-plan-status-button span {
  color: #001952;
  height: 22px;
  line-height: 1.375rem;
  border-bottom: 0;
  text-decoration: underline;
  font-weight: 700;
  letter-spacing: 0.5px;
  font-size: 1rem;
}

.ReactivationModal .modal-footer .Button:first-child {
  margin: 0 auto;
}

.TermsSection .component__Checkbox {
  width: 14px;
  height: 14px;
  margin-right: 20px;
}

.Reactivation-terms-section .component__Checkbox .checkbox__IconContainer img {
  width: 14px;
  height: 14px;
}


/* switchToYearlyModal style below */

.customModal.switchToYearlyModal .modal-dialog {
  top: 4vh;
  padding: 40px 35px 32px 35px;
}

.switchToYearlyModal .ReactivationTitle.modal-title.h4 {
  text-align: center;
  margin-bottom: 38px;
  font-weight: 700;
  font-size: 1.563rem;
  line-height: 1.875rem;
  letter-spacing: 0.25px;
  padding: 0;
  height: 32px;
}

.switchToYearlyModal .modal-footer .Button:first-child {
  margin-top: 1.63px;
  letter-spacing: 1px;
}

.switchToYearlyModal .SelectedPaymentDetail {
  width: 100%;
  border: 2px solid #001952;
  box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.11);
  border-radius: 5px;
  margin-bottom: 13px;
}

.switchToYearlyModal .ActualPriceStrikeThroughText {
  margin-right: 7px;
}

.switchToYearlyModal .ReactivationBodyData {
  padding: 10px 58px 20px 16px;
}

.switchToYearlyModal .ReactivationBodyData .heading {
  font-size: 0.875rem;
}

.switchToYearlyModal .ReactivationBodyData .description {
  font-size: 0.875rem;
  /* margin-bottom: 8px; */
}

.switchToYearlyModal .PetPriceDetailsContainer .PetDetailsTitle {
  color: #000000;
}

.switchToYearlyModal .ReactivationTermsSection .terms-checkbox-row-section {
  margin-top: 23px;
}

.switchToYearlyModal .modal-dialog .modal-footer {
  margin-top: 32px;
}

.switchToYearlyModal .confirmButton {
  max-width: 344px;
}

.switchToYearlyModal .modal-footer .change-plan-status-button {
  color: #001952;
  height: 22px;
  line-height: 22px;
  padding: 0;
  margin: 18px auto 0;
}

.switchToYearlyModal .modal-footer .change-plan-status-button span {
  color: #001952;
  border: 0;
  text-decoration: underline;
}

.switchToYearlyModal .ReactivationTermsSection .terms-checkbox-row-section p {
  line-height: 1.125rem;
}

.switchToYearlyModal .ReactivationBodyData .annual-renewal-text {
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.125rem;
  letter-spacing: 0.4px;
  text-align: left;
  margin-top: 3px;
}

/* Mobile responsive style below */
@media only screen and (max-width: 670px) {
  .customModal.ReactivationModal .modal-dialog {
    padding: 55px 18px 22px 19px;
    top: 10vh;
    max-width: 357px;
    overflow: auto;
  }

  .switchToYearlyModal.customModal.ReactivationModal .modal-dialog {
    padding: 29px 18px 37px 19px;
  }

  .ReactivationTitle.modal-title.h4 {
    font-size: 1.125rem;
    line-height: 1.313rem;
    margin-bottom: 7px;
    letter-spacing: 0.15px;
  }

  .switchToYearlyModal .ReactivationTitle.modal-title.h4 {
    margin-bottom: 28px;
    font-weight: 700;
    font-size: 1.25rem;
    line-height: 1.5rem;
  }

  .ReactivationModal .CloseButton {
    top: 1.125rem;
    right: 1.125rem;
  }

  .ReactivationBodyData {
    padding: 12px 10px;
  }


  .TermsSection .terms-checkbox-row-section {
    font-size: 0.75rem;
    margin-top: 0.625rem;
  }

  .ReactivationModal .modal-dialog .modal-footer {
    margin-top: 16px;
  }

  .ReactivationModal .modal-footer .Button {
    max-width: 320px;
  }

  .ReactivationModal .modal-footer .Button:last-child {
    margin-top: 12px;
  }

  .switchToYearlyModal .SelectedPaymentDetail {
    padding: 23px 10px 15px 8px;
  }

  .switchToYearlyModal .PetPriceDetailsContainer .PetDetailsTitle {
    width: 115px;
    height: auto;
    line-height: 1.25rem;
  }

  .switchToYearlyModal .SelectedPaymentDetail {
    margin-bottom: 11px;
  }

  .switchToYearlyModal .ReactivationBodyData {
    padding: 10px 12px 15px;
  }

  .switchToYearlyModal .ReactivationTermsSection .terms-checkbox-row-section {
    margin-top: 19px;
  }

  .switchToYearlyModal .modal-dialog .modal-footer {
    margin-top: 7px;
  }

  .switchToYearlyModal.ReactivationModal .modal-footer .Button:last-child {
    margin-top: 27px;
  }

}
