.AddClinicModal-v2 .component__buttonGroup {
    margin-top: 20px;
}

/* do not group these rules */

.AddClinicModal-v2 input::-webkit-input-placeholder {
    color: var(--primary-color-five);
}

.AddClinicModal-v2 input:-moz-placeholder {
    /* FF 4-18 */
    color: var(--primary-color-five);
}
.AddClinicModal-v2 .Button{
    letter-spacing: 0.5px;
    line-height: 1.375rem;
    font-weight: 700;
}

.AddClinicModal-v2 input::-moz-placeholder {
    /* FF 19+ */
    color: var(--primary-color-five);
}

.AddClinicModal-v2 input::-ms-input-placeholder {
    /* IE 10+ */
    color: var(--primary-color-five);
}

.AddClinicModal-v2 input::-ms-input-placeholder {
    /* Microsoft Edge */
    color: var(--primary-color-five);
}

.AddClinicModal-v2 input::placeholder {
    /* modern browser */
    color: var(--primary-color-five);
}

.AddClinicModal-v2 #state div[class$="-placeholder"] {
    color: var(--primary-color-five);
}

/* Hide the scroll on the Add Vet Modal but keep functionally */
.add-vet-modal-v2 .modal-dialog {
    height: 100%;
    max-height: 700px;
    padding-left: 17px !important;
    padding-right: 17px !important;
    width: 80%;
    overflow-y: scroll;
    -ms-overflow-style: none;  /* Hide scrollbar for Edge */
    scrollbar-width: none;  /* Hide scrollbar for Firefox */
}

@media (min-width: 670px) {
    .add-vet-modal-v2.modal > .modal-dialog {
        width: 90%;
        max-width: 654px;
        max-height: 460px;
    }
}

@media (min-width: 769px) and (max-width: 975px) {
    .add-vet-modal-v2.modal > .modal-dialog {
        max-height: 40% !important;
        top: 30% !important;
    }
}

@media screen and (min-width: 976px) {
    .add-vet-modal-v2.modal > .modal-dialog {
        max-height: 40% !important;
        top: 30% !important;
    }
}

/* Hide scrollbar for Chrome, Safari and Opera */
.add-vet-modal-v2 .modal-dialog::-webkit-scrollbar {
    display: none;
}

.add-vet-modal-v2 #modal-title {
    margin-top: 1.5625rem;
    margin-bottom: 1.25rem;
    font-weight: bold;
    font-family: var(--primary-font-bold);
}

.add-vet-modal-v2 #modal-title h1 {
    line-height: 0rem;
    padding: 0rem;
    font-size: 1.5625rem;
}

.AddClinicModal-v2 .add-clinic-modal-grid-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: auto;
    grid-column-gap: 10px;
    grid-row-gap: 0px;
}

.AddClinicModal-v2 .name-box {
    grid-column: 1/5;
    grid-row: 1;
}

.AddClinicModal-v2 .address-box {
    grid-column: 1/5;
    grid-row: 2;
}

.AddClinicModal-v2 .city-box {
    grid-column: 1/5;
    grid-row: 3;
}

.AddClinicModal-v2 .state-box {
    grid-column: 1/3;
    grid-row: 4;
}

.AddClinicModal-v2 .zip-box {
    grid-column: 3/5;
    grid-row: 4;
}

.AddClinicModal-v2 .phone-number-box {
    grid-column: 1/5;
    grid-row: 5;
}

.AddClinicModal-v2 .fax-number-box {
    grid-column: 1/5;
    grid-row: 6;
}

@media (min-width: 670px) {
    .AddClinicModal-v2 .add-clinic-modal-grid-container {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: auto;
        grid-column-gap: 10px;
        grid-row-gap: 0px;
    }

    .AddClinicModal-v2 .name-box {
        grid-column: 1/3;
        grid-row: 1;
    }

    .AddClinicModal-v2 .address-box {
        grid-column: 3/5;
        grid-row: 1;
    }

    .AddClinicModal-v2 .city-box {
        grid-column: 1/3;
        grid-row: 2;
    }

    .AddClinicModal-v2 .state-box {
        grid-column: 3/4;
        grid-row: 2;
    }

    .AddClinicModal-v2 .zip-box {
        grid-column: 4/5;
        grid-row: 2;
    }

    .AddClinicModal-v2 .phone-number-box {
        grid-column: 1/3;
        grid-row: 3;
    }

    .AddClinicModal-v2 .fax-number-box {
        grid-column: 3/5;
        grid-row: 3;
    }
}
