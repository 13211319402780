.BenefitUsageHistoryPage {
    padding-bottom: 1rem;
}

.BenefitUsageHistoryPage .Container .title:first-of-type {
    font-size: 1.5625rem;
}

.BenefitUsageHistoryPage .Container:first-of-type {
    padding-top: 1.5rem;
    padding-top: 1.5rem;
    margin-bottom: 1.5rem;
}

@media (min-width: 670px) {
    .BenefitUsageHistoryPage .Container:first-of-type {
        max-width: 37.5rem;
    }
}

.BenefitUsageHistoryPage .Container .error-container {
    display: flex;
    flex-direction: column;
}

.BenefitUsageHistoryPage .Container .error-title {
    text-align: center;
    margin-bottom: 1rem;
}

.BenefitUsageHistoryPage .Container .component__button {
    width: 50%;
    align-self: center;
}

.BenefitUsageHistoryPage .Container .titleHeader {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
    margin-top: 1rem;
}

.BenefitUsageHistoryPage .Container .title.topTitle {
    margin: 0;
    padding: 0;
}

.BenefitUsageHistoryPage .Container .backButton {
    cursor: pointer;
    padding-bottom: 0.5rem;
}

.BenefitUsageHistoryPage .Container .title {
    margin-bottom: 1rem;
    font-size: 1.125rem;
    font-weight: 600;
    color: var(--primary-color);
}

@media (min-width: 670px) {
    .BenefitUsageHistoryPage .Container .title:first-of-type {
        text-align: center;
    }
}

.BenefitUsageHistoryPage .Container .Card {
    overflow: hidden;
    border-radius: 0.25rem;
}

.BenefitUsageHistoryPage .Container .Card .card-graph {
    padding: 1.25rem;
}

.BenefitUsageHistoryPage .Container .Card:not(:last-of-type) {
    margin-bottom: 1rem;
}

.BenefitUsageHistoryPage .Container .List__Header {
    padding: 0.75rem;
}

.BenefitUsageHistoryPage .Container .List__Item {
    display: flex;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
}

.BenefitUsageHistoryPage .Container .List__Footer {
    font-size: 0.8125rem;
    background-color: transparent;
    color: var(--list-history-footer);
    display: flex;
    font-weight: 600;
    align-items: center;
    justify-content: center;
    border-top: 1px solid #D8D8D8;
}

.BenefitUsageHistoryPage .Container .benefit-name-date {
    display: flex;
    width: 70%;
    flex-direction: column;
    color: var(--primary-color);
}

.BenefitUsageHistoryPage .Container .no-usages {
    display: flex;
    width: 100%;
    flex-direction: column;
}

.BenefitUsageHistoryPage .Container .benefit-total-spent-value {
    font-size: 1.5rem;
    font-weight: bold;
}

.BenefitUsageHistoryPage .Container .benefit-price {
    font-size: 0.8125rem;
    font-weight: bold;
    color: var(--primary-color);
}

.BenefitUsageHistoryPage .Container .benefit-name {
    font-weight: 600;
}

.BenefitUsageHistoryPage .Container .status-header {
    font-size: 0.625rem;
    font-weight: bold;
    color: var(--primary-color-four);
}

.BenefitUsageHistoryPage .Container .status {
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-self: end;
}

.BenefitUsageHistoryPage__Graph {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    /* Not sure if this is correct, looked wonky, need designs */
}

.BenefitUsageHistoryPage__Graph .innerCircleGraph {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    position: absolute;
    width: 11.25rem;
    height: 11.25rem;
    border-radius: 5.625rem;
}

.BenefitUsageHistoryPage__Graph .innerCircleGraph .iconReward {
    margin-bottom: 0.25rem;
}

.BenefitUsageHistoryPage__Graph .innerCircleGraph .rewards {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.BenefitUsageHistoryPage__Graph .innerCircleGraph .rewards-value {
    display: flex;
    align-items: flex-start;
    color: var(--primary-color);
}

.BenefitUsageHistoryPage__Graph .innerCircleGraph .rewards span {
    margin-bottom: 0.25rem;
    font-size: 1.5625rem;
    font-weight: bold;
}

.BenefitUsageHistoryPage__Graph .innerCircleGraph .rewards__value h3, .BenefitUsageHistoryPage__Graph .innerCircleGraph .rewards__value p {
    color: var(--primary-color);
}

.BenefitUsageHistoryPage__Graph .innerCircleGraph .rewards p {
    width: 7.5rem;
    text-align: center;
    line-height: 1.2;
}

.BenefitUsageHistoryPage #canvas {
    display: block;
    max-width: 100%;
    margin: 0 auto;
}

.BenefitUsageHistoryPage .ListHeaderWrapper {
    display: flex;
    justify-content: space-between;
    font-size: 1.25rem;
    align-items: center;
}

.BenefitUsageHistoryPage .ListHeaderWrapper span {
    color: var(--primary-color);
    font-weight: bold;
    font-size: 1.25rem;
}

.BenefitUsageHistoryPage .ListHeaderWrapper span .white-color {
    color: white;
}

.BenefitUsageHistoryPage .ListFooterWrapper {
    display: flex;
    justify-content: space-between;
    font-size: 1.5rem;
    align-items: center;
}

.BenefitUsageHistoryPage .ListFooterWrapper .Price {
    font-size: inherit;
}