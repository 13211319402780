.TransitionModalBody .title {
    line-height: 1.2;
    padding-top: 0;
}

.TransitionModalBody .title, 
.TransitionModalBody .component__text {
    color: var(--primary-color);
}

.TransitionModalBody .pet-name {
    font-size: 1rem;
    font-weight: 600;
}

.TransitionModalBody .explanation {
    margin-top: 0.625rem;
    margin-bottom: 0.9375rem;
}

.TransitionModalBody .warning {
    color: var(--orangeWarning);
    margin-bottom: 0.9375rem;
}

.TransitionModalBody .pet-transition-choice {
    display: block;
    margin-top: 20px;
    margin-bottom: 10px;
}

.TransitionModalBody .pet-transition-choice .title {
    margin-bottom: 0.5rem;
}

.TransitionModalBody .choices {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 14px;
}

.TransitionModalBody .blurb.marked {
    box-shadow: inset 0 0 0 .125rem var(--primary-color);
}

.TransitionModalBody .blurb {
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 12px;
    border-radius: 0.3125rem;
    box-shadow: 0px 3px 6px #33333366;
}

.TransitionModalBody .blurb .blurb-text {
    font-weight: 300;
}

.TransitionModalBody .blurb .badge-wrapper {
    border-radius: 0.3125rem;
    background-color: var(--lightBlue);
    margin-bottom: 1.5625rem;
    font-weight: 600;
    padding: 0.25rem 0.5rem;
    align-self: center;
}

.TransitionModalBody .blurb .badge-wrapper .badge {
    font-size: 0.8125rem;
}

.TransitionModalBody #save-button {
    margin-top: 1.25rem;
    margin-bottom: 0.625rem;
}

.TransitionModalBody #pet-transition-cards {
    max-height: 30rem;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
}

/* ::-webkit-scrollbar {
    width: 0;  
    background: transparent;
} */


::-webkit-scrollbar-track {
    background-color: transparent;
  }
  
  ::-webkit-scrollbar {
    width: 0.5rem;
    background-color: rgba(0,0,0,0);
  }
  
  ::-webkit-scrollbar:hover {
    background-color: rgba(0, 0, 0, 0.09);
  }
  
  ::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.3);
    border: 0.125rem solid #F5F5F5;
    border-radius: 6.25rem;
    -webkit-border-radius: 6.25rem;
  }
  
  ::-webkit-scrollbar-thumb:active {
    background: rgba(0,0,0,0.61);
    border-radius: 6.25rem;
    -webkit-border-radius: 6.25rem;
  }