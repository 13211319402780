.SelectPaymentMethod button:nth-of-type(2) {
  margin-top: 15px;
}

.SelectPaymentMethod button {
  max-width: calc(100% - 20px);
  height: 56px;
  line-height: 22px;
  letter-spacing: 1px;
}

.SelectPaymentMethod-RedemptionModal {
  margin-top: 25px;
}

.creditCardIconRow {
  display: flex;
  justify-content: left;
  align-items: center;
}

.paymentMethodTableContent {
  max-height: 360px;
  overflow: auto;
  overscroll-behavior: none;
}

.paymentMethodTableContent ::-webkit-scrollbar {
  background: transparent;
  /* Chrome/Safari/Webkit */
  display: none;
  width: 0px;
}

.disable-scrollbars {
  scrollbar-width: none;
  /* Firefox */
  -ms-overflow-style: none;
  /* IE 10+ */
}

.radioPaymentTableRow {
  height: 72px;
  vertical-align: middle;
}

.radioPaymentTableRow:hover {
  background-color: #F5F8FF;
  cursor: pointer;
}

.radioPaymentTableData {
  border: solid lightgray;
  vertical-align: middle;
  border-width: 1px;
  border-bottom: none;
  letter-spacing: 0.5px;
  font-weight: 400;
  line-height: 25px;
}

.addNewCreditCardData-redemptionACIModal {
  border: solid lightgray;
  vertical-align: middle;
  border-width: 1px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.addNewCreditCardData {
  border: solid lightgray;
  vertical-align: middle;
  border-width: 1px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  position: sticky;
  inset-block-end: 0;
  background-color: white;
}

.addNewCreditCardData:hover {
  background-color: #F6F8FF !important;
}

.creditCardRadioRowIcon {
  height: 30px
}

.creditCardRadioRowText {
  margin-left: 20px;
}

.radioPaymentTableData:has(input:checked) {
  background-color: #F5F8FF;
  font-weight: 700;
  color: #333333;
}

.saveButton-redemptionACIModal {
  position: relative;
  color: white;
  background-color: #001952;
  margin: 2em 0 2em 0;
  width: 215px;
  border: none;
  border-radius: 5px;
  font-weight: 700;
  cursor: pointer;
  margin-bottom: 0;
}

.saveButton {
  position: relative;
  background-color: #FFD405;
  margin: 2em 0 2em 0;
  width: 215px;
  border: none;
  border-radius: 5px;
  font-weight: 700;
  cursor: pointer;
  color: #001952;
}

.buttonContainer-redemptionACIModal {
  width: 100%;
  text-align: center;
}

.buttonContainer {
  width: 100%;
  text-align: right;
}

.radioPaymentTableInput {
  height: 20px !important;
  width: 20px !important;
  margin-left: 1.5em;
  margin-right: 1.5em !important;
  accent-color: #001952;
  -webkit-appearance: none;
  appearance: none;
  border: 0.0625rem solid #656565 !important;
  border-radius: 50% !important;
}

.radioPaymentTableInput:checked {
  box-shadow: 0 0 0 1px #001952;
  background-color: #001952;
  border-width: 0.2rem !important;
  border: 0.2rem solid #fff !important;
}

.radioPaymentTableDataFirst {
  border: solid lightgray;
  vertical-align: middle;
  border-width: 1px;
  border-bottom: none;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
  letter-spacing: 0.5px;
  font-weight: 400;
  line-height: 25px;
}

.radioPaymentTableDataFirst:has(input:checked) {
  background-color: #F5F8FF;
  font-weight: 700;
  color: #333333;
}

.addNewCreditCardRow-redemptionACIModal {
  position: sticky;
  inset-block-end: 0;
  height: 75px;
  color: #001952;
  font-weight: bold;
  text-align: center;
  height: 72px;
  cursor: pointer;
}

.addNewCreditCardRow-redemptionACIModal:hover {
  background-color: #f7f7f7;
}

.addNewCreditCardRow {
  color: #001952;
  font-weight: bold;
  text-align: center;
  height: 72px;
  background-color: #FFFFFF;
  cursor: pointer;
}

.addNewCreditCardRow:hover {
  background-color: #F8F7F9;
}

.PaymentModalHeader {
  text-align: center;
  font-weight: bold;
}