.PetCardList-v2 {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: auto;
    min-width: 100%;
    margin: 0;
    gap: 20px;
    justify-content: center;
}

@media (min-width:670px) {
    .PetCardList-v2 {
        justify-content: center;
    }
}

.PetCardList__Item-v2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    box-sizing: border-box;
    width: 160px;
    height: 110px;
    padding: 18px 0 13px;
    margin: 0;
    border: 1px solid #666666;
    background-color: transparent;
    border-radius: 0.25rem;
    list-style: none;
    cursor: pointer;
    position: relative;
}
/* Progressively increase the fixed width of the item. Item must have a fixed width.
It must start from the left, but at the same time should seem centered on the screen.
This does not bring any concerns on performance on the client's browser/app because
the screen does not change so often, just portrait/landscape orientation at its
maximum. Biggest smartphones are around 414px(iPhoneXR) and the smaller size we
support is 375px wide. This give us the appearance that's being asked. Above 670px
another media query centers the content justifying the content.
*/

.PetCardList__Item-v2:focus {
    outline-color: var(--outline-color);
    box-shadow: 0 0 0 1px white, 0 0 0 3px #cccccc;
}

.PetCardList__Item-v2.PetCardList__Item-v2--Marked {
    box-shadow: inset 0 0 0 0.125rem var(--primary-color);
}

.PetCardList__Item-v2.PetCardList__Item-v2--Prioritized {
    order: 0;
}

.PetCardList__IconWrapper-v2 svg path {
    fill: var(--primary-color);
}

.PetCardList__Coin-v2 {
    position: absolute;
    top: 10px;
    left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    font-size: 14px;
    font-weight: bold;
    color: var(--primary-color);
    border: 1px solid var(--primary-color);
    box-sizing: border-box;
}

.PetCardList__Coin-v2--Marked {
    background-color: var(--secondary-color);
    border: unset;
}

.PetCardList__Coin-v2--UM {
    background-color: var(--primary-color);
}

.PetCardList__Coin-v2--UM .Icon img{
    margin-top: 7px;
}

.PetCardList__Coin-v2 .currency {
    transform: translateY(-1.5px);
    font-size: 8px;
}

.PetCardList__Name-v2 .title {
    max-width: 104px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.375rem;
    letter-spacing: 0.5px;
    text-align: center;
    color: #001952;
    /* TODO: Figure out what to call this color, make CSS variable */
    /* margin-bottom: 1rem; */
    padding-bottom: 0;
}

/* .PetCardList__IconWrapper-v2-Group{
    margin-right: 1rem;
} */

.PetCardList__IconWrapper-v2 .plus-sign {
    position: absolute;
    top: 14px;
    left: 10px;
    font-weight: bold;
}

.addPetV2 .plus-sign {
    position: absolute;
    top: 8px;
    left: 20px;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #333333;
}

@media (max-width: 670px) { 
    .PetCardList__Item-v2 img {
        max-width: 95px;
    }
    .PetCardList__Name-v2 .title {
        margin-top: 8px;
        margin-bottom: 0px;
    }
}
