.PetcoLogoSharedComponent-v2 .Container .top-logo-wrapper .logoPetcoVitalCare {
    width: 166px;
    height: 34px;
}

.PetcoLogoSharedComponent-v2 .Container .top-logo-wrapper .iconBackToDashboard.showBackToDashboard {
    display: inline-block;
}

.PetcoLogoSharedComponent-v2 .Container .top-logo-wrapper {
    display: flex;
    align-items: flex-end;
    padding: 0;
    overflow: hidden;
}

.PetcoLogoSharedComponent-v2 .Container .top-logo-space-between {
    justify-content: space-between;
}

.PetcoLogoSharedComponent-v2 .Container .top-logo-center {
    justify-content: center;
}

.PetcoLogoSharedComponent-v2 .Container .top-logo-wrapper button {
    background-color: transparent;
    border: none;
}

.PetcoLogoSharedComponent-v2 .Container .top-logo-wrapper .iconBackToDashboard {
    display: none;
    cursor: pointer;
}
.PetcoLogoSharedComponent-v2 .Container .top-logo-wrapper .UM-logo{
    width:293px;
    height: 40px;
}
.RedemptionDashboardPage-v2 .PetcoLogoSharedComponent-v2 .Container .top-logo-wrapper .UM-logo img{
    max-width: 100%;
    max-height: 100%;
}