.CancelRenewReasonModal form .comment-wrapper {
    clear: both;
    margin-bottom: 15px;
}

.CancelRenewReasonModal h1 {
    font-size: 1.5rem;
    padding: 0.625rem 0rem;
    line-height: 2rem;
    font-weight: 700;
}

.CancelRenewReasonModal .modal-body p {
    font-size: 1rem;
    font-weight: 400;
}

.CancelRenewReasonModal .modal-body label {
    font-weight: 400;
}

.CancelRenewReasonModal form .comment-wrapper textarea.comment-text-area {
    font-weight: 400;
}

.comment-text-area::placeholder {
    color: #656565;
}

.comment-text-area:focus{
    outline-color:var(--outline-color);
}

.comment-text-area::-webkit-input-placeholder {
    color: #656565;
    opacity: 1;
}

.comment-text-area::-moz-placeholder {
    color: #656565;
    opacity: 1;
}

.CancelRenewReasonModal form label {
    font-size: 16px;
}

.CancelRenewReasonModal form label:last-child {
    margin-bottom: 8px;
}

.CancelRenewReasonModal .ButtonGroup {
    clear: both;
    display: flex;
    flex-direction: column;
}

.reason-list{
    margin: 0;
}