.Button {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 1rem 1.5rem;
    border: 0;
    border-radius: 0.25rem;
    box-sizing: border-box;
    font-size: 1rem;
    font-weight: 700;
    text-align: center;
    text-decoration: none;
    line-height: 1.2;
    color: #FFFFFF;
    background-color: var(--primary-color);
    cursor: pointer;
    margin: 9px auto;
    padding: 0.9rem;
}

@media (min-width: 670px) {
    .Button {
        max-width: 18.75rem;
        margin: 9px auto;
    }
}

.Button:disabled {
    border: 0.0625rem solid #a9b5ba;
    color: #5f5f5f;
    background-color: #b8b8b8;
    pointer-events: none;
}

.ButtonLink {
    display: inline-block;
    width: 100%;
    padding: 1rem 1.5rem;
    border: 0;
    border-radius: 0.25rem;
    box-sizing: border-box;
    font-size: 1rem;
    font-weight: 700;
    text-align: center;
    text-decoration: none;
    line-height: 1.2;
    color: #FFFFFF;
    background-color: var(--primary-color);
    cursor: pointer;
}

.ButtonLink.TextLink {
    padding: 0;
    text-decoration: underline;
    text-align: left;
    color: var(--primary-color);
    background-color: transparent;
}

.ButtonLink:disabled {
    border: 0.0625rem solid #a9b5ba;
    color: #5f5f5f;
    background-color: #b8b8b8;
    pointer-events: none;
}

.Button.secondary {
    color: var(--primary-color);
    border: 1px solid var(--primary-color);
    background-color: white;
}

.change-plan-status-button {
    background-color: white;
    color: black;
    border: none;
    cursor: pointer;
    pointer-events: visible;
    font-size: 14px;
}

.change-plan-status-button span {
    border-bottom: 1px solid black;
}