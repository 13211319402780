.MembershipDetailsPage {
  height: auto;
  min-height: 900px;
}

.MembershipDetailsPage .Container #pet-plan-title {
  margin-bottom: 1.25rem;
  color: var(--primary-color);
  line-height: 2rem;
  letter-spacing: 0.25px;
}

.MembershipDetailsPage .Container .titleHeader {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
  margin-top: 1rem;
}

.MembershipDetailsPage .Container .backButton {
  cursor: pointer;
  padding-bottom: 0.5rem;
}

.MembershipDetailsPage .Container .title.topTitle {
  margin: 0;
  padding: 0;
}

@media (min-width: 540px) {
  .MembershipDetailsPage .Container>.title {
    text-align: center;
  }
}

.MembershipDetailsPage .Container .Card {
  border-radius: 0.25rem;
  overflow: hidden;
}

.MembershipDetailsPage .List__Footer {
  background-color: var(--secondary-color-two);
  color: var(--primary-color);
  list-style: none;
  padding: 1rem 1.25rem;
}

.MembershipDetailsPage .List__Footer.List__Footer__UM {
  background-color: #DAF1E6;
}

.List__Footer__Margin {
  margin-top: 1rem;
  background-color: #EDFCF3;
}

.MembershipDetailsPage .ListFooterWrapper .footer-total {
  font-weight: bold;
}

.MembershipDetailsPage .ListFooterWrapper .price-observation {
  font-size: 12px;
}

.MembershipDetailsPage .Container .Card .Card__Header,
.MembershipDetailsPage .Container .Card .Card__Body {
  padding: 0rem;
}

.MembershipDetailsPage .Container .Card .Card__Header {
  height: unset;
}

.MembershipDetailsPage .Container .Card .Card__Header span {
  display: block;
  font-weight: 700;
  line-height: 1.375rem;
  margin-bottom: 0.25rem;
  letter-spacing: 0.5px;
  color: var(--primary-color);
}

@media (min-width: 670px) {
  .Container {
    max-width: 61.5rem;
  }
}

@media (max-width: 670px) {
  .MembershipDetailsPage .Container #pet-plan-title {
    line-height: 1.5rem;
  }
}

.MembershipDetailsPage .Container .membership-details-buttons {
  background-color: transparent;
  padding: 0;
  color: var(--primary-color-two);
  text-decoration: underline;
}

.MembershipDetailsPage .Container .Card__Body .editPaymentMethod {
  display: block;
  margin-top: 20px;
  margin-bottom: 0.25rem;
  font-size: 0.875rem;
  text-decoration: underline;
  color: var(--primary-color-seventeen);
  cursor: pointer;
  background-color: transparent;
  border: none;
  padding: 0;
  font-weight: 700;
  line-height: 1.25rem;
  letter-spacing: 0.25px;
}

.MembershipDetailsPage .Container .Card__Body .auto-renew {
  display: block;
  font-size: 0.8125rem;
  color: var(--auto-renew-color);
}

.MembershipDetailsPage .Container .grayed-button {
  color: gray;
  border: none;
  cursor: not-allowed;
  pointer-events: visible;
}

.MembershipDetailsPage .Container .wrapper {
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  justify-content: space-between;
}

.MembershipDetailsPage .PetListWrapper {
  box-shadow: 0rem 0.625rem 0.75rem #EFEFEF;
  margin-bottom: 0.75rem;
  padding-bottom: 1.5rem;
}

.MembershipDetailsPage #recurring-payment-header-with-spacing {
  margin-bottom: 0.25rem;
}

.MembershipDetailsPage #recurring-payment-header-without-spacing {
  margin-bottom: 0rem;
}

.MembershipDetailsPage .recurring-payment-section {
  padding-top: 10px;
  padding-bottom: 10px;
  color: var(--primary-color-sixteen);
}

.MembershipDetailsPage .recurring-payment-headline {
  font-size: 0.875rem;
  color: var(--primary-color-sixteen);
  font-weight: 700;
  line-height: 1.25rem;
  letter-spacing: 0.25px;
}

.MembershipDetailsPage .recurring-payment {
  font-size: 0.875rem;
  color: var(--primary-color-sixteen);
  font-weight: 400;
  line-height: 1.25rem;
  letter-spacing: 0.25px;
}

.MembershipDetailsPage .referral-savings-message {
  font-family: var(--primary-font);
  color: var(--primary-color);
  font-size: 16px;
  padding-top: 25px;
}

#root>div>div.registration-container>div.MembershipDetailsPage>div:nth-child(3)>div>div>div>div.List__Footer>div.ListFooterWrapper>div>span {
  font-weight: 700;
  font-family: var(--primary-font-bold);
}

.MembershipDetailsPage .PetListWrapper .title {
  padding-left: 0.625rem;
}

@media (min-width: 670px) {
  .MembershipDetailsPage .PetListWrapper .title {
    text-align: center;
    padding-left: 0;
  }
}

.MembershipDetailsPage .ListFooterWrapper {
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  font-size: 1.5rem;
  -webkit-box-align: center;
  align-items: center;
}

.MembershipDetailsPage .ListFooterWrapper .Price {
  font-size: inherit;
}

.MembershipDetailsPage .ListFooterDiscountWrapper {
  display: flex;
  justify-content: space-between;
  font-size: 0.8125rem;
  align-items: center;
  padding-bottom: 0.375rem;
}

.MembershipDetailsPage .ListFooterDiscountWrapper .Price {
  font-size: inherit;
}

.MembershipDetailsPage .affiliate-tax {
  margin-top: 1.5rem;
}

.MembershipDetailsPage .RenewalMessage {
  background-color: var(--renewal-message-bg-color);
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 30px;
  line-height: 1.3;
  color: var(--renewal-message-color);
}

.MembershipDetailsPage .successMessage {
  background: #E9FDF2;
  border-radius: 4px;
  line-height: 1.25rem;
  font-size: 14px;
  color: var(--renewal-message-color);
  letter-spacing: 0.25px;
}

.MembershipDetailsPage .successMessage img {
  margin-top: 2px;
  margin-right: 8px;
}

.activationMessage {
  font-weight: normal;
  padding: 12px;
  border: 1px solid #FF3A44;
  border-radius: 6px;
  margin-top: 15px;
  margin-bottom: 16px;
  font-size: 0.875rem;
  line-height: 1.4;
  color: var(--primary-color);
  background-color: var(--primary-color-ten);
}

.flashMessage {
  min-height: 56px;
  box-sizing: border-box;
  padding: 16px;
  margin-top: 15px;
  margin-bottom: 16px;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.375rem;
  color: #333;
  letter-spacing: 0.5px;
  display: flex;
}

.activationErrorMessage {
  line-height: 1.375rem;
  background-color: var(--primary-color-ten);
}

.UM_ActivationErrorMessage {
  line-height: 1.375rem;
  background-color: #FFF0F0;
}

.activationErrorMessage img {
  margin-top: 2px;
  margin-right: 19px;
}

.MembershipDetailsPage .RenewalErrorMessage {
  background-color: #FFF0F0;
  font-weight: 400;
  margin-top: 11px;
  font-size: 0.875rem;
  color: #333333;
  letter-spacing: 0.25px;
  line-height: 1.375rem;
}

.expiredErrorMessage span .change-plan-status-button {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.MembershipDetailsPage .RenewalErrorMessage .Icon {
  display: flex;
  align-items: center;
}

.MembershipDetailsPage .RenewalErrorMessage img {
  margin-top: 2px;
  margin-right: 17px;
  margin-left: 7px;
}

.MembershipDetailsPage .change-plan-status-button {
  background-color: transparent;
  padding: 0;
  color: var(--primary-color);
  border: none;
  cursor: pointer;
  pointer-events: visible;
  font-size: 1rem;
  border-bottom: 1px solid var(--primary-color);
  font-weight: 700;
  letter-spacing: 0.25px;
  line-height: 1.375rem;
}

@media only screen and (max-width: 670px) {
  .MembershipDetailsPage .RenewalErrorMessage span {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }

  .SummaryTable .SummaryTableTitle {
    display: none;
  }

  .MembershipDetailsPage .Container .Card .Card__Header span {
    font-weight: 700;
    line-height: 1.375rem;
    letter-spacing: 0.5px
  }

  .SummaryTable__Th__Tax {
    padding-top: 22px;
  }

  .SummaryTable__Th {
    line-height: 1.375rem;
  }

  .MembershipDetailsPage .change-plan-status-button {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }

  .activationErrorMessage {
    letter-spacing: 0.25px;
    line-height: 1.25rem;
  }

}

.Card__Body__Color {
  color: #001952;
  font-weight: 400;
  line-height: 1.375rem;
  letter-spacing: 0.5px;
}

.MembershipDetailsPage .DashboardHeader-v2 {
  margin-bottom: 2.25rem;
}

.MembershipDetailsPage .List .List__Item {
  padding: 1rem 1.25rem;
}

.ReactivationBodyData {
  max-width: 100%;
  background-color: #DAF1E6;
  display: flex;
  flex-direction: column;
  color: #333333;
  padding: 10px 15px 17px;
  line-height: 1.3rem;
}

.UM_BodyData {
  background-color: #DAF1E6;
}

.ReactivationBodyData .heading {
  font-weight: 700;
  font-size: 14px;
  line-height: 1.25rem;
  letter-spacing: 0.25px;
  color: #000000;
}

.ReactivationBodyData .description {
  font-size: 14px;
  line-height: 1.25rem;
  letter-spacing: 0.25px;
  margin-bottom: 8px;
  font-weight: 400;
}

.ReactivationBodyData .descriptionFontSize {
  font-size: 0.875rem;
  line-height: 1.25rem;
  letter-spacing: 0.25px;
  font-weight: 400;
}

.ReactivationTermsSection .terms-checkbox-row-section {
  display: flex;
  align-items: center;
  margin-top: 1rem;
}

.ReactivationTermsSection .component__Checkbox {
  align-self: flex-start;
}

.Reactivation-terms-section .component__Checkbox .checkbox__IconContainer {
  height: auto;
  width: auto;
  border: 1.5px solid var(--primary-color);
  font-size: 0.5625rem;
}

.ReactivationTermsSection .monthlyCommitmentTermsChecked {
  margin-top: 0;
}

.ReactivationTermsSection .terms-checkbox-row-section p {
  line-height: 1.125rem;
  margin-bottom: 9px;
}


.ReactivationTermsSection .monthlyCommitmentTermsChecked p {
  margin-bottom: 0px;
}

.ReactivationTermsSection .component__Checkbox {
  width: unset;
}

.ReactivationTermsText .component__text {
  color: #000000;
  font-size: 0.75rem;
  line-height: 1.125rem;
  letter-spacing: 0.4px;
  margin-left: 14px;
  font-weight: 400;
}

.ReactivationTermsText .textComponent {
  color: #000000;
  font-size: 0.75rem;
  line-height: 1.125rem;
  letter-spacing: 0.4px;
  margin-left: 14px;
  font-weight: 400;
}

.ReactivationTermsText .component__text .button-link {
  font-size: 0.75rem;
  font-weight: 700;
  letter-spacing: 0.4px;
  line-height: 1.125rem;
}

.ReactivationTermsText .button-link {
  text-decoration: underline;
  color: var(--primary-color);
  font-size: 0.875rem;
}

.button-link:focus {
  outline-color: var(--outline-color);
}

.ReactivationTermsText .reactivationText {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  display: flex;
  align-items: center;
  letter-spacing: 0.4px;
  color: #000000 !important;
}

.SummaryTable {
  width: 100%;
}

.SummaryTable .footer-total {
  font-weight: 400;
}

.SummaryTable__Th {
  font-weight: 400;
  text-align: left;
  line-height: 1.375rem;
  letter-spacing: 0.5px;
}

.SummaryTable__Td {
  text-align: right;
  font-weight: 400;
  line-height: 1.375rem;
  letter-spacing: 0.5px;
}

.updateCCPetNames {
  height: 10.5rem;
  width: auto;
  overflow: scroll;
}

@media only screen and (max-width: 670px) {
  .ReactivationBodyData {
    padding: 10px 22px 17px 11px;
  }

  .ReactivationTermsSection .terms-checkbox-row-section {
    margin-top: 8px;
  }

  .updateCCPetNames {
    height: 7rem;
  }

  .ReactivationBodyData .heading {
    font-size: 0.875rem;
  }
}

.displayUpdateCCPetNames {
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  font-size: 1.125rem;
  /* gap: 1rem; */
}

.SummaryTable .SummaryTableTitle {
  display: none;
}

.SummaryTable__Th__Tax {
  padding-top: 22px;
}
