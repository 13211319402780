.NewPetForm .input-container {
  position: relative;
  background-color: #fff;
  width: 100%;
  margin-bottom: 20px;
}

.NewPetForm .input-container input {
  background-color: #fff;
}

.NewPetForm .input-container input::placeholder {
  overflow: visible;
}

.NewPetForm .input-container label {
  position: absolute;
  z-index: 1;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.125rem;
  letter-spacing: 0.4px;
  pointer-events: none;
  color: #656565;
  left: 1rem;
  top: 0.25rem;
}

.NewPetForm .input-container .ComboBoxWrapper {
  height: 56px;
  margin-bottom: 23px;
}

.NewPetForm .form-field-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.NewPetForm .msg-field-wrapper {
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.4000000059604645px;
  color: #656565;
}

.NewPetForm .input-error {
  position: absolute;
  top: 3.6rem;
  font-size: 0.75rem;
  left: 0.25rem;
}

.NewPetForm {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.NewPetAdditionFormPage {
  margin: 15px;
}

.NewPetForm .Container .top-logo-wrapper {
  padding: 0;
}

.NewPetForm .ComboxBoxWrapper .pet-type {
  height: 100px;
}

.NewPetForm form {
  width: 100%;
}

.NewPetForm .title {
  text-align: center;
  color: #000000;
  padding-top: 0;
  padding-bottom: 0.4375rem;
  margin-top: 1.32rem;
}

.NewPetForm .new-pet-form-logo-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.NewPetForm .react-datepicker-wrapper {
  width: 100%;
  margin-bottom: 5px;
}

/* vvvvvv These need to stay, these are not React Select styles */
.NewPetForm #name:placeholder-shown,
.NewPetForm #date:placeholder-shown {
  padding: 0.3rem 0 0;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.375rem;
  letter-spacing: 0.5px;
}

.NewPetForm #name:not(:placeholder-shown) {
  padding: 1rem 0 0;
}

.NewPetForm #date:not(:placeholder-shown) {
  padding: 1.125rem 0 0;
}
/* ^^^^^^ These need to stay, these are not React Select styles */

.NewPetForm #name,
.NewPetForm #date {
  width: 100%;
  border: 1px solid #656565;
  border-radius: 0;
  height: 56px;
  box-sizing: border-box;
  color: #333333;
  font-size: 1rem;
  line-height: 1.375rem;
  letter-spacing: 0.5px;
}

.NewPetForm #name::placeholder, #date::placeholder {
  color: #656565;
}

.NewPetForm #name::-webkit-input-placeholder, #date::-webkit-input-placeholder {
  color: #656565;
}

.NewPetForm .react-datepicker__input-container {
  box-sizing: content-box;
  height: 56px;
}


.NewPetForm #pet-form-submit-button {
  margin-bottom: 0;
  line-height: 1.375rem;
  letter-spacing: 1px;
}

.NewPetForm #pet-form-submit-button:disabled {
  color: #333333;
  line-height: 1.375rem;
  letter-spacing: 1px;
}

.NewPetForm .field {
  display: flex;
  flex-flow: column-reverse;
  margin-bottom: 1em;
}

.NewPetForm label, input {
  transition: all 0.2s;
  touch-action: manipulation;
}

.NewPetForm input {
  font-size: 1.5em;
  border: 0;
  border-bottom: 1px solid #ccc;
  font-family: inherit;
  -webkit-appearance: none;
  border-radius: 0;
  padding: 0;
  cursor: text;
}

.NewPetForm .InputWrapper {
  height: 56px;
}

.NewPetForm input:focus {
  outline: 0;
  border-bottom: 1px solid #666;
}

.NewPetForm label {
  letter-spacing: 0.05em;
}

.NewPetForm #type {
  height: 56px;
}

.NewPetForm .mobile-select {
  padding-top: 20px;
}

.NewPetForm select {
  height: 56px;
  border: 1px solid #656565;
}

.NewPetForm #breed {
  height: 56px;
}

.NewPetForm .form-field-col {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin: 0;
  flex: 1;
}

@media (min-width: 670px) {
  .NewPetForm .form-field-wrapper {
    flex-direction: row;
    gap: 20px;
    row-gap: 0;
    margin-bottom: 30px;
  }
  
  .form-field-col:nth-child(2) .input-container {
    margin-bottom: -3px;
   }
}

@media screen and (min-width: 670px) and (max-width: 713px){
  .NewPetForm .form-field-wrapper {
    margin-bottom: 30px
  }

  .form-field-col:nth-child(2) .input-container {
    margin-bottom: -20px;
   }
}

.afterSubmitErrorMessage p {
  text-align: center;
  color: #e02924;
}

.registration-container input {
  text-indent: 0.875rem;
}

@media (max-width: 670px) {
  .react-datepicker__input-container>#date {
    text-indent: 0.9375rem;
  }

  .NewPetForm #date:placeholder-shown {
    padding: 0.3rem 0.0625rem 0;
  }

  .NewPetForm #date:not(:placeholder-shown) {
    padding: 1rem 0 0 0.0625rem;
  }
}
.selected-PetType-Group {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  margin-top: -20px;
}

.selected-PetType-Text {
  width: 280px;
  height: auto;
  margin-left: 10px;
  margin-top: 2px;
  font-weight: 400;
  font-size: 0.813rem;
  line-height: 1.125rem;
  letter-spacing: 0.4px;
  color: #001952;
}

.react-datepicker__month-select {
  height: 2.4rem !important;
}

.react-datepicker__year-select {
  height: 2.4rem !important;
}
