.react-date-picker-dynamic-label {
    position: relative;
    height: 56px;
   }

.default-date-style {
    color: black;
}

.react-date-picker-dynamic-label .empty-field {
    padding: 0 !important;
}

.react-date-picker-dynamic-label .enhanced-date {
    border-radius: 0;
    padding: 16px 0 0 0;
    color: var(--primary-color-eleven);
    height: 40px;
}

.react-date-picker-dynamic-label label {
    position: absolute;
    z-index: 1;
    margin: 0;
    top: 5px;
    left: 16px;
    font-size: 12px;
    font-weight: 400;
    color: var(--primary-color-eleven) !important;
    line-height: 1.125rem;
    letter-spacing: 0.4px;
}

.react-datepicker-wrapper {
    width: 100%;
}

.react-datepicker-wrapper {
    /* need to add to v3 only */
    height: 56px;
}

.react-datepicker__input-container {
    height: 56px;
}

.react-datepicker__month-select {
  height: 2.5rem !important;
  border: 0.5px solid #656565;
}

.react-datepicker__year-select {
  height: 2.5rem !important;
  border: 0.5px solid #656565;
}
