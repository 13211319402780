.ComingSoonWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 600px;
}

.ComingSoonWrapper div {
    width: 300px;
    max-width: 70%;
}

.ComingSoonWrapper div img {
    width: 100%;
}