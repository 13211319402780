.customModal.UpdatePlansCCModal .modal-dialog {
    width: calc(100% - 9px);
    max-width: 590px;
    padding: 52px 52px 39px 52px;
}

.UpdatePlansCCModal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    line-height: 2rem;
    align-items: center;
}

.UpdatePlansCCTitle.modal-title.h4 {
    text-align: center;
    font-size: 1.125rem;
    width: 383px;
    min-height: 72px;
    line-height: 1.5rem;
    letter-spacing: 0.15px;
    margin-bottom: 0px;
    font-weight: 700;
}

.UpdatePlansCCTitle.modal-title.h4 {
    color: #333333;
}

.UpdatePlansCCModal .CloseButton {
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
    color: var(--primary-color);
    cursor: pointer;
}

.UpdatePlansCCModal .CloseButton span .UpdatePlansCCCloseIcon img {
    height: 18px;
}

.secondary {
    background-color: transparent;
}

.UpdatePlansCCModal .modal-footer .Button {
    width: 100%;
    min-width: 361px;
    height: 56px;
    align-items: flex-end;
    cursor: pointer;
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.375rem;
    letter-spacing: 1px;
}

.UpdatePlansCCModal .modal-footer .Button:first-child {
    margin: 0 auto;
}

.TermsSection .component__Checkbox {
    width: 24px;
    height: 24px;
    margin-right: 20px;
}

.UpdatePlansCC-terms-section .component__Checkbox .checkbox__IconContainer img {
    width: 14px;
    height: 14px;
}

/* Mobile responsive style below */
@media only screen and (max-width: 670px) {
    .customModal.UpdatePlansCCModal .modal-dialog {
        padding: 41px 14px 19px;
        width: 348px;
        top: 5rem;
        overflow: hidden;
    }

    .UpdatePlansCCTitle.modal-title {
        margin-bottom: 0px;
    }

    .customModal.UpdatePlansCCModal .successMessage {
        background: #E9FDF2;
        border-radius: 4px;
        line-height: 1.25rem;
        font-size: 0.875rem;
        color: var(--renewal-message-color);
        width: 320px;
        margin-bottom: 12px;
    }

    .UpdatePlansCCTitle.modal-title.h4 {
        font-size: 0.875rem;
        line-height: 1.25rem;
        width: 258px;
        min-height: 52px;
        letter-spacing: 0.25px;
    }

    .UpdatePlansCCModal .CloseButton {
        top: 1.125rem;
        right: 1.125rem;
    }

    .UpdatePlansCCModal .modal-footer .Button {
        width: 100%;
        min-width: 320px;
        height: 56px;
        align-items: flex-end;
        cursor: pointer;
    }

    .UpdatePlansCCBodyData {
        padding: 12px 10px;
    }

    .UpdatePlansCCBodyData .heading {
        font-size: 0.875rem;
    }

    .UpdatePlansCCBodyData .description {
        font-size: 0.875rem;
    }

    .TermsSection .terms-checkbox-row-section {
        font-size: 0.75rem;
        margin-top: 0.625rem;
    }

    .modal-dialog .modal-footer {
        margin-top: 22px;
    }

    .UpdatePlansCCModal .modal-footer .Button:last-child {
        margin-top: 3px;
    }

    .UpdatePlansCCModal .flashMessage {
        min-height: 51px;
        box-sizing: border-box;
        padding: 16px;
        /* margin-top: 15px; */
        margin-bottom: 12px;
        font-size: 1rem;
        font-weight: 400;
        line-height: 22px;
        color: var(--primary-color);
        letter-spacing: 0.5px;
        display: flex;
    }

    .UpdatePlansCCModal .modal-footer {
        margin-top: 0;
    }

    .UpdatePlansCCModal .updateCCPetNames {
        gap: 32px;
    }

    .UpdatePlansCCModal .displayUpdateCCPetNames {
        margin-left: 0px !important;
        letter-spacing: 0.5px !important;
    }
}

.UpdatePlansCCModal .successMessage {
    background: #E9FDF2;
    border-radius: 4px;
    font-size: 0.875rem;
    color: var(--renewal-message-color);
    width: 486px;
    min-height: 51px;
    margin-top: 0;
    margin-bottom: 17px;
    letter-spacing: 0.25px;
    line-height: 1.25rem;
    font-weight: 400;
}

.UpdatePlansCCModal .UM_successMessage {
    background: #DAF1E6;
}

.UpdatePlansCCModal .successMessage img {
    margin-top: 2px;
    margin-right: 8px;
}

.flashMessage {
    min-height: 51px;
    box-sizing: border-box;
    padding: 16px;
    margin-top: 15px;
    margin-bottom: 16px;
    font-size: 1rem;
    font-weight: 400;
    line-height: 22px;
    color: var(--primary-color);
    letter-spacing: 0.5px;
    display: flex;
}

.UpdatePlansCCModal .modal-body {
    margin-right: 25px;
}

.UpdatePlansCCModal.modal-body.UpdateCCPetNames {
    font-size: 1.125rem;
}

.UpdatePlansCCModal.modal-body.UpdateCCPetNames.displayUpdateCCPetNames.UpdatePlansCC-icon-bird {
    width: 18.99px;
    height: 33.01px;
}

.UpdatePlansCCModal.modal-body.UpdateCCPetNames.displayUpdateCCPetNames.component__Checkbox span.checkbox__IconContainer {
    max-width: 24px;
    max-height: 24px;
}

/* ------------------UI enhancements as per figma ----------- */

.updateCCPetNames {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.UpdatePlansCCModal .displayUpdateCCPetNames {
    gap: 10px;
    height: 24px;
    line-height: 2.25rem;
    letter-spacing: 0.15px;
    font-weight: 700;
}

.UpdatePlansCCModal .component__Checkbox {
    margin-bottom: 0;
}

.UpdatePlansCCModal .Icon.updatePlansCC-icon-bird img {
    margin-top: 0.275rem;
    margin-left: 7px;
    margin-right: 6px;
    width: 17.27px;
    height: 30px;
}

.UpdatePlansCCModal .Icon.updatePlansCC-icon-cat img {
    margin-top: 0.3rem;
}

.UpdatePlansCCModal .Icon.updatePlansCC-icon-small-animal img {
    margin-top: 0.1rem;
    margin-right: 2px;
    width: 28px;
    height: 29.05px;
}

.UpdatePlansCCModal .Icon.updatePlansCC-icon-dog img {
    margin-top: 0.5rem;
    width: 30px;
    height: 21.32px;
}

.UpdatePlansCCModal .Icon.updatePlansCC-icon-fish img {
    margin-top: 0.4rem;
    width: 30px;
    height: 23.78px;
}

.UpdatePlansCCModal .Icon.updatePlansCC-icon-reptile img {
    margin-top: 0.2rem;
    width: 30px;
    height: 30.85px;
}

.UpdatePlansCCModal .component__Checkbox .checkbox__IconContainer {
    width: 24px;
    height: 24px;
    /* margin: -4px 0 0; */
    box-sizing: border-box;
    margin-left: 6px;
    margin-top: 4px;
}

.UpdatePlansCCModal .component__Checkbox .checkbox__IconContainer img {
    max-width: 16.18px;
    max-height: 15.77px;
}

.UpdatePlansCCModal .modal-footer {
    margin-top: 37px;
}