.Message {
    display: block;
    margin: 0.325rem 0;
    font-size: 0.75rem;
    color: red;
}

@media (max-width: 560px) { 
    .Message {
        margin: 0.75rem 0;
    }
}