#info-modal {
  padding: 0.9375rem;
}

#info-modal .modal-header {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
}

#info-modal .modal-title {
  margin-bottom: 0;
  font-size: 1.3rem;
  font-weight: bold;
}

#info-modal .modal-body {
  margin: 1rem;
}

#info-modal .includes-header {
  margin-bottom: 1rem;
  font-weight: lighter;
}

#info-modal .includes-details {
  font-weight: normal;
  color: gray;
  font-size: 0.85rem;
}

#info-modal .info-close-button {
  padding: 0.2rem;
  margin-left: 3rem;
  color: #0A598F;
  font-weight: bolder;
  font-size: 2rem;
  cursor: pointer;
}
