.BackToButton {
    width: 100%;
    display: flex;
    line-height: 1;
    margin-bottom: 15px;
    cursor: pointer;
    color: var(--primary-color);
}

.BackToButton .Icon {
  height: 16px;
}

.BackToButton  h4 {
  font-size: 18px;
  line-height: 1;
  margin-left: 5px;
}
