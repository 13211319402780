.ReimbursementWarningModalBody {
    position: relative;
}

.ReimbursementWarningModalBody .Icon {
    position: absolute;
    cursor: pointer;
    right: 0;
}

.ReimbursementWarningModalBody .title {
    color: #000000;
    font-size: 1.25rem;
    text-align: center;
    padding: 1.5rem 0;
}

.ReimbursementWarningModalBody .component__text {
    color: #333333;
    font-size: 0.9375rem;
    font-weight: 400;
    text-align: center;
}

.ReimbursementWarningModalBody .ContactCustomer {
    margin: 1.4rem 0;
}

.ReimbursementWarningModalBody .ContactCustomer .component__text {
    margin: 0;
}

.ReimbursementWarningModalBody .ContactCustomer .ContactCustomer__Phone {
    color: var(--primary-color);
    font-weight: bold;
    text-decoration: underline;
}

.ReimbursementWarningModalBody .ContactCustomer .ContactCustomer__Reference {
    width: 14rem;
    padding: 1rem;
    margin: 1.4rem auto 0;
    font-size: 1rem;
    font-weight: 700;
    letter-spacing: 1.2px;
    text-align: center;
    color: #333333;
    background: #E5E5E5;
    border-radius: 6px;
}