.RedeemBenefitsPage-v2 .DashboardHeader-v2,
.RedeemBenefitsPage-v2 .Container {
    width: 63.5rem;
    max-width: 63.5rem;
    margin: 0 auto;
    padding-left: 0;
    padding-right: 0;
}

.RedeemBenefitsPage-v2 .DashboardHeader-v2 {
    margin-bottom: 18px;
}

.RedeemBenefitsPage__CardsWrapper-v2 {
    display: flex;
    justify-content: start;
    align-items: stretch;
    flex-wrap: wrap;
    gap: 15px;
}

.WelcomeGiftAndRoverInRow {
    display: flex;
}

.WelcomeGiftAndRoverInRow .RedeemBenefitsPage__CardsWrapper-v2 {
    margin-top: 15px;
    margin-right: 15px;
    justify-content: start;
    align-items: stretch;
    flex-wrap: wrap;
    gap: 15px;
}

@media (min-width: 670px) and (max-width: 1024px) {

    .RedeemBenefitsPage-v2 .DashboardHeader-v2,
    .RedeemBenefitsPage-v2 .Container {
        width: 41rem;
        max-width: 41rem;
    }
}

@media (max-width: 750px) {
    .RedeemBenefitsPage-v2 {
        padding: 0 1rem;
    }

    .RedeemBenefitsPage-v2 .DashboardHeader-v2,
    .RedeemBenefitsPage-v2 .Container {
        width: 100%;
        max-width: 41rem;
    }

    .RedeemBenefitsPage__CardsWrapper-v2 {
        flex-direction: column;
    }
}

@media (max-width: 460px) {
    .RedeemBenefitsPage__CardsWrapper-v2 {
        flex-direction: column;
        align-items: stretch;
    }
}

.RedeemBenefitsPage-v2 .Container.containerBody>h5.bold-font {
    font-size: 1.125rem;
    margin-bottom: 15px;
    font-weight: 700;
    line-height: 1.5rem;
    letter-spacing: 0.15px;
}

.RedeemBenefitsPage-v2 .PetListGridStyled {
    margin-bottom: 1rem;
}

.RedeemBenefitsPage-v2 .PetList__Name h4 {
    text-align: center;
}

.RedeemBenefitsPage-v2 .PetList__Item {
    margin: 0;
}

.RedeemBenefitsPage-v2 .RedeemBenefitsPage__Header-v2 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    flex-grow: 1;
    text-align: center;
}

.RedeemBenefitsPage-v2 .Container.containerBody>h1:first-of-type {
    font-size: 1.735rem;
    line-height: 1.625rem;
}

.RedeemBenefitsPage-v2 .Container.containerBody .titleHeader {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
    margin-top: 1rem;
}

.RedeemBenefitsPage-v2 .Container.containerBody .backButton {
    cursor: pointer;
    padding-bottom: 0.5rem;
}

.RedeemBenefitsPage-v2 .Container.containerBody .title.topTitle {
    margin: 0;
    padding: 0;
}

.RedeemBenefitsPage-v2 .Container.containerBody .title {
    font-size: 0.9375rem;
    padding-bottom: 0;
    color: var(--black-font);
}

.RedeemBenefitsPage-v2 .Container.containerBody small {
    font-size: .75rem;
    display: block;
    padding-bottom: 1rem;
}

.RedeemBenefitsPage-v2 .Container.containerBody .section:not(:last-of-type) {
    margin-bottom: 2rem;
}

.RedeemBenefitsPage-v2 .Container.containerBody .section .sectionHeader {
    margin-bottom: 1rem;
}

.RedeemBenefitsPage-v2 .Container.containerBody .section .title {
    margin-bottom: 0.25rem;
    line-height: 1.2;
}

.RedeemBenefitsPage-v2 .Container.containerBody .section .Icon {
    min-width: 2.5rem;
    display: flex;
    justify-content: center;
}

.RedeemBenefitsPage-v2 .Container.containerBody .section .Icon.reimbursementBenefits .sectionHeader {
    display: flex;
    justify-content: space-between;
}

.RedeemBenefitsPage-v2 .Container.containerBody .section .Icon.reimbursementBenefits .sectionHeader .component__button {
    max-width: 15rem;
}

.RedeemBenefitsPage-v2 .Container.containerBody .section .SubmitButton--Desktop {
    font-family: var(--primary-font);
    text-transform: uppercase;
    letter-spacing: 1px;
}

@media (min-width: 792px) {
    .RedeemBenefitsPage-v2 .Container.containerBody .section .SubmitButton--Desktop {
        display: inline-block;
    }

    .RedeemBenefitsPage-v2 .Container.containerBody .section .SubmitButton--Mobile {
        display: none;
    }
}

.RedeemBenefitsPage-v2 .Container.containerBody .section.reimbursementBenefits .RedeemBenefitsList__BenefitsList,
.RedeemBenefitsPage-v2 .Container.containerBody .section.yourOtherBenefits .RedeemBenefitsList__BenefitsList {
    margin-bottom: 0;
}

@media (min-width: 792px) {

    .RedeemBenefitsPage-v2 .Container.containerBody .section.reimbursementBenefits .RedeemBenefitsList__BenefitsList,
    .RedeemBenefitsPage-v2 .Container.containerBody .section.yourOtherBenefits .RedeemBenefitsList__BenefitsList {
        grid-template-columns: repeat(2, 1fr);
    }
}

.RedeemBenefitsPage-v2 .Container.containerBody .section .Card .title {
    font-size: 0.875rem;
    line-height: 1.2;
    margin-bottom: 0.25rem;
}

.RedeemBenefitsPage-v2 .Container.containerBody .Card {
    box-shadow: 0 0 1.125rem rgba(0, 0, 0, .1);
    width: 285px;
    /* height: auto; commented this line for VLT-2073 */
    min-height: 240px;
    display: inline-flex;
    justify-content: space-between;
    margin: 0;
    padding: 22px 24px;
}

.RedeemBenefitsPage-v2 .Container.containerBody .Card:last-child {
    margin-right: 0;
}

.RedeemBenefitsPage-v2 .Container.containerBody .Card .Button-v2 {
    margin: 0 auto;
    font-weight: 700;
    line-height: 1.375rem;
    letter-spacing: 1px;
}

.RedeemBenefitsPage__CardsWrapper-v2 .Card {
    padding: 20px 16px;
    width: 320px;
    min-height: 182px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.Card-Height {
    height: 210px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.RedeemBenefitsPage__ShowBarcodeButtonWrapper .component__text {
    color: var(--black-font);
}

.redeemable,
.RedeemBenefitsPage__ShowBarcodeButtonWrapper .exhausted {
    color: var(--black-font);
}

.above-button-description {
    font-size: 1rem;
    line-height: 1.375rem;
    display: flex;
    text-align: center;
    flex-grow: 1;
    font-weight: 400;
    letter-spacing: 0.5px;
    margin: 10px 0;
    color: var(--black-font);
}

.RedeemBenefitsPage-v2 .RedeemBenefitsPage__Info-v2 .exhausted h4 {
    color: gray;
}

.RedeemBenefitsPage-v2 .RedeemBenefitsPage__Header-v2 .exhausted.benefit-title {
    margin-top: 15px;
}

.RedeemBenefitsPage-v2 .RedeemBenefitsPage__Header-v2 .redeemable.benefit-title,
.RedeemBenefitsPage-v2 .RedeemBenefitsPage__Header-v2 .middle.benefit-title,
.RedeemBenefitsPage-v2 .RedeemBenefitsPage__Header-v2 .exhausted.benefit-title {
    letter-spacing: 0;
    font-size: 1.25rem;
    font-weight: 700;
    text-align: center;
    min-height: 48px;
    line-height: 1.5rem;
}

.RedeemBenefitsPage-v2 .RedeemBenefitsPage__ShowBarcodeButtonWrapper .component__text,
.RedeemBenefitsPage-v2 .RedeemBenefitsPage__ShowBarcodeButtonWrapper .exhausted,
.RedeemBenefitsPage-v2 .RedeemBenefitsPage__ShowBarcodeButtonWrapper p {
    font-size: 1rem;
    font-weight: 400;
    text-align: center;
    margin-top: 20px;
    letter-spacing: 0.5px;
    line-height: 22px;
}

.RedeemBenefitsPage-v2 .exhausted-intermediate-state {
    color: #000;
}

.RedeemBenefitsPage-v2 .exhausted {
    color: gray;
}

.RedeemBenefitsPage-v2 .one-time-use {
    background-color: #06FF86;
    color: var(--primary-color);
    border-radius: 6px;
    margin-bottom: 16px;
    font-size: 0.75rem;
    line-height: 1.625rem;
    font-weight: 700;
    height: 25px;
    width: 149px;
    text-align: center;
    letter-spacing: 2px;
}

.RedeemBenefitsPage-v2 .one-time-use.one-time-use-UM {
    background-color: #DAF1E6;
    color: #001952;
}

.RedeemBenefitsPage-v2 .exhausted-badge {
    background-color: #E5E5E5;
    color: var(--primary-color);
    border-radius: 6px;
    font-size: 0.75rem;
    font-family: var(--primary-font-bold);
    line-height: 1.625rem;
    font-weight: bold;
    height: 25px;
    width: 124px;
    text-align: center;
    letter-spacing: 2px;
}

.RedeemBenefitsPage-v2 .RedeemBenefitsPage__ShowBarcodeButtonWrapper {
    width: 100%;
}

.RedeemBenefitsPage-v2 .RedeemBenefitsPage__ShowBarcodeButtonWrapper .SubmitButton--Desktop.SubmitButton--Disabled.component__button {
    background-color: #cccccc;
}

.RedeemBenefitsPage-v2 .RedeemBenefitsPage__ShowBarcodeButtonWrapper .component__button {
    background-color: var(--primary-color);
    box-sizing: border-box;
}

.RedeemBenefitsPage-v2 .Button {
    text-transform: uppercase;
}


.RedeemBenefitsPage-v2 #multiple-target-button {
    margin: 0.625rem 0 0 0;
}


.RedeemBenefitsPage-v2 .RedeemBenefitsPage__ShowBarcodeButtonWrapper .component__button:hover,
.RedeemBenefitsPage__ShowBarcodeButtonWrapper .component__button:focus {
    color: #FFF;
}

.RedeemBenefitsPage-v2 .RedeemBenefitsPage__BenefitWrapper {
    width: 100%;
}

.RedeemBenefitsPage-v2 .RedeemBenefitsPage__Link a,
.RedeemBenefitsPage__Link span {
    display: block;
    width: 100%;
    font-size: 0.75rem;
    text-align: center;
    line-height: 1.125rem;
    font-weight: 400;
    letter-spacing: 0.4px;
}

.RedeemBenefitsPage-v2 .RedeemBenefitsPage__Link .allotmentCount {
    width: 100%;
    margin-top: 0;
    margin-bottom: 0.25rem;
}

.RedeemBenefitsPage-v2 .RedeemBenefitsPage__Link:first-of-type {
    margin-top: 0.9375rem;
}

.RedeemBenefitsPage-v2 .RedeemBenefitsPage__Link a {
    text-decoration: none;
    color: var(--primary-color-two);
}

.RedeemBenefitsPage-v2 .RedeemBenefitsPage__Link span {
    color: #333333;
}

.RedeemBenefitsPage-v2 .RedeemBenefitsList__BenefitsList {
    display: grid;
    margin-bottom: 1rem;
    grid-gap: 1rem;
}

@media (max-width: 540px) {
    .RedeemBenefitsList__BenefitsList {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (min-width: 792px) {
    .RedeemBenefitsList__BenefitsList {
        grid-template-columns: repeat(3, 1fr);
    }
}

.RedeemBenefitsPage-v2 .RedeemBenefitsList__BenefitsList .BenefitsList__Item {
    display: flex;
    align-items: flex-start;
}

.RedeemBenefitsList__BenefitsList .BenefitsList__Item svg path {
    fill: var(--primary-color);
}

.RedeemBenefitsList__BenefitsList .BenefitsList__Item .title,
.RedeemBenefitsList__BenefitsList .BenefitsList__Item .component__text {
    color: var(--primary-color);
}

.RedeemBenefitsList__BenefitsList .BenefitsList__Item .title {
    padding-top: 0;
}

.RedeemBenefitsList__BenefitsList .BenefitsList__Item .title:not(:last-of-type) {
    margin-bottom: 0.5rem;
}

.RedeemBenefitsList__BenefitsList .BenefitsList__Item .benefitTitle {
    display: flex;
    align-items: center;
}

.RedeemBenefitsList__BenefitsList .BenefitsList__Item .benefitTitle .Icon {
    height: 0.9var(--primary-color);
}

.RedeemBenefitsList__BenefitsList .BenefitsList__Item .benefitTitle .title {
    margin-top: 0;
    margin-right: 0.5rem;
    margin-bottom: 0;
}

.RedeemBenefitsPage__BarcodeModalWrapper .modal-body .modal-title {
    margin-top: 24px;
    margin-bottom: 0;
}

.RedeemBenefitsPage__BarcodeModalWrapper .modal-body p {
    margin-bottom: 10px;
}

.RedeemBenefitsPage-v2 .DashboardHeader-v2 {
    box-shadow: 0px 2px 0px rgb(0 0 0 / 10%);
}

.RedeemBenefitsPage__BarcodeModalWrapper .component__button {
    background-color: var(--primary-color);
    color: white;
}

.RedeemBenefitsPage__BarcodeModalWrapper .component__button:last-of-type {
    background-color: transparent;
    color: var(--primary-color);
    border-color: var(--primary-color);
}

.RedeemBenefitsPage-v2 .BarcodeModalWrapper__CustomerInfoV2 p,
.BarcodeModalWrapper__CustomerInfoV2 div span {
    color: var(--primary-color);
}

.RedeemBenefitsPage-v2 .BarcodeModalWrapper__CustomerInfoV2 p {
    text-align: left;
}

.RedeemBenefitsPage-v2 .BarcodeModalWrapper__CustomerInfoV2 div {
    display: flex;
    justify-content: space-between;
    margin-top: 8px;
}

.RedeemBenefitsPage-v2 .BarcodeModalWrapper__CustomerInfoV2 div span {
    max-width: 200px;
    font-weight: bold;
    font-size: 14px;
    text-align: left;
    line-height: 1.2;
}

.RedeemBenefitsPage__BarcodeWrapper {
    margin-top: 0.625rem;
    margin-bottom: 1rem;
}

.RedeemBenefitsPage__BarcodeWrapper svg {
    width: 100%;
    height: auto;
}

.RedeemBenefitsPage__BarcodeWrapper svg g text {
    fill: var(--primary-color);
}

.RedeemBenefitsPage-v2 .UsedBarcodeWrapper {
    height: 2.5rem;
    margin-top: 0.3125rem;
    overflow: hidden;
    position: relative;
}

.RedeemBenefitsPage-v2 .UsedBarcodeWrapper svg {
    opacity: .3;
    width: 100%;
    height: 5rem;
}

.RedeemBenefitsPage-v2 .UsedBarcodeWrapper svg g {
    transform: translate(10px, 0);
}

.RedeemBenefitsPage-v2 .UsedBarcodeWrapper .component__text {
    position: absolute;
    z-index: 1;
    background-color: #FFF;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: var(--primary-color) !important;
    padding: 2px;
}

@media (max-width: 540px) {
    .RedeemBenefitsPage-v2 .Container.containerBody .section .Card .component__button {
        max-width: 18.75rem;
        margin-right: auto;
        margin-left: auto;
    }

    .RedeemBenefitsPage__CardsWrapper-v2 .Card {
        width: 100%;
    }
}

.RedeemBenefitsPage-v2 .RedeemBenefitsPage__Header-v2 .thirdParty_Benefit_Logo {
    width: 162px;
    height: 62px;
    margin-bottom: 10px;
}

.RedeemBenefitsPage-v2 .Container.containerBody .thirdParty-wrapper h5.title {
    font-size: 1.125rem;
    margin-bottom: 30px;
    font-weight: 700;
    letter-spacing: 0.15px;
    line-height: 1.5rem;
}

.RedeemBenefitsPage-v2 .thirdParty-wrapper {
    margin-top: 45px;
}

.RedeemBenefitsPage-v2 .Container.containerBody .thirdParty-wrapper .Card {
    padding: 36px 25px 36px 24px;
}

.RedeemBenefitsPage-v2 .Container.containerBody .thirdParty-wrapper-v2 .Card {
    padding: 16px 25px 26px 24px;
    position: relative;
}

.thirdParty-wrapper-v2 .newWord {
    position: absolute;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.5px;
    color: #FFFFFF;
    background: #007500;
    border-radius: 5px;
    padding: 1px 6px;
    /* width: 45px;
    height: 20px; */
    left: 16px;
}

.thirdParty-wrapper-v2 .RedeemBenefitsPage__CardsWrapper-v2 .Card .RedeemBenefitsPage__Header-v2 h4 {
    font-weight: 700;
    font-size: 21px;
    line-height: 24px;
    text-align: center;
}

.thirdParty-wrapper-v2 .RedeemBenefitsPage-v2 .RedeemBenefitsPage__Header-v2 .redeemable.benefit-title,
.RedeemBenefitsPage-v2 .RedeemBenefitsPage__Header-v2 .middle.benefit-title,
.RedeemBenefitsPage-v2 .RedeemBenefitsPage__Header-v2 .exhausted.benefit-title {
    font-weight: 700;
    font-size: 21px;
    line-height: 24px;
    text-align: center;
}

.showExpiryDate {
    font-weight: 700;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #001952;
    background: #DAF1E6;
    border-radius: 6px;
    padding: 4px 11px 4px 9px;
    margin-top: 13px;
}

.thirdParty-wrapper-v2 .above-button-description {
    margin-top: 12px;
    margin-bottom: 17px;
}

.error.thirdParty-error {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.4px;
    color: #FB4341;
    display: flex;
    text-align: center;
}

.error.thirdParty-error span {
    font-size: 12px;
}

.thirdParty-wrapper-v2 .error.thirdParty-error {
    margin-top: 24px;
}

.error.redeem-benifits-coupon-error {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.4px;
    color: #FB4341;
    display: flex;
    text-align: center;
    margin-top: 10px;
}

.error.redeem-benifits-coupon-error span {
    font-size: 12px;
}


/* responsive style below--------------------------------------------------------- */
@media only screen and (max-width: 540px) {
    .RedeemBenefitsPage-v2 .thirdParty-wrapper {
        border-top: 1px solid #D0D0D0;
        padding-top: 15px;
    }
}

@media only screen and (max-width: 670px) {
    .WelcomeGiftAndRoverInRow {
        display: block;
    }
}

@media only screen and (min-width: 671px) {
    .alignExclusiveMemberTextCenter {
        text-align: center;
    }
}

.RedeemBenefitsPage-v2 .Container.containerBody .Card:not(.no-height-auto) {
    height: auto;
}

/* .RedeemBenefitsPage-v2 .Container.containerBody .Card.cardHeight-RedeemBenefitsCouponErrorMessage {
    height: unset;
} */

/* .RedeemBenefitsPage-v2 .Container.containerBody .Card.card-auto-height {
    height: auto;
} */

.clickHere_benefit {
    color: #001952
}

.clickHere_benefit:visited {
    color: #001952
}