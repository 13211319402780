.Vital-care-tip-page-v2.vital-care-tip {
  display: flex;
  align-items: center;
  height: 54px;
  width: 100%;
  max-width: 547px;
  background-color: #E5E5E5;
  margin: 42px auto;
}

.Vital-care-tip-page-v2.vital-care-tip>* {
  margin-left: 0.875rem;
}

.Vital-care-tip-page-v2.vital-care-tip.vital-care-tip-um {
  background-color: #C8F0FD;
}

.Vital-care-tip-page-v2 .tip {
  color: #000;
  font-size: 0.875rem;
  line-height: 1.25rem;
  letter-spacing: 0.016rem;
}

.Vital-care-tip-page-v2 .tip-um {
  color: #001952;
}

.Vital-care-tip-page-v2 .tip span {
  font-size: 0.875rem;
  font-weight: 700;
}

.Vital-care-tip-page-v2 .icons-wrapper {
  position: relative;
  border-radius: 50%;
  background-color: #06FF86;
  height: 89px;
  min-width: 89px;
}

.Vital-care-tip-page-v2 #icon-dog {
  position: absolute;
  top: 15px;
  left: 18px;
}

.Vital-care-tip-page-v2 #icon-cat {
  position: absolute;
  top: 45px;
  left: 38px;
}

@media only screen and (max-width:670px) {
  .Vital-care-tip-page-v2 .tip {
    line-height: 1.25rem;
    letter-spacing: 0.0053rem;
  }
}