.CompleteYourPurchasePage-v2 {
  height: auto;
  min-height: 900px;
  padding: 16px 0;
  margin-bottom: 16px;
  background-color: #ffffff;
}

.CompleteYourPurchasePage-v2 .button-link {
  text-decoration: underline;
  color: var(--primary-color);
  font-size: 0.75rem;
  line-height: 1.125rem;
}

.CompleteYourPurchasePage-v2 .enter-payment-information .Button-v2 {
  font-weight: 700;
  background-color: var(--yellow);
  color: var(--primary-color);
  text-transform: uppercase;
  line-height: 1.375rem;
  letter-spacing: 0.5px;
}

.CompleteYourPurchasePage-v2 .button-link:focus,
.CompleteYourPurchasePage-v2 .button-link:hover {
  cursor: pointer;
  color: var(--primary-color);
}

.CompleteYourPurchasePage-v2 .CompleteYourPurchasePage__Container-v2 {
  max-width: 47.5rem;
  padding-right: 1rem;
  padding-left: 1rem;
  margin: 0 auto;
}

.CompleteYourPurchasePage-v2 .CompleteYourPurchasePage__Container-v2 .title,
.CompleteYourPurchasePage-v2 .CompleteYourPurchasePage__Container-v2 .component__text {
  color: #333333;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 400;
  letter-spacing: 0.25px;
}

.CompleteYourPurchasePage-v2 .CompleteYourPurchasePage__Container-v2 .component__text.UM {
  font-weight: 700;
}

.CompleteYourPurchasePage-v2 .CompleteYourPurchasePage__Container-v2 .title {
  display: inline-block;
  color: #000000;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.5rem;
  padding: 0;
}

.CompleteYourPurchasePage-v2 .CompleteYourPurchasePage__Container-v2 .need-help-title.title {
  font-size: 1rem;
  line-height: 1.375rem;
}

.CompleteYourPurchasePage-v2 .CompleteYourPurchasePage__Container-v2 .component__text.need-help {
  font-size: 0.813rem;
  line-height: 0.938rem;
}

.CompleteYourPurchasePage-v2 .CompleteYourPurchasePage__Container-v2 .slick-slider {
  overflow: hidden;
  margin-top: 1.25rem;
}

.CompleteYourPurchasePage-v2 .CompleteYourPurchasePage__Container-v2 .slick-track {
  display: flex;
  flex-direction: row;
  margin: 0;
  gap: 10px;
}

.CompleteYourPurchasePage-v2 .CompleteYourPurchasePage__Container-v2 .slick-slide {
  width: 100% !important;
  max-width: 320px;
}

@media only screen and (max-width: 670px) {
  .CompleteYourPurchasePage-v2 .CompleteYourPurchasePage__Container-v2 .slick-slide {
    min-width: 290px;
  }

}

.CompleteYourPurchasePage-v2 .CompleteYourPurchasePage__Container-v2 .slick-dots {
  width: 100%;
  padding: 0;
  margin-top: 16px;
  display: flex !important;
  justify-content: center;
}

.CompleteYourPurchasePage-v2 .CompleteYourPurchasePage__Container-v2 .slick-dots li {
  list-style: none;
}

.CompleteYourPurchasePage-v2 .CompleteYourPurchasePage__Container-v2 .slick-dots li button {
  text-indent: -9999px;
  width: 0.75rem !important;
  height: 0.75rem !important;
  padding: 0;
  margin-right: 0.625rem;
  border: 2px solid #979797;
  border-radius: 50%;
  display: inline-block;
  outline: 0;
  overflow: hidden;
  cursor: pointer;
  border-color: var(--primary-color-four) !important;
  background-color: var(--primary-color-four) !important;
}

.CompleteYourPurchasePage-v2 .CompleteYourPurchasePage__Container-v2 .slick-dots li.slick-active button {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
}

.CompleteYourPurchasePage-v2 .CompleteYourPurchasePage__Container-v2 .slick-dots {
  list-style: none !important;
}

.CompleteYourPurchasePage-v2 .CompleteYourPurchasePage__Container-v2 .slick-dots button {
  width: 10px !important;
  height: 10px !important;
  border-radius: 50% !important;
  border-color: #A1C7F2 !important;
  background-color: #A1C7F2 !important;
  text-indent: -200px;
  overflow: hidden;
}

.CompleteYourPurchasePage-v2 .CompleteYourPurchasePage__Container-v2 .slick-active button {
  background-color: #2A79D6 !important;
  border-color: #2A79D6 !important;
}

.CompleteYourPurchasePage-v2 .CompleteYourPurchasePage__Container-v2 .submit-copy {
  margin: 1.375rem 0 0.75rem;
  font-size: 0.75rem;
  line-height: 1.125rem;
}

.CompleteYourPurchasePage-v2 .CompleteYourPurchasePage__Container-v2 .submit-copy .button-link {
  font-size: inherit;
}

.CompleteYourPurchasePage-v2 .CompleteYourPurchasePage__Container-v2 .submit-info {
  color: var(--primary-color);
  font-size: 0.75rem;
  line-height: 1.125rem;
  font-weight: 400;
}

.CompleteYourPurchasePage-v2 .CompleteYourPurchasePage__Container-v2 .enter-payment-information {
  max-width: 20rem;
  margin: 0 auto;
  text-align: center;
}

.CompleteYourPurchasePage-v2 .CompleteYourPurchasePage__Container-v2 .enter-payment-information p {
  font-size: 0.813rem;
  color: var(--primary-color);
  margin-top: 0.625rem;
  font-weight: 400;
  line-height: 0.938rem;
}

.CompleteYourPurchasePage__Container-v2 .EstimatedTotal__PetInfo-v2 p.priceWeight {
  line-height: 1.375rem;
}

.CompleteYourPurchasePage__Container-v2 .EstimatedTotal__PetInfo-v2 p.Month-priceWeight {
  line-height: 1.25rem;
}

.CompleteYourPurchasePage__Container-v2 .membershipType p.component__text {
  line-height: 1.25rem;
}

.CompleteYourPurchasePage__Container-v2 .StoreLocator-v2 .Button-v2 {
  line-height: 1.375rem;
  font-weight: 700;
  letter-spacing: 1px;
}

.CompleteYourPurchasePage-v2 .CompleteYourPurchasePage__Container-v2 .CreditCardComboBox {
  margin-bottom: 17px;
  max-width: 40rem;
}

.CompleteYourPurchasePage-v2 .CompleteYourPurchasePage__Container-v2 .Step__Header-v2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 0 0.75rem;
  border-top: 0.0625rem solid var(--primary-color-five);
  margin-bottom: -1px;
  border-bottom: 1px solid #CCCCCC;
}

.CompleteYourPurchasePage-v2 .CompleteYourPurchasePage__Container-v2 .Step__Header-v2-ACI {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 0 0.75rem;
  border-top: 0.0625rem solid var(--primary-color-five);
  margin-bottom: -1px;
}

.CompleteYourPurchasePage-v2 .CompleteYourPurchasePage__Container-v2 .Step__Header-v2.no-border {
  border-top: none;
}

.CompleteYourPurchasePage-v2 .CompleteYourPurchasePage__Container-v2 .Step__Header-v2.border-bottom {
  border-bottom: 0.0625rem solid var(--primary-color-five);
}

.CompleteYourPurchasePage-v2 .Step__Header-v2 .title {
  padding-top: 0;
  margin-bottom: 0;
  font-size: 1.25rem;
  flex: 1 0 0;
}

.CompleteYourPurchasePage-v2 .Step__Header-v2 .optional {
  font-size: 0.813rem;
  line-height: 0.938rem;
  color: #656565;
}

.CompleteYourPurchasePage-v2 .Step__Header-v2 .Icon {
  margin-bottom: 0;
}

.CompleteYourPurchasePage-v2 .Step__Header-v2 .Icon svg path {
  fill: var(--primary-color);
}

.CompleteYourPurchasePage-v2 .Step__Header-v2 .Icon[name="review"],
.CompleteYourPurchasePage-v2 .Step__Header-v2 .Icon[name="yourAddress"],
.CompleteYourPurchasePage-v2 .Step__Header-v2 .Icon[name="payment"] {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: var(--secondary-color-two);
}

.CompleteYourPurchasePage-v2 .Step__Header-v2 .Icon[name="checked"] {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: var(--secondary-color);
}

/* role: 'button',
  tabIndex: '0', */

.CompleteYourPurchasePage-v2 .Step__Edit-v2 {
  font-family: var(--primary-font-bold);
  font-size: 0.875rem;
  margin-left: 1rem;
  font-weight: 600;
  color: var(--primary-color);
  border-bottom: 1px solid var(--primary-color);
  letter-spacing: 0.05rem;
  cursor: pointer;
}

.CompleteYourPurchasePage-v2 .Step__Content-v2:nth-of-type(2) .component__button {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}

.CompleteYourPurchasePage-v2 .Step__Content-v2 .address {
  color: black;
}

.CompleteYourPurchasePage-v2 .Step__Content-v2 .Button-v2 {
  text-transform: uppercase;
  height: 56px;
  display: inline-flex;
  align-items: center;
  margin: 0;
  max-width: inherit;
  float: inherit;

}

.CompleteYourPurchasePage-v2 .Step__Content-v2 .component__button.invalid-button {
  background-color: #d4dadc;
  border: 0.0625rem solid #a9b5ba;
  color: #8e9da2;
}

.CompleteYourPurchasePage-v2 .Step__Content-v2 .component__button.active-button {
  background-color: var(--primary-color);
  color: white;
}

.CompleteYourPurchasePage-v2 .Step__Content-v2 fieldset {
  padding: 0;
  margin-top: 0.375rem;
  box-shadow: none;
  background-color: transparent;
}

.CompleteYourPurchasePage-v2 .Step__Content-v2 fieldset small {
  margin-top: 0.5rem;
  font-size: 0.75rem;
  font-weight: 600;
  color: red;
}

.CompleteYourPurchasePage-v2 .Step__Content-v2 .css-1wa3eu0-placeholder {
  color: #333333;
}

.CompleteYourPurchasePage-v2 .Step__Content-v2 input::placeholder {
  color: #333333;
}

.CompleteYourPurchasePage-v2 .Step__Content-v2 input:-ms-input-placeholder {
  color: #333333;
}

.CompleteYourPurchasePage-v2 .Step__Content-v2 input::-ms-input-placeholder {
  color: #333333;
}

.CompleteYourPurchasePage-v2 .Step__Content-v2 label {
  color: var(--primary-color);
}

.CompleteYourPurchasePage-v2 .Step__Content-v2 label:not(:first-of-type) {
  margin-top: 0.75rem;
}

.CompleteYourPurchasePage-v2 .Step__Content-v2 label span {
  color: red;
}

.CompleteYourPurchasePage-v2 .Step__Content-v2 .location {
  margin-top: 0.75rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1rem;
}

.CompleteYourPurchasePage-v2 .Step__Content-v2 .css-yk16xz-control {
  border-color: var(--primary-color-five);
}

.CompleteYourPurchasePage-v2 .CompleteYourPurchasePage__Container-v2 .submit-payment .Button-v2 {
  font-weight: 700;
  border: none;
  line-height: 1.375rem;
  letter-spacing: 1px;
}

.CompleteYourPurchasePage-v2 .CompleteYourPurchasePage__Container-v2 .submit-payment .Button-v2.active-button {
  background-color: var(--yellow);
  color: var(--primary-color);
}

.CompleteYourPurchasePage-v2 .CompleteYourPurchasePage__Container-v2 .special-price {
  color: #333333;
  margin-top: 1.2rem;
  margin-bottom: -0.75rem;
}

.modal-body-paragraph {
  text-align: center !important;
  padding-left: 5px;
  padding-right: 5px;
}

@media (min-width: 930px) {
  .CompleteYourPurchasePage-v2 .CompleteYourPurchasePage__Container-v2 {
    padding-right: 1rem;
    padding-left: 1rem;
  }

  .CompleteYourPurchasePage-v2 .component__button {
    max-width: 18.75rem;
    margin: 0 auto;
  }

  .CompleteYourPurchasePage-v2 .CompleteYourPurchasePage__Container-v2 .enter-payment-information {
    max-width: 100%;
    text-align: left;
    display: flex;
    flex-direction: column-reverse;
  }

  .CompleteYourPurchasePage-v2 .CompleteYourPurchasePage__Container-v2 .enter-payment-information .Button-v2 {
    margin: 1rem 0 3rem;
    align-self: end;
    letter-spacing: 1px;
    padding: 17px 14px;
    max-width: 21.375rem;
  }

  .CompleteYourPurchasePage-v2 .CompleteYourPurchasePage__Container-v2 .enter-payment-divide {
    width: 100%;
    margin: 1rem 0;
    border-top: 0.0625rem solid var(--primary-color-five);
  }

  .CompleteYourPurchasePage-v2 .CompleteYourPurchasePage__Container-v2 .CreditCardComboBox {
    margin: 1rem 0 1rem 0;
  }

  .CompleteYourPurchasePage-v2 .CompleteYourPurchasePage__Container-v2 .submit-payment {
    display: flex;
    flex-direction: column;
  }

  .CompleteYourPurchasePage-v2 .CompleteYourPurchasePage__Container-v2 .submit-payment .Button-v2 {
    align-self: end;
    margin: 1rem 0;
  }

  .CompleteYourPurchasePage-v2 .CompleteYourPurchasePage__Container-v2 .submit-info {
    text-align: end;
  }

  .CompleteYourPurchasePage-v2 .Step__Content-v2 .Button-v2 {
    max-width: 18.75rem;
    float: right;
  }

  .CompleteYourPurchasePage-v2 .CompleteYourPurchasePage__Container-v2 .special-price {
    margin-top: 1rem;
    margin-bottom: -0.90rem;
  }
}

@media only screen and (max-width: 670px) {

  .CompleteYourPurchasePage-v2 .CompleteYourPurchasePage__Container-v2 .title,
  .CompleteYourPurchasePage-v2 .CompleteYourPurchasePage__Container-v2 .component__text {
    line-height: 1.5rem;
  }

  .CompleteYourPurchasePage-v2 .Step__Header-v2 .optional {
    line-height: 0.938rem;
  }

  .CompleteYourPurchasePage-v2 .CompleteYourPurchasePage__Container-v2 .membershipTax .component__text {
    line-height: 1.375rem;
  }

  .CompleteYourPurchasePage-v2 .CompleteYourPurchasePage__Container-v2 .component__text.tell-text {
    line-height: 1.25rem;
  }

  .CompleteYourPurchasePage__Container-v2 .CardReviewList__Row-v2 p.component__text {
    line-height: 1.25rem;
  }

  .CompleteYourPurchasePage__Container-v2 .EstimatedTotal__PetInfo-v2 p.component__text {
    line-height: 1.25rem;
  }

  .CompleteYourPurchasePage__Container-v2 .membershipType p.component__text {
    line-height: 1.25rem;
  }

  .CompleteYourPurchasePage__Container-v2 .PaymentInformation__Item .membershipType p.component__text {
    line-height: 1.375rem;
  }

  .CompleteYourPurchasePage-v2 .CompleteYourPurchasePage__Container-v2 .submit-copy {
    margin-top: 12px;
    margin-bottom: 12px;
    line-height: 1.125rem;
  }

  .CompleteYourPurchasePage-v2 .CompleteYourPurchasePage__Container-v2 .submit-payment .Button-v2.active-button {
    background-color: #001952;
    color: #FFFFFF;
    border-radius: 5px;
  }

  .CompleteYourPurchasePage-v2 .CompleteYourPurchasePage__Container-v2 .submit-info {
    font-size: 0.75rem;
    margin-top: 12px;
  }

  .CompleteYourPurchasePage-v2 .CompleteYourPurchasePage__Container-v2 .enter-payment-information {
    margin: 33px auto 37px auto;
    max-width: 100%;
  }

  .CompleteYourPurchasePage__Container-v2 .EstimatedTotal__PetInfo-v2 p.priceWeight {
    line-height: 1.375rem;
    /*not required*/
  }

  .CompleteYourPurchasePage-v2 .CompleteYourPurchasePage__Container-v2 .enter-payment-information p {
    margin-top: 22px;
  }

  @media only screen and (max-width: 768px) {
    .PetCards-v2 .slide-arrow-left {
      display: none;
    }

    .PetCards-v2 .slide-arrow-right {
      display: none;
    }
  }
}

.expiredErrorMessage {
  font-weight: 400;
  border-radius: 2px;
  margin-top: 11px;
  font-size: 0.875rem;
  color: #333333;
  background-color: #FFF0F0;
  letter-spacing: 0.25px;
  line-height: 1.375rem;
}

.expiredErrorMessage .Icon {
  display: flex;
  align-items: center;
}

.expiredErrorMessage img {
  margin-top: 2px;
  margin-right: 17px;
  margin-left: 7px;
}