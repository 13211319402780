
.Progress {
  width: 100%;
  position: relative;
}

/* TODO: Figure out what the main background color is */
.Progress .ProgressContainer {
  background-color: var(--background-main);
  height: 0.5rem;
  width: 100%;
  border-radius: 0.25rem;
  display: flex;
}

.Progress .ProgressBar {
  height: 100%;
  background-color: var(--secondary-color);
  border-radius: 0.25rem;
  display: flex;
  transition: width 0.2s;
  position: relative;
}

/* span */
/* TODO: Figure out the content placeholder color */
.Progress .ProgressLabel {
  font-size: 0.8125rem;
  font-weight: 700;
  color: var(--content-placeholder);
  margin-top: 0.625rem;
  display: block;
}
