.MembershipFloatingLabelInputContainer {
    margin-top: 10px;
    margin-bottom: 10px;
    position: relative;
    height: 56px;
}

.MembershipFloatingLabelInputContainer .MembershipFloatLabel__label {
    position: absolute;
    z-index: 1;
    font-size: 0.75rem;
    color: var(--primary-color-eleven);
    left: 13px;
    top: 7px;
    width: 265px;
}

.MembershipFloatingLabelInputContainer .MembershipFloatLabel__input {
    padding: 0 0 0 13px;
    position: absolute;
    color: var(--primary-color-eleven);
    font-size: 1rem;
    height: 56px;
    background-color: #fff;
    border: 1px solid var(--primary-color-eleven);
    letter-spacing: 0.5px;
    line-height: 1.375rem;
    font-weight: 400;
}

.MembershipFloatingLabelInputContainer .MembershipFloatLabel__input--filled {
    padding-top: 35px;
    padding-right: 15px;
    padding-bottom: 19px;
    padding-left: 12px;
}

.MembershipFloatingLabelInputContainer .MembershipFloatLabel__input::placeholder {
    color: var(--primary-color-eleven);
}

.MembershipFloatingLabelInputContainer .MembershipFloatLabel__input:-ms-input-placeholder {
    color: var(--primary-color-eleven);
}

.MembershipFloatingLabelInputContainer .MembershipFloatLabel__input::-ms-input-placeholder {
    color: var(--primary-color-eleven);
}

.MembershipFloatingLabelInputContainer input:focus {
    outline-color: unset;
}

.MembershipFloatingLabelInputContainer input:focus-visible {
    outline-offset: unset;
    outline: unset;
}

.MembershipFloatingLabelInputContainer .add-clinic-modal-grid .input-error {
    margin-top: unset !important;
}

.MembershipFloatingLabelInputContainer input:-internal-autofill-selected {
    background-color: #fff;
}
