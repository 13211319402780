:root {
    /*** Colors ***/
    /* Primary */
    --primary-color: #001952;
    --primary-color-two: #005891;
    --primary-color-three: #40527D;
    --primary-color-four: #9DABC9;
    --primary-color-five: #D8DFED;
    --primary-color-six: #73EAFF;
    --primary-color-seven: #94D37A;
    --primary-color-eight: #1FB5EC;
    --primary-color-nine: #E5E5E5;
    --primary-color-ten: rgba(255, 58, 68, 0.1);
    --primary-color-eleven: #656565;
    --primary-color-twelve: #A9B5BA;
    
    --primary-color-fourteen: #19204F;
    --primary-color-fifteen: #001954;
    --primary-color-sixteen: #333333;
    --primary-color-seventeen: #011954;

    

    --black-font: rgb(16,16,16);
    
    /* Secondary */
    --secondary-color: #06FF86;
    --secondary-color-two: #E9FDF2;

    /* Outline */
    --outline-color:#001952;

    /* Misc  */
    --orangeWarning: #F4756E;
    --lightBlue: #BDE4F6;
    --lightGreen: #E9FDF2;
    --tertiary: #FB4341;
    /* Logos */
    /* I don't like this restriction of CRA, need to discuss */
    --app-logo: url(assets/petco-vital-care.svg); 
    --steps-icon: url(assets/steps-icon.svg);
    /* Context Specific */
    --auto-renew-color: #8E9DA2;
    --renewal-message-bg-color: #eee;
    --renewal-message-color: #001952;
    --step-progress-bar: #06ff86;
    /* Probably move this one unless it really needs to be overridden */
    --pet-list-wrapper-shadow-color: #EFEFEF;
    /*** Fonts ***/
    --primary-font: PetcoCircularWeb, sans-serif;
    --primary-font-bold: PetcoCircularWeb, sans-serif;
    --header1: 1.5625rem;
    --header2: 19px;
    --header3: 17px;
    --header4: 15px;
    --paragraph: 15px;
    --paragraphSmall: 14px;
    --link: 17px;
    --linkSmall: 15px;
    /*** Buttons ***/
    --button-color: #fff;
    --disabled-button-border: 0rem solid #a9b5ba;
    --disabled-button-color: #5f5f5f;
    --disabled-button-bg-color: #b8b8b8;
    /* This is for Lists... Need to figure out better names  */
    --list-header-color: #2A79D6;
    --list-history-footer: #00649D;

    --placeholder: #8E9DA2;

    /* PetCo Wellness */
    --green: #46C126;
    --greenLight1: #EBF8E8;
    --greenLight2: #B4E6A7;
    --orange: #F18213;
    --orangeLight2: #F8C31E;

    /* PetCo Insurance */
    --blue: #2A79D6;
    --gradientLight: #42B8EB;
    --gradientDark: #2A79D6;

    /* PetCo Purchase */
    --yellow: #FFD405;
  
    /* Old Theme */
    --greyLight1: #F4F6F6; /* "main" */
    --greyLight2: #E9ECED;
    --greyLight3: #D4D9DC;
    --greyLight4: #C4CDD5;
    --greyLighter1: #f3f0f0;
    --greyLighter2: #cccccc;
    --greyLighter3: #F3F6F6;
  
}

/* Use env() or write media queries specific to :root */
/* desktopMaxWidth: '1440px',
desktopMedWidth: '1280px',
desktopWidth: '1037px',
desktopMinWidth: '992px',
tabletMaxWidth: '790px',
tabletMinWidth: '670px',
mobileMaxWidth: '540px',
mobileMinWidth: '480px'
 */
