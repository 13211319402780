.ClaimsThankYouPage {
    padding: 1rem 0;
}

.ClaimsThankYouPage .Container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 25rem;
}

.ClaimsThankYouPage .Container .Icon {
    align-self: center;
}

.ClaimsThankYouPage .Container .Icon img {
    width: 3.5rem;
}

.ClaimsThankYouPage .title, .ClaimsThankYouPage .component__text {
    margin-bottom: 1.5rem;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    color: var(--primary-color);
}

.ClaimsThankYouPage button.component__button {
    background-color: var(--primary-color);
}