.ClaimsUploadInvoicesPage-v2 {
    height: auto;
    background-color: #ffffff;
    max-width: 20rem;
    margin: 0 auto;
    padding: 1rem 0;
}

.ClaimsUploadInvoicesPage-v2 .logoPetcoVitalCare {
    display: none;
}

.ClaimsUploadInvoicesPage-v2 h2 {
    font-size: 1.565rem;
    text-align: left;
    color: var(--primary-color);
    font-weight: 700;
    letter-spacing: 0.25px;
    line-height: 2rem;
}

.ClaimsUploadInvoicesPage-v2 .FileImageWrapper {
    min-height: 160px;
    min-width: 160px;
}

.ClaimsUploadInvoicesPage-v2 .FileImage__IconFileWrapper {
    max-height: 160px;
    min-width: 160px;
    padding: 1rem 0 1.5rem;
}

.ClaimsUploadInvoicesPage-v2 .FileImage__IconFileWrapper .inner-wrapper {
    padding: 0;
}

.ClaimsUploadInvoicesPage-v2 .title {
    display: flex;
    justify-content: left;
}

.ClaimsUploadInvoicesPage-v2 .title:nth-of-type(2) {
    margin-bottom: 0.625rem;
}

.ClaimsUploadInvoicesPage-v2 .FileDialogInput {
    max-width: unset;
}

.ClaimsUploadInvoicesPage-v2 .claim_p {
    font-size: 1rem;
    margin: 0.5rem 0 1rem;
    font-weight: 400;
    line-height: 1.25rem;
    letter-spacing: 0.4px;
}

.ClaimsUploadInvoicesPage-v2 .claim_p.claim_p--info {
    font-size: 0.75rem;
    color: #656565;
    margin-top: 1rem;
    line-height: 1.125rem;
}

.ClaimsUploadInvoicesPage-v2 #remove-button {
    margin: 1rem 0 0;
    background-color: transparent;
    color: var(--primary-color);
    cursor: pointer;
    font-weight: 700;
    text-decoration: underline;
    font-size: 1rem;
    font-weight: 700;
    padding: 0;
    line-height: 1.375rem;
    letter-spacing: 0.5px;
}

.ClaimsUploadInvoicesPage-v2 .component__button:first-of-type {
    margin-top: 2rem;
    margin-right: auto;
    margin-left: auto;
    border: 1.5px solid #0A598F;
}

.ClaimsUploadInvoicesPage-v2 .component__button span {
    color: #0A598F;
}

.ClaimsUploadInvoicesPage-v2 #upload-another {
    font-weight: 700;
    text-transform: uppercase;
    background-color: transparent;
    margin-right: unset;
    margin-left: unset;
    max-width: unset;
    line-height: 1.375rem;
    letter-spacing: 1px;
}

.ClaimsUploadInvoicesPage-v2 .button-continue {
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 1px;
    line-height: 1.375rem;
}

.ClaimsUploadInvoicesPage-v2 .comment-text-area {
    resize: none;
}

.ClaimsUploadInvoicesPage-v2 .component__button.invalid-button {
    background-color: #d4dadc;
    border: 0.0625rem solid #a9b5ba;
    color: #8e9da2;
}

.ClaimsUploadInvoicesPage-v2 .component__button.invalid-button span {
    color: #8e9da2;
}

.ClaimsUploadInvoicesPage-v2 .component__button.button-active {
    background-color: var(--primary-color);
    color: white;
}

.ClaimsUploadInvoicesPage-v2 .component__button.button-active span {
    color: #FFF;
}

.ClaimsUploadInvoicesPage-v2 #message-field {
    color:#C83D5A;
    display: flex;
    justify-content: center;
    margin-top: 0.9375rem;
}

.ClaimsUploadInvoicesPage-v2 #loading-message {
    display: flex;
    justify-content: center;
    margin-top: 0.9375rem;
}

.FileImageGallery {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.FileImageGallery .inner-wrapper {
    padding: 0.5rem;
}

.FileImageGallery .component__text {
    text-align: center;
    font-size: 0.75rem;
    line-height: 1.1;
    word-break: break-all;
}

@media (min-width: 670px) {
    .ClaimsUploadInvoicesPage-v2 .logoPetcoVitalCare {
        margin: 0 auto;
        padding: 0;
        display: block;
    }

    .ClaimsUploadInvoicesPage-v2 h2 {
        text-align: center;
    }

    .ClaimsUploadInvoicesPage-v2 {
        max-width: 70rem;
    }

    .ClaimsUploadInvoicesPage-v2 .Container {
        max-width: 620px;
    }

    .ClaimsUploadInvoicesPage-v2 .PetcoLogoSharedComponent-v2 {
        margin-top: -40px;
    }

    .ClaimsUploadInvoicesPage-v2 .component_button {
        margin-right: unset;
        margin-left: unset;
        justify-self: start;
    }

    .ClaimsUploadInvoicesPage-v2 .FileDialogInput.Justify {
        justify-self: end;
    }

    .ClaimsUploadInvoicesPage-v2 .title {
        justify-content: center;
    }

    .ClaimsUploadInvoicesPage-v2 .claim_p {
        text-align: center;
        margin: 0.5rem 0 1.25rem;
    }

    .ClaimsUploadInvoicesPage-v2 #upload-another {
        max-width: 365px;
        margin: 0 auto;
    }
}