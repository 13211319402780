.ClaimsDatePage-v2 {
    height: auto;
    background-color: #ffffff;
    max-width: 21.25rem;
    margin: 0 auto;
    padding: 1rem 0;
}

.ClaimsDatePage-v2 .PetcoLogoSharedComponent-v2 {
    margin-top: -40px;
    margin-bottom: 1rem;
}

.ClaimsDatePage-v2 h2 {
    text-align: left;
    color: var(--primary-color);
}

.ClaimsDatePage-v2 .registration-container input {
    line-height: 1.375rem;
    letter-spacing: 0.5px;
    font-weight: 400;
}

@media (max-width: 760px) {
    .ClaimsDatePage-v2 .PetcoLogoSharedComponent-v2 {
        display: none;
    }
}

@media (min-width: 670px) {
    .ClaimsDatePage-v2 h2 {
        text-align: center;
    }
}

@media (min-width: 670px) {
    .ClaimsDatePage-v2 {
        max-width: 70rem;
    }
}

@media (min-width: 670px) {
    .ClaimsDatePage-v2 .BackTo {
        margin-left: 40px;
    }
}

.ClaimsDatePage-v2 fieldset {
    padding: 0;
    margin-top: 0.375rem;
    box-shadow: none;
    background-color: transparent;
}

.ClaimsDatePage-v2 fieldset small {
    margin-top: 0.5rem;
    font-size: 0.75rem;
    font-weight: 600;
    color: red;
}

.ClaimsDatePage-v2 .enhanced-title {
    line-height: 2rem;
    padding-bottom: 19px;
    font-weight: 700;
    font-size: 25px;
    letter-spacing: 0.25px;
}

.ClaimsDatePage-v2 .registration-container-enhanced {
    margin: 0;
    padding: 0;
}

.ClaimsDatePage-v2 fieldset {
    margin: 0;
}

.ClaimsDatePage-v2 .BackTo {
    margin-bottom: 10px;
}

.ClaimsDatePage-v2 .button-enhanced {
    line-height: 30px;
    margin: 0 auto;
    height: 56px;
}

.ClaimsDatePage-v2 .standard-button {
    margin-top: 10px;
    max-width: 340px;
    letter-spacing: 1px;
    line-height: 1.375rem;
    font-weight: 700;
}

.ClaimsDatePage-v2 .registration-container {
    max-width: 340px;
    margin: 0 auto;
}
