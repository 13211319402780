.toggle-switch-page-v2 {
    display: flex;
    justify-content: center;
}

.toggle-switch-page-v2 .ButtonGroup {
    display: flex;
    background-color: #F8F7F9;
    border-radius: 26px;
    width: 100%;
    border: 2px solid #001952;

}

.toggle-switch-page-v2 .ButtonGroup .Button-v2 {
    margin: 0px;
    border-radius: 24px;
    font-size: 0.75rem;
    display: flex;
    flex-direction: column;
    line-height: 1.125rem;
    letter-spacing: 0.4px;
    padding: 9px 0;
    min-height: 48px;
    color: #FFFFFF;
}

.toggle-switch-page-v2 .ButtonGroup .button-disabled {
    color: #333333;
    background: #F8F7F9;
}

@media only screen and (max-width:670px) {
    .toggle-switch-page-v2 .ButtonGroup {
        justify-content: stretch;
        width: 100%;
    }
}

.toggle-switch-page-v2 .toggle-switch-v1 {
    font-size: 0.75rem;
    font-weight: 700;
    line-height: 1rem;
  }
  
.toggle-switch-page-v2 .toggle-switch-v2 {
    font-size: 0.75rem;
    line-height: 1rem;
    letter-spacing: 0.4px;
    font-weight: 400;
  }
  @media only screen and (max-width:300px) {
    .choose-your-plan-page-v2 .cardList-chooseYourPlan .title{
        margin-left: 8px;
    }
}
@media only screen and (max-width: 670px) {
    .toggle-switch-page-v2 .ButtonGroup .Button-v2 {
        padding: 8px 0;
    }
    .toggle-switch-page-v2 .toggle-switch-v1{
        font-size: 0.875rem;
        letter-spacing: 0.25px;
        line-height: 1.25rem;
    }
    .toggle-switch-page-v2 .toggle-switch-v2 {
        font-size: 0.75rem;
        letter-spacing: 0.4px;
        line-height: 1.125rem;
    }
    .toggle-switch-page-v2 .ButtonGroup .button-disabled {
        color: #656565;
    }
    
}
