.InputWrapper input {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    width: 100%;
    border: 1px solid rgb(212, 218, 220);
    outline: none;
    transition: all 0.2s ease 0s;
    touch-action: manipulation;
    border-radius: 5px;
    font-size: 1rem;
    padding: 0px 0.5rem;
    height: 2.75rem;
    box-sizing: border-box;
}

.input-error {
    font-size: 0.8125rem;
    color: #e02924;
}

.title {
    padding-bottom: 1.25rem;
}

.no-padding {
    padding: 0;
}