.MembershipFloatLabelInput {
    position: relative;
}

.MembershipFloatLabelInput .MembershipFloatLabelInput__label {
    position: absolute;
    z-index: 1;
    font-size: 0.75rem;
    color: #656565;
    left: 1rem;
    top: 0.25rem; 
}

.MembershipFloatLabelInput .MembershipFloatLabelInput__input {
    box-sizing: border-box;
    color: #333333;
    height: 56px;
    padding: 1.75rem 1rem 1.75rem;
    font-size: 1rem;
    border: 1px solid #656565;
    transition: padding 0.3s;
}

.MembershipFloatLabelInput .MembershipFloatLabelInput__input--filled {
    padding: 2.2rem 1rem 1.2rem;
}

.MembershipFloatLabelInput .MembershipFloatLabelInput__input::placeholder {
    color: #656565;
}

.MembershipFloatLabelInput .MembershipFloatLabelInput__input:-ms-input-placeholder {
    color: #656565;
}

.MembershipFloatLabelInput .MembershipFloatLabelInput__input::-ms-input-placeholder {
    color: #656565;
}