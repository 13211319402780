.ClaimsVetSelectionPage {
    padding: 1rem 0;
}

.ClaimsVetSelectionPage .Container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 25rem;
}

.ClaimsVetSelectionPage .Container .Icon {
    align-self: center;
}

.ClaimsVetSelectionPage .title {
    margin-bottom: 0.25rem;
    line-height: 1.4;
    color: var(--primary-color);
}

@media (min-width: 670px) {
    .ClaimsVetSelectionPage .title {
        text-align: center;
    }
}

@media (min-width: 670px) {
    .ClaimsVetSelectionPage .component__text {
        margin-left: auto;
        margin-right: auto;
    }
}

.ClaimsVetSelectionPage .vet-office-wrapper {
    margin-top: 15px;
    margin-bottom: 5px;
}

.ClaimsVetSelectionPage .vet-office-text {
    color: var(--primary-color);
    font-weight: bold;
    font-size: 0.9375rem;
    display: contents;
}

.ClaimsVetSelectionPage .search-form {
    width: 100%;
}

.ClaimsVetSelectionPage .CMEmb {
    width: unset;
    padding-top: 0.8125rem;
    padding-bottom: 0.8125rem;
}

.ClaimsVetSelectionPage label {
    color: var(--primary-color);
}

.ClaimsVetSelectionPage label span {
    margin-right: 4px;
    color: red;
}

.ClaimsVetSelectionPage .vet-clinic-search-input {
    border-radius: 5px;
    font-size: 1rem;
    padding: 0;
    height: 2.75rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}

.ClaimsVetSelectionPage .vet-clinic-search-input::placeholder {
    color: var(--primary-color-two);
}

.ClaimsVetSelectionPage .vet-clinic-search-input:-ms-input-placeholder {
    color: var(--primary-color-two);
}

.ClaimsVetSelectionPage .vet-clinic-search-input::-ms-input-placeholder {
    color: var(--primary-color-two);
}

.ClaimsVetSelectionPage .results {
    margin-top: 13px;
}

.ClaimsVetSelectionPage .dont-see-wrapper {
    margin-top: 10px;
}

.ClaimsVetSelectionPage .dont-see-wrapper button {
    background: transparent;
    border: unset;
    padding: 0;
    font-size: 0.875rem;
    margin-left: 0.1875rem;
}

.ClaimsVetSelectionPage .dont-see {
    font-weight: bold;
    color: var(--primary-color);
}

.ClaimsVetSelectionPage .add-clinic-link {
    text-decoration: underline;
    font-weight: bold;
    color: var(--primary-color);
}

.ClaimsVetSelectionPage .add-clinic-link:focus {
    outline: none;
}

.ClaimsVetSelectionPage .add-clinic-link:hover {
    cursor: pointer;
}

.ClaimsVetSelectionPage .red {
    color: red;
}

.ClaimsVetSelectionPage .no-results-list {
    margin-top: 0.625rem;
}

.ClaimsVetSelectionPage .vet-clinic-list li {
    list-style: none;
    margin-top: 0.625rem;
}

.ClaimsVetSelectionPage .item-wrapper {
    background-color: #E9F0EE;
    padding: 0.625rem 0.3125rem;
    border-radius: 10px;
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 10% 90%;
}

.ClaimsVetSelectionPage .item-wrapper .item-title {
    color: var(--primary-color);
    text-align: left;
    font-size: 0.875rem;
}

.ClaimsVetSelectionPage .item-wrapper .item-description {
    color: var(--primary-color);
}

.ClaimsVetSelectionPage .item-wrapper label {
    margin: auto;
}

.ClaimsVetSelectionPage #vet-clinic {
    padding: unset;
}

.ClaimsVetSelectionPage input[type="radio"] {
    height: 1.5rem;
    width: 1.5rem;
}

.ClaimsVetSelectionPage .next-button {
    margin-top: 20px;
    background-color: var(--primary-color);
}

.ClaimsVetSelectionPage .load-more-button {
    margin: 1.25rem 0rem;
}

.ClaimsVetSelectionPage .load-more-button span {
    width: 100%;
}