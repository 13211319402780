.List {
    background-color: white;
}

.List .List__Item {
    list-style: none;
    padding: 1rem;
    border-bottom: 0.0625rem solid var(--primary-color-five);
}

.List .List__Item:first-of-type {
    border-top: 0.0625rem solid var(--primary-color-five);
}

.List .ItemLeftWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.List .ItemRightWrapper {
    margin-left: auto;
    display: flex;
    align-items: center;
}

.List .ItemRightWrapper:hover {
    cursor: pointer;
}

.List .ItemTitle {
    font-size: 18px;
    color: #333333;
    font-weight: bold;
    margin-bottom: 4px;
}

.List__ImageWrapper {
    display: grid;
    grid-template-rows: 6.25rem;
    grid-template-columns: repeat(2, 6.25rem);
    grid-gap: 0.5rem;
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
}

.List__ImageWrapper img {
    width: 100%;
    object-fit: cover;
    height: 100%;
}

@media (max-width: 540px) {
    .List__ImageWrapper {
        grid-template-columns: repeat(4, 6.25rem);
    }
}

.List__Header {
    background-color: var(--secondary-color-two);
    color: #FFFFFF;
    font-size: 1.5rem;
}

.List__Footer {
    padding: 1rem;
    background-color: var(--secondary-color-two);
    color: #FFFFFF;
    font-size: 1.5rem;
}