/* react-bootstrap modal show */
.fade.show {
  opacity: 1;
}

/* form errors */
.form-group.error > input,
.form-group.error > select,
.form-group.error > textarea,
.div.error > .faux-checkbox {
  border-color: #ee3524 !important;
}

.form-group.error > small {
  color: #ee3524;
}

/* Location modal store list - show border under last child */
.modal ul.location-list li:last-child {
  border-bottom: 0.0625rem solid #d4dadc;
  padding-bottom: 1.1875rem;
}

.btn-loadmore {
  width: auto !important;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1.25rem;
}

/* PetcoLoader */
#petco-loader {
  background: rgba(255, 255, 255, 0.7);
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1002;
}

#petco-loader > div {
  position: relative;
  top: 30%;
}

button.link {
  cursor: pointer;
  overflow: visible;
  width: auto;
  text-align: left;
  color: #00aae7;
  background: none;
  text-decoration: underline;
  font-size: smaller;
  margin-bottom: 0.25rem;
  padding: 0;
  border: none;
}
