.combox-box-container .ComboBoxWrapper {
    height: 56px;
    position: absolute;
    top: 10px;
}

.combox-box-container .ComboBoxWrapper .ComboxBoxWrapper-control {
    height: 56px;
}

.combox-box-container {
    height: 56px;
    position: relative;
    margin-bottom: 20px;
}

.combox-box-container .ComboBoxWrapper {
    min-width: 144px;
}

.mobile-container .ComboBoxWrapper {
    border: 1px solid var(--primary-color-eleven);
    height: 54px;
}

.combox-box-container #combo-box-label {
    z-index: 999;
    top: 18px;
    margin: 0;
    padding: 0;
    position: absolute;
    left: 14px;
    color: var(--primary-color-eleven);
}

#mobile-no-value-floating-select-id {
    height: 54px;
    position: absolute;
    max-width: 153px;
    min-width: 144px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 12px;
}

#mobile-has-value-floating-select-id {
    height: 54px;
    position: absolute;
    max-width: 153px;
    min-width: 144px;
    padding-top: 21px;
    padding-bottom: 5px;
    padding-left: 12px;
}

#react-select-no-value-floating-select-id {
    height: 56px;
    position: absolute;
    max-width: 153px;
    min-width: 144px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 0px;
    top: -5px;
}

#react-select-has-value-floating-select-id {
    height: 56px;
    position: absolute;
    max-width: 153px;
    min-width: 144px;
    padding-top: 0px;
    padding-bottom: 5px;
    padding-left: 0px;

}

.combox-box-container .css-1s2u09g-control {
    border-radius: 0px !important;
    border-color: var(--primary-color-eleven) !important;
    height: 56px;
}

.combox-box-container .css-14el2xx-placeholder {
    position: absolute;
    top: 16px;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.375rem;
    letter-spacing: 0.5px;
    color: #656565;
}

.ComboBoxWrapper .css-14el2xx-placeholder {
    color: #656565;
}

.combox-box-container .css-qc6sy-singleValue {
    margin-top: 4px;
    margin-left: 4px;
}

.combox-box-container .react-select {
    z-index: 2;
}