@charset "UTF-8";
@import url("css-variables.css");

@font-face {
	font-family: "PetcoCircularWeb";
	src:url("../fonts/PetcoCircularWeb-Black.woff2") format("woff2"),
	    url("../fonts/PetcoCircularWeb-Black.woff") format("woff");
	font-weight: 900;
}

@font-face {
	font-family: "PetcoCircularWeb";
	src:url("../fonts/PetcoCircularWeb-Bold.woff2") format("woff2"),
	    url("../fonts/PetcoCircularWeb-Bold.woff") format("woff");
	font-weight: 700;
}

@font-face {
	font-family: "PetcoCircularWeb";
	src:url("../fonts/PetcoCircularWeb-Medium.woff2") format("woff2"),
	    url("../fonts/PetcoCircularWeb-Medium.woff") format("woff");
	font-weight: 600;
}

@font-face {
	font-family: "PetcoCircularWeb";
	src:url("../fonts/PetcoCircularWeb-Book.woff2") format("woff2"),
	    url("../fonts/PetcoCircularWeb-Book.woff") format("woff");
	font-weight: 500;
}

@font-face {
	font-family: "PetcoCircularWeb";
	src:url("../fonts/PetcoCircularWeb-Regular.woff2") format("woff2"),
	    url("../fonts/PetcoCircularWeb-Regular.woff") format("woff");
	font-weight: 400;
}

* {
  font-family: var(--primary-font);
  font-size: 1rem;
  word-break: break-word;
}

@media only screen and (max-width: 540px) {
  * {
    font-size: 1rem;
  }
}

.app-logo {
  /* content: var(--app-logo); */
  max-width: 10rem;
}

.steps-icon {
  content: var(--steps-icon);
  display: block;
}

.container,
.row,
.row [class*="col-"]img.block {
  width: 100%;
}

.main-body,
.padding-full,
[class*="padding-"] {
  box-sizing: border-box;
}

.position-relative,
.row {
  position: relative;
}

.clearfix:after,
.row::after,
.text-arrow:after {
  content: "";
}

.btn,
.text-center,
.text-center>p {
  text-align: center;
}

#overlay,
.datepicker--nav-action.-disabled- {
  visibility: hidden;
}

a,
abbr,
acronym,
address,
applet,
article,
aside,
audio,
b,
big,
blockquote,
body,
canvas,
caption,
center,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
embed,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
output,
p,
pre,
q,
ruby,
s,
samp,
section,
small,
span,
strike,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
tt,
u,
ul,
var,
video {
  margin: 0;
  padding: 0;
  border: 0;
  /* font: inherit; */
  vertical-align: baseline;
}

ol,
ul {
  font-size: 0.9375em;
}

.btn,
body,
html {
  font-family: var(--primary-font);
}

fieldset h3,
fieldset h4,
h1 {
  font-stretch: normal;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

ol,
ul {
  list-style: none;
}

.container {
  max-width: 68.4375rem;
  margin-left: auto;
  margin-right: auto;
  clear: both;
}

.container.full-width {
  max-width: none;
}

.container.full-width .row [class*="col-"] {
  margin: 0;
}

.container.full-width .row .col-center {
  margin: 0 auto;
}

.row [class*="col-"] {
  float: left;
  width: 100%;
  margin: 0.5rem 0;
  min-height: 0.125rem;
  box-sizing: border-box;
}

.row [class*="col-"]:empty {
  margin: 0;
  min-height: 0;
}

.no-margin .row [class*="col-"] {
  margin: 0.5rem 0;
}

.row .col-center {
  float: none;
  margin: 0.5rem auto;
  clear: both;
}

.row::after {
  display: table;
  clear: both;
}

.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9 {
  width: 96%;
}

.col-1-sm {
  width: 4.33333%;
}

.col-2-sm {
  width: 12.66667%;
}

.col-3-sm {
  width: 21%;
}

.col-4-sm {
  width: 29.33333%;
}

.col-5-sm {
  width: 37.66667%;
}

.col-6-sm {
  width: 46%;
}

.col-7-sm {
  width: 54.33333%;
}

.col-8-sm {
  width: 62.66667%;
}

.col-9-sm {
  width: 71%;
}

.col-10-sm {
  width: 79.33333%;
}

.col-11-sm {
  width: 87.66667%;
}

.col-12-sm {
  width: 96%;
}

.container.no-margin>.col-1-sm {
  width: 8.33333%;
}

.container.no-margin>.col-2-sm {
  width: 16.66667%;
}

.container.no-margin>.col-3-sm {
  width: 25%;
}

.container.no-margin>.col-4-sm {
  width: 33.33333%;
}

.container.no-margin>.col-5-sm {
  width: 41.66667%;
}

.container.no-margin>.col-6-sm {
  width: 50%;
}

.container.no-margin>.col-7-sm {
  width: 58.33333%;
}

.container.no-margin>.col-8-sm {
  width: 66.66667%;
}

.container.no-margin>.col-9-sm {
  width: 75%;
}

.container.no-margin>.col-10-sm {
  width: 83.33333%;
}

.container.no-margin>.col-11-sm {
  width: 91.66667%;
}

.container.no-margin>.col-12-sm {
  width: 100%;
}

@media screen and (min-width: 769px) and (max-width: 975px) {

  .container .col-1,
  .container.margin .col-1 {
    width: 4.33333%;
  }

  .container .col-2,
  .container.margin .col-2 {
    width: 12.66667%;
  }

  .container .col-3,
  .container.margin .col-3 {
    width: 21%;
  }

  .container .col-4,
  .container.margin .col-4 {
    width: 29.33333%;
  }

  .container .col-5,
  .container.margin .col-5 {
    width: 37.66667%;
  }

  .container .col-6,
  .container.margin .col-6 {
    width: 46%;
  }

  .container .col-7,
  .container.margin .col-7 {
    width: 54.33333%;
  }

  .container .col-8,
  .container.margin .col-8 {
    width: 62.66667%;
  }

  .container .col-9,
  .container.margin .col-9 {
    width: 71%;
  }

  .container .col-10,
  .container.margin .col-10 {
    width: 79.33333%;
  }

  .container .col-11,
  .container.margin .col-11 {
    width: 87.66667%;
  }

  .container .col-12,
  .container.margin .col-12 {
    width: 96%;
  }

  .container.no-margin .row>.col-1,
  .container.no-margin>.col-1 {
    width: 8.33333%;
  }

  .container.no-margin .row>.col-2,
  .container.no-margin>.col-2 {
    width: 16.66667%;
  }

  .container.no-margin .row>.col-3,
  .container.no-margin>.col-3 {
    width: 25%;
  }

  .container.no-margin .row>.col-4,
  .container.no-margin>.col-4 {
    width: 33.33333%;
  }

  .container.no-margin .row>.col-5,
  .container.no-margin>.col-5 {
    width: 41.66667%;
  }

  .container.no-margin .row>.col-6,
  .container.no-margin>.col-6 {
    width: 50%;
  }

  .container.no-margin .row>.col-7,
  .container.no-margin>.col-7 {
    width: 58.33333%;
  }

  .container.no-margin .row>.col-8,
  .container.no-margin>.col-8 {
    width: 66.66667%;
  }

  .container.no-margin .row>.col-9,
  .container.no-margin>.col-9 {
    width: 75%;
  }

  .container.no-margin .row>.col-10,
  .container.no-margin>.col-10 {
    width: 83.33333%;
  }

  .container.no-margin .row>.col-11,
  .container.no-margin>.col-11 {
    width: 91.66667%;
  }

  .container.no-margin .row>.col-12,
  .container.no-margin>.col-12 {
    width: 100%;
  }

  .hidden-sm {
    display: block;
  }
}

@media screen and (min-width: 976px) {

  .container .col-1,
  .container.margin .col-1 {
    width: 4.33333%;
  }

  .container .col-2,
  .container.margin .col-2 {
    width: 12.66667%;
  }

  .container .col-3,
  .container.margin .col-3 {
    width: 21%;
  }

  .container .col-4,
  .container.margin .col-4 {
    width: 29.33333%;
  }

  .container .col-5,
  .container.margin .col-5 {
    width: 37.66667%;
  }

  .container .col-6,
  .container.margin .col-6 {
    width: 46%;
  }

  .container .col-7,
  .container.margin .col-7 {
    width: 54.33333%;
  }

  .container .col-8,
  .container.margin .col-8 {
    width: 62.66667%;
  }

  .container .col-9,
  .container.margin .col-9 {
    width: 71%;
  }

  .container .col-10,
  .container.margin .col-10 {
    width: 79.33333%;
  }

  .container .col-11,
  .container.margin .col-11 {
    width: 87.66667%;
  }

  .container .col-12,
  .container.margin .col-12 {
    width: 96%;
  }

  .container.no-margin .row>.col-1,
  .container.no-margin>.col-1 {
    width: 8.33333%;
  }

  .container.no-margin .row>.col-2,
  .container.no-margin>.col-2 {
    width: 16.66667%;
  }

  .container.no-margin .row>.col-3,
  .container.no-margin>.col-3 {
    width: 25%;
  }

  .container.no-margin .row>.col-4,
  .container.no-margin>.col-4 {
    width: 33.33333%;
  }

  .container.no-margin .row>.col-5,
  .container.no-margin>.col-5 {
    width: 41.66667%;
  }

  .container.no-margin .row>.col-6,
  .container.no-margin>.col-6 {
    width: 50%;
  }

  .container.no-margin .row>.col-7,
  .container.no-margin>.col-7 {
    width: 58.33333%;
  }

  .container.no-margin .row>.col-8,
  .container.no-margin>.col-8 {
    width: 66.66667%;
  }

  .container.no-margin .row>.col-9,
  .container.no-margin>.col-9 {
    width: 75%;
  }

  .container.no-margin .row>.col-10,
  .container.no-margin>.col-10 {
    width: 83.33333%;
  }

  .container.no-margin .row>.col-11,
  .container.no-margin>.col-11 {
    width: 91.66667%;
  }

  .container.no-margin .row>.col-12,
  .container.no-margin>.col-12 {
    width: 100%;
  }

  .hidden-sm {
    display: block;
  }
}

ul>li {
  list-style: none;
}

ul.list-unstyled>li {
  list-style: none;
}

ul.list-styled {
  padding-left: 1.25rem;
}

ul.list-inline>li {
  display: inline-block;
}

ol li {
  list-style: decimal;
}

fieldset ul li,
ol.list-unstyled li {
  list-style: none;
}

.clearfix:after {
  display: block;
  clear: both;
}

@media screen and (min-height: 1095px) {
  .main-body {
    padding: 0;
  }
}

.block {
  display: block !important;
}

.inline {
  display: inline-block !important;
}

.full-width {
  width: 100%;
}

.full-height {
  height: 100%;
}

.bg-lightestgray {
  background-color: #f4f6f6;
}

.margin-sm {
  margin: 0.625rem;
}

.margin-full-xsm {
  margin: 0.3125rem;
}

.margin-full-sm {
  margin: 0.625rem;
}

.margin-full-md {
  margin: 0.9375rem;
}

.margin-full-lg {
  margin: 1.25rem;
}

.margin-top-xsm {
  margin-top: 0.3125rem;
}

.margin-right-xsm {
  margin-right: 0.3125rem;
}

.margin-bottom-xsm {
  margin-bottom: 0.3125rem;
}

.margin-left-xsm {
  margin-left: 0.3125rem;
}

.margin-top-sm {
  margin-top: 0.625rem;
}

.margin-right-sm {
  margin-right: 0.625rem;
}

.margin-bottom-sm {
  margin-bottom: 0.625rem;
}

.margin-left-sm {
  margin-left: 0.625rem;
}

.margin-top-md {
  margin-top: 0.9375rem;
}

.margin-right-md {
  margin-right: 0.9375rem;
}

.margin-bottom-md {
  margin-bottom: 0.9375rem;
}

.margin-left-md {
  margin-left: 0.9375rem;
}

.margin-lg {
  margin: 1.25rem;
}

.margin-top-lg {
  margin-top: 1.25rem;
}

.margin-right-lg {
  margin-right: 1.25rem;
}

.margin-bottom-lg {
  margin-bottom: 1.25rem;
}

.margin-left-lg {
  margin-left: 1.25rem;
}

.margin-xlg {
  margin: 2.5rem;
}

.margin-bottom-xlg {
  margin-bottom: 2.5rem;
}

.margin-right-xlg {
  margin-right: 2.5rem;
}

.margin-top-xlg {
  margin-top: 2.5rem;
}

.padding-sm {
  padding: 0.625rem;
}

.padding-lg {
  padding: 1.25rem;
}

.padding-xlg {
  padding: 2.5rem;
}

.padding-bottom-xlg {
  padding-bottom: 2.5rem;
}

.padding-top-xlg {
  padding-top: 2.5rem;
}

.padding-full {
  padding: 0.625rem;
}

.padding-full-xs {
  padding: 0.3125rem;
}

.padding-full-sm {
  padding: 0.625rem;
}

.padding-full-md {
  padding: 0.9375rem;
}

.padding-full-lg {
  padding: 1.25rem;
}

.padding-top-xs {
  padding-top: 0.3125rem;
}

.padding-top-sm {
  padding-top: 0.625rem;
}

.padding-right-sm {
  padding-right: 0.625rem;
}

.padding-bottom-sm {
  padding-bottom: 0.625rem;
}

.padding-left-sm {
  padding-left: 0.625rem;
}

.padding-top-md {
  padding-top: 0.9375rem;
}

.padding-right-md {
  padding-right: 0.9375rem;
}

.padding-bottom-md {
  padding-bottom: 0.9375rem;
}

.padding-left-md {
  padding-left: 0.9375rem;
}

.padding-top-lg {
  padding-top: 1.25rem;
}

.padding-right-lg {
  padding-right: 1.25rem;
}

.padding-bottom-lg {
  padding-bottom: 1.25rem;
}

.padding-left-lg {
  padding-left: 1.25rem;
}

.valign-top {
  vertical-align: top;
}

.valign-mid {
  vertical-align: middle;
}

.valign-bot {
  vertical-align: bottom;
}

.pull-left {
  float: left !important;
}

.pull-right {
  float: right !important;
}

.primary {
  color: #00aae7;
}

.error,
.form-required,
.text-danger {
  color: #ee3524;
}

.text-success {
  color: #468847;
}

.text-success:hover {
  color: #356635;
}

.text-warning {
  color: #c09853;
}

.text-warning:hover {
  color: #a47e3c;
}

.approved,
.remaining {
  color: #46c126;
}

.text-notice {
  color: #fc951f;
}

.link-pointer {
  cursor: pointer;
}

.textarea .img-responsive {
  display: block;
  max-width: 100%;
  width: 100%;
}

.spacer-lg-top {
  padding-top: 3.125rem;
}

.spacer-lg-right {
  padding-right: 3.125rem;
}

.spacer-lg-bottom {
  padding-bottom: 3.125rem;
}

.spacer-lg-left {
  padding-left: 3.125rem;
}

.spacer-md-top {
  padding-top: 2.5rem;
}

.spacer-md-right {
  padding-right: 2.5rem;
}

.spacer-md-bottom {
  padding-bottom: 2.5rem;
}

.spacer-md-left {
  padding-left: 2.5rem;
}

.spacer-sm-top {
  padding-top: 1.25rem;
}

.spacer-sm-right {
  padding-right: 1.25rem;
}

.spacer-sm-bottom {
  padding-bottom: 1.25rem;
}

.spacer-sm-left {
  padding-left: 1.25rem;
}

.spacer-xsm-top {
  padding-top: 0.625rem;
}

.spacer-xsm-right {
  padding-right: 0.625rem;
}

.spacer-xsm-bottom {
  padding-bottom: 0.625rem;
}

.spacer-xsm-left {
  padding-left: 0.625rem;
}

.display-inline {
  display: inline;
}

.clear-space {
  padding: 0;
  margin: 0;
}

.show {
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.position-absolute {
  position: absolute;
}

sup {
  top: -0.5em;
}

.margin-none {
  margin: 0;
}

.padding-left-none {
  padding-left: 0;
}

.padding-right-none {
  padding-right: 0;
}

.padding-top-none {
  padding-top: 0;
}

.padding-bottom-none {
  padding-bottom: 0;
}

.padding-none {
  padding: 0;
}

.clear {
  clear: both;
}

.fade {
  opacity: 0;
  -webkit-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear;
}

.width-successmsg {
  max-width: 33.75rem !important;
}

.fade.in {
  opacity: 1;
}

.collapse {
  display: none;
}

.collapse.in {
  display: block;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  -webkit-transition: height 0.35s ease;
  transition: height 0.35s ease;
}

@media screen and (min-width: 769px) and (max-width: 975px) {
  .width-10 {
    width: 10%;
  }

  .width-15 {
    width: 15%;
  }

  .width-20 {
    width: 20%;
  }

  .width-25 {
    width: 25%;
  }

  .width-30 {
    width: 30%;
  }

  .width-35 {
    width: 35%;
  }

  .width-40 {
    width: 40%;
  }

  .width-45 {
    width: 45%;
  }

  .width-50 {
    width: 50%;
  }

  .width-55 {
    width: 55%;
  }

  .width-60 {
    width: 60%;
  }

  .width-65 {
    width: 65%;
  }

  .width-70 {
    width: 70%;
  }

  .width-75 {
    width: 75%;
  }

  .width-80 {
    width: 80%;
  }

  .width-85 {
    width: 85%;
  }

  .width-90 {
    width: 90%;
  }

  .width-95 {
    width: 95%;
  }

  .width-100-sm {
    width: 100%;
  }

  .modal .modal-dialog {
    /* max-height: 75%; */
  }

  .full-height-modal.modal>.modal-dialog {
    max-height: 95%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

@media screen and (min-width: 976px) {
  .width-10 {
    width: 10%;
  }

  .width-15 {
    width: 15%;
  }

  .width-20 {
    width: 20%;
  }

  .width-25 {
    width: 25%;
  }

  .width-30 {
    width: 30%;
  }

  .width-35 {
    width: 35%;
  }

  .width-40 {
    width: 40%;
  }

  .width-45 {
    width: 45%;
  }

  .width-50 {
    width: 50%;
  }

  .width-55 {
    width: 55%;
  }

  .width-60 {
    width: 60%;
  }

  .width-65 {
    width: 65%;
  }

  .width-70 {
    width: 70%;
  }

  .width-75 {
    width: 75%;
  }

  .width-80 {
    width: 80%;
  }

  .width-85 {
    width: 85%;
  }

  .width-90 {
    width: 90%;
  }

  .width-95 {
    width: 95%;
  }

  .width-100-sm {
    width: 100%;
  }

  .modal .modal-dialog {
    /* max-height: 75%; */
  }

  .full-height-modal.modal>.modal-dialog {
    max-height: 95%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

@media screen and (min-width: 421px) and (max-width: 768px) {
  .width-10-md {
    width: 10%;
  }

  .width-15-md {
    width: 15%;
  }

  .width-20-md {
    width: 20%;
  }

  .width-25-md {
    width: 25%;
  }

  .width-30-md {
    width: 30%;
  }

  .width-35-md {
    width: 35%;
  }

  .width-40-md {
    width: 40%;
  }

  .width-45-md {
    width: 45%;
  }

  .width-50-md {
    width: 50%;
  }

  .width-55-md {
    width: 55%;
  }

  .width-60-md {
    width: 60%;
  }

  .width-65-md {
    width: 65%;
  }

  .width-70-md {
    width: 70%;
  }

  .width-75-md {
    width: 75%;
  }

  .width-80-md {
    width: 80%;
  }

  .width-85-md {
    width: 85%;
  }

  .width-90-md {
    width: 90%;
  }

  .width-95-md {
    width: 95%;
  }

  .width-100-md {
    width: 100%;
  }
}

@media screen and (max-width: 420px) {
  .width-10-sm {
    width: 10%;
  }

  .width-15-sm {
    width: 15%;
  }

  .width-20-sm {
    width: 20%;
  }

  .width-25-sm {
    width: 25%;
  }

  .width-30-sm {
    width: 30%;
  }

  .width-35-sm {
    width: 35%;
  }

  .width-40-sm {
    width: 40%;
  }

  .width-45-sm {
    width: 45%;
  }

  .width-50-sm {
    width: 50%;
  }

  .width-55-sm {
    width: 55%;
  }

  .width-60-sm {
    width: 60%;
  }

  .width-65-sm {
    width: 65%;
  }

  .width-70-sm {
    width: 70%;
  }

  .width-75-sm {
    width: 75%;
  }

  .width-80-sm {
    width: 80%;
  }

  .width-85-sm {
    width: 85%;
  }

  .width-90-sm {
    width: 90%;
  }

  .width-95-sm {
    width: 95%;
  }

  .width-100-sm {
    width: 100%;
  }
}

h1.white,
h2.white,
h3.white,
h4.white,
h5.white {
  color: #fff;
  font-weight: 700;
  line-height: 1.1em;
  margin: 0;
}

p.white {
  font-weight: 400;
  color: #fff;
}

.text-tile-lg {
  background-color: #fff;
  border-radius: 0.3125rem;
  box-shadow: 0 0.125rem 0.3125rem 0 rgba(0, 0, 0, 0.1);
}

.alert {
  padding: 0.9375rem;
  margin-bottom: 1.375rem;
  border: 0.0625rem solid transparent;
  border-radius: 0.25rem;
}

.alert.alert-success {
  background-color: #dff0d8;
  border-color: #d6e9c6;
  color: #468847;
}

.alert .close {
  float: right;
  font-size: 1.3125rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 0.0625rem 0 #fff;
  opacity: 0.2;
  filter: alpha(opacity=20);
}

.alert .close:focus,
.alert .close:hover {
  color: #000;
  text-decoration: none;
  cursor: pointer;
  opacity: 0.5;
  filter: alpha(opacity=50);
}

.alert {
  padding: 0;
  cursor: pointer;
  background: 0 0;
  border: 0;
  -webkit-appearance: none;
}

.margin-top-none {
  margin-top: 0 !important;
}

.margin-bottom-none {
  margin-bottom: 0 !important;
}

.margin-left-none {
  margin-left: 0 !important;
}

.margin-right-none {
  margin-right: 0 !important;
}

.sr-only {
  position: absolute;
  left: -624.9375rem;
}

form [class*="btn-"],
form [class*="btn-"]:disabled,
form [class*="btn-"]:disabled:hover,
form.form-inline {
  display: table;
}

form.form-inline .form-group {
  display: table-cell;
}

form.form-inline .form-group:not(:first-child) {
  padding-left: 0.9375rem;
}

form.form-inline .form-group a.btn {
  padding: 0.75rem 0.9375rem;
}

form.form-inline .form-group select.form-control {
  border-radius: 0.3125rem;
}

a:focus,
div:focus,
input:focus {
  outline-color: #215FE8;
  outline: none;
}

.btn:focus,
select:focus {
  outline-color: #00aae7;
}

.label,
label {
  clear: both;
  color: #8e9da2;
  float: left;
  font-size: 0.8125em;
  font-weight: 700;
  margin: 0 0 0.4375rem;
}

.label.valid,
label.valid {
  color: #46c126;
}

.label.invalid,
label.invalid {
  color: #ee3524;
}

label#card {
  display: block;
  float: none;
  margin-bottom: 4px;
}

input[type="checkbox"]+.label,
input[type="checkbox"]+label {
  font-weight: 400;
  color: #333;
  cursor: pointer;
}

select,
textarea {
  border: 0rem solid #a9b5ba;
  color: #666;
  font-size: 0.9375em;
  width: 100%;
  box-sizing: border-box;
}

textarea {
  background-color: rgba(244, 246, 246, 0.4);
  height: 5.1875rem;
  padding: 0.625rem;
}

input[type="text"],
input[type="tel"],
input[type="email"],
input[type="password"] {
  background-color: rgba(244, 246, 246, 0.4);
  border: 0rem solid #a9b5ba;
  box-sizing: border-box;
  color: #666;
  font-size: 0.9375em;
  height: 2.6875rem;
  padding: 0 0.625rem;
  width: 100%;
  background-size: 0;
  background-position: 95% center;
  transition: 0.2s;
}

#react-select-3-input,
#react-select-2-input {
  transition: 0s;
}

select {
  padding: 0.6875rem 0.9375rem;
  box-shadow: none;
  background: url(img/arrow.png) 96% 50% no-repeat #fff;
  height: 2.6875rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

select::-ms-expand {
  display: none;
}

select.valid {
  border-color: #46c126;
}

select.invalid {
  border-color: #ee3524;
}

input[type="radio"] {
  float: left;
  margin-right: 0.625rem;
}

/* 
  This should prevent iOS from zooming in on dropdowns and other inputs. 
  DO NOT change the font-size. 
*/
@media screen and (max-width: 790px) {

  input,
  input:focus,
  select,
  select:focus,
  textarea,
  textarea:focus {
    font-size: 1rem;
  }
}

.btn {
  box-sizing: border-box;
  border: 0;
  border-radius: 0.3125rem;
  cursor: pointer;
  display: inline-block;
  font-size: 0.9375em;
  padding: 0.625rem 0.9375rem;
  transition: background-color 0.2s, border 0.2s;
}

.btn-primary,
button-primary {
  background-color: #00aae7;
  color: #fff;
}

.btn-primary:focus,
.btn-primary:hover,
button-primary:focus,
button-primary:hover {
  background-color: #0084b4;
  color: #fff;
}

.btn-default,
button-default {
  background-color: #e9eced;
  color: #000;
}

.btn-default:focus,
.btn-default:hover,
button-default:focus,
button-default:hover {
  background-color: #cdd4d6;
  color: #000;
}

.btn-danger,
button-danger {
  background-color: #ee3524;
  color: #fff;
}

.btn-danger:focus,
.btn-danger:hover,
button-danger:focus,
button-danger:hover {
  background-color: #cf2010;
  color: #fff;
}

.btn-inverted,
button-inverted {
  background-color: #fff;
  color: #00aae7;
}

.btn-inverted:focus,
.btn-inverted:hover,
button-inverted:focus,
button-inverted:hover {
  background-color: #e6e6e6;
}

.btn-secondary,
button-secondary {
  background-color: #fff;
  color: #00aae7;
  border-color: #fff;
}

.btn-secondary:focus,
.btn-secondary:hover,
button-secondary:focus,
button-secondary:hover {
  text-decoration: underline;
}

.btn-full,
button-full {
  width: 100%;
}

.btn-thin,
button-thin {
  padding: 0.375rem 0 !important;
}

.btn-large,
button-large {
  font-size: 1.25rem;
  padding: 1.125rem 0.9375rem;
  transition: 0.3s;
  border: 0.0625rem solid #00aae7;
}

.btn-large:disabled,
button-large:disabled {
  padding: 1.125rem 0.9375rem;
}

.btn-smaller,
button-smaller {
  min-width: 4.6875rem;
  margin-bottom: 0.625rem;
  padding: 0.8125rem 0.5rem 0.9375rem;
  font-size: 0.85em;
  border-width: 0.0625rem;
  border-radius: 0.1875rem;
}

a.no-style,
p {
  font-size: 0.9375em;
}

.btn-gray,
button-gray {
  background-color: #a9b5ba;
  color: #fff;
  border-color: #8e9da2;
}

.btn-gray:focus,
.btn-gray:hover,
button-gray:focus,
button-gray:hover {
  background-color: #8d9da3;
  color: #fff;
}

.btn-rounded,
button-rounded {
  background-color: #00aae7;
  color: #fff;
  padding: 0.9375rem1.25rem;
  border-radius: 0.3125rem;
}

.btn-rounded:focus,
.btn-rounded:hover,
button-rounded:focus,
button-rounded:hover {
  background-color: #0084b4;
  color: #fff;
}

.btn.disabled,
.btn.disabled:focus,
.btn.disabled:hover,
.btn:disabled,
.btn:disabled:hover,
button.disabled,
button.disabled:focus,
button.disabled:hover,
button:disabled,
button:disabled:hover {
  background-color: #d4dadc;
  border: 0rem solid #a9b5ba;
  color: #8e9da2;
  cursor: initial;
}

.btn-teal,
button-teal {
  background-color: #21c1c5;
  color: #fff;
}

.btn-teal:focus,
.btn-teal:hover,
button-teal:focus,
button-teal:hover {
  background-color: #1a9699;
  color: #fff;
}

.btn-yellow,
button-yellow {
  background-color: #fdb954;
  color: #fff;
}

.btn-yellow:focus,
.btn-yellow:hover,
button-yellow:focus,
button-yellow:hover {
  background-color: #fca422;
  color: #fff;
}

.btn-declined,
button-declined {
  background-color: #ee3524;
  color: #fff;
}

.btn-declined:focus,
.btn-declined:hover,
button-declined:focus,
button-declined:hover {
  background-color: #cf2010;
  color: #fff;
}

.btn-approved,
button-approved {
  background-color: #46c126;
  color: #fff;
}

.btn-approved:focus,
.btn-approved:hover,
button-approved:focus,
button-approved:hover {
  color: #fff;
  background-color: #37961e;
}

.btn-notice,
button-notice {
  background-color: #fc951f;
  color: #fff;
}

.btn-notice:focus,
.btn-notice:hover,
button-notice:focus,
button-notice:hover {
  background-color: #e57c03;
  color: #fff;
}

a.no-style,
h5,
p {
  color: #666;
}

.btn-transparent,
.btn-transparent:focus,
.btn-transparent:hover,
button-transparent,
button-transparent:focus,
button-transparent:hover {
  background-color: transparent;
}

body,
html {
  line-height: 1.25em;
  color: #333;
}

@media only screen and (max-width: 768px) and (orientation: portrait) {
  html {
    -webkit-text-size-adjust: 100%;
  }
}

p {
  line-height: 1.6em;
}

h1,
h2,
h3,
h4 {
  font-weight: 700;
}

.accessible-link,
.text-underline,
p a {
  text-decoration: underline;
}

#bopusSelectEnabledBopusAttrOff a,
#spc-bopus-store-info .lsa-hours a,
#spc-bopus-store-info .lsa-phone a,
#store-hours a,
#store-pickup-dropdown .lsa-hours a,
#store-pickup-dropdown .lsa-phone a {
  text-decoration: none;
}

h1 {
  margin: 0;
  padding: 2% 0 0;
  font-size: 2em;
  line-height: 1.74em;
  letter-spacing: normal;
}

h2 {
  font-size: 1.5em;
  line-height: 2em;
}

h3 {
  font-size: 1.3em;
  line-height: 1.75em;
}

h4 {
  font-size: 1.5625em;
  line-height: 1.2em;
  letter-spacing: 0.0125rem;
}

h5 {
  font-size: 0.9375em;
}

.textarea h1,
.textarea h2,
.textarea h3,
.textarea h4,
.textarea h5 {
  color: #333;
  font-weight: 700;
  line-height: 1.1em;
  margin: 1.25rem 0 0.625rem;
}

strong {
  font-weight: 700;
}

em {
  font-style: italic;
}

.textarea h1 {
  font-size: 2.5em;
}

.textarea h2 {
  font-size: 1.75em;
}

.textarea h3 {
  font-size: 1.375em;
}

.textarea h4 {
  font-size: 1.125em;
}

.textarea h5 {
  font-size: 1em;
  line-height: 1.1em;
  margin: 1.25rem 0 0.625rem;
}

.textarea ol,
.textarea ul {
  margin-left: 1.5625rem;
  font-size: 0.9375em;
}

.textarea ol li,
.textarea ul li {
  font-size: 0.9375em;
  line-height: 1.6em;
  color: #666;
}

.textarea p {
  padding: 0.3125rem 0;
  margin: 0 0 0.6875rem;
}

.text-italic {
  font-style: italic !important;
}

.order-confirm .order-confirm-summary fieldset .summary-list-total,
.text-bold {
  font-weight: 700 !important;
}

.text-normal {
  font-weight: 400 !important;
}

.text-arrow:after {
  display: inline-block;
  height: 0;
  width: 0;
  border: 0.375rem solid transparent;
  border-left-color: inherit;
  border-left-width: 0.625rem;
  margin: 0 0 0 0.4375rem;
}

.order-confirm .product-shipping p,
.order-confirm .ship-list .product-info p,
.order-confirm .shopper-confidence p,
.text-x-small {
  font-size: 0.8125em;
  line-height: 1.25em;
}

.text-small {
  font-size: 0.875em;
  line-height: 0.875em;
}

.text-medium {
  font-size: 0.9375em;
  line-height: 0.9375em;
}

.order-confirm .order-confirm-summary fieldset .summary-list-total,
.text-large {
  font-size: 1.25em;
  line-height: 1.25em;
}

.text-primary {
  color: #00aae7;
}

.text-white,
.text-white a {
  color: #fff;
}

.text-lighter {
  color: #a9b5ba;
}

.order-confirm #order-confirm-info-boxes.order-confirm-info-boxes .info-box-1 div p.ib-info,
.order-confirm #order-confirm-info-boxes.order-confirm-info-boxes .info-box-2 div p.ib-info,
.order-confirm .order-confirm-summary fieldset .summary-list-item,
.order-confirm .product-shipping p,
.order-confirm .ship-list .product-info p,
.text-light {
  color: #666;
}

.text-lightest {
  color: #8e9da2;
}

.order-confirm #order-confirm-info-boxes.order-confirm-info-boxes .info-box-1 div p.ib-header,
.order-confirm #order-confirm-info-boxes.order-confirm-info-boxes .info-box-2 div p.ib-header,
.order-confirm .order-confirm-summary fieldset .summary-list-total,
.order-confirm .product-shipping p strong,
.order-confirm .ship-list .product-info p strong,
.text-dark {
  color: #333;
}

.text-darkest {
  color: #1a1918;
}

.text-approved {
  color: #46c126;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.datepicker--cells {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.datepicker--cell {
  border-radius: 0.25rem;
  box-sizing: border-box;
  cursor: pointer;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 2rem;
  z-index: 1;
}

.datepicker--cell.-focus- {
  background: #f0f0f0;
}

.datepicker--cell.-current- {
  color: #4eb5e6;
}

.datepicker--cell.-current-.-focus- {
  color: #4a4a4a;
}

.datepicker--cell.-current-.-in-range- {
  color: #4eb5e6;
}

.datepicker--cell.-in-range- {
  background: rgba(92, 196, 239, 0.1);
  color: #4a4a4a;
  border-radius: 0;
}

.datepicker--cell.-in-range-.-focus- {
  background-color: rgba(92, 196, 239, 0.2);
}

.datepicker--cell.-disabled- {
  cursor: default;
  color: #aeaeae;
}

.datepicker--cell.-disabled-.-focus- {
  color: #aeaeae;
}

.datepicker--cell.-disabled-.-in-range- {
  color: #a1a1a1;
}

.datepicker--cell.-disabled-.-current-.-focus- {
  color: #aeaeae;
}

.datepicker--cell.-range-from- {
  border: 0.0625rem solid rgba(92, 196, 239, 0.5);
  background-color: rgba(92, 196, 239, 0.1);
  border-radius: 0.25rem 0 0 0.25rem;
}

.datepicker--cell.-range-to- {
  border: 0.0625rem solid rgba(92, 196, 239, 0.5);
  background-color: rgba(92, 196, 239, 0.1);
  border-radius: 0 0.25rem 0.25rem 0;
}

.datepicker--cell.-selected-,
.datepicker--cell.-selected-.-current- {
  color: #fff;
  background: #5cc4ef;
}

.datepicker--cell.-range-from-.-range-to- {
  border-radius: 0.25rem;
}

.datepicker--cell.-selected- {
  border: none;
}

.datepicker--cell.-selected-.-focus- {
  background: #45bced;
}

.datepicker--cell:empty {
  cursor: default;
}

.datepicker--days-names {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0.5rem 0 0.1875rem;
}

.datepicker--day-name {
  color: #000;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  font-weight: 700;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  text-align: center;
  text-transform: uppercase;
  font-size: 0.8em;
}

.datepicker--body,
.datepicker-inline .datepicker--pointer {
  display: none;
}

.datepicker--cell-day {
  width: 14.28571%;
}

.datepicker--cells-months {
  height: 10.625rem;
  color: #000;
}

.datepicker--cell-month {
  width: 33.33%;
  height: 25%;
}

.datepicker--cells-years,
.datepicker--years {
  height: 10.625rem;
}

.datepicker--cell-year {
  width: 25%;
  height: 33.33%;
}

.datepickers-container {
  position: absolute;
  left: 0;
  top: 0;
}

.datepicker {
  background: #fff;
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  box-sizing: content-box;
  font-family: Tahoma, sans-serif;
  font-size: 0.875rem;
  color: #4a4a4a;
  width: 15.625rem;
  position: absolute;
  left: -6250rem;
  opacity: 0;
  transition: opacity 0.3s ease, left 0s 0.3s, -webkit-transform 0.3s ease;
  transition: opacity 0.3s ease, transform 0.3s ease, left 0s 0.3s;
  transition: opacity 0.3s ease, transform 0.3s ease, left 0s 0.3s,
    -webkit-transform 0.3s ease;
  z-index: 100;
}

.datepicker.-from-top- {
  -webkit-transform: translateY(-0.5rem);
  transform: translateY(-0.5rem);
}

.datepicker.-from-right- {
  -webkit-transform: translateX(0.5rem);
  transform: translateX(0.5rem);
}

.datepicker.-from-bottom- {
  -webkit-transform: translateY(0.5rem);
  transform: translateY(0.5rem);
}

.datepicker.-from-left- {
  -webkit-transform: translateX(-0.5rem);
  transform: translateX(-0.5rem);
}

.datepicker.active {
  opacity: 1;
  -webkit-transform: translate(0);
  transform: translate(0);
  transition: opacity 0.3s ease, transform 0.3s ease, left 0s 0s,
    -webkit-transform 0.3s ease;
}

.datepicker-inline .datepicker {
  border-color: #d7d7d7;
  box-shadow: none;
  color: #4a4a4a;
  position: static;
  left: auto;
  right: auto;
  opacity: 1;
  -webkit-transform: none;
  transform: none;
}

.datepicker--content {
  box-sizing: content-box;
  padding: 0.25rem;
  color: #000;
}

.datepicker--pointer {
  position: absolute;
  background: #fff;
  border-top: 0.0625rem solid #dbdbdb;
  border-right: 0.0625rem solid #dbdbdb;
  width: 0.625rem;
  height: 0.625rem;
  z-index: -1;
}

.-top-center- .datepicker--pointer,
.-top-left- .datepicker--pointer,
.-top-right- .datepicker--pointer {
  top: calc(100% - 0.25rem);
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
}

.-right-bottom- .datepicker--pointer,
.-right-center- .datepicker--pointer,
.-right-top- .datepicker--pointer {
  right: calc(100% - 0.25rem);
  -webkit-transform: rotate(225deg);
  transform: rotate(225deg);
}

.-bottom-center- .datepicker--pointer,
.-bottom-left- .datepicker--pointer,
.-bottom-right- .datepicker--pointer {
  bottom: calc(100% - 0.25rem);
  -webkit-transform: rotate(315deg);
  transform: rotate(315deg);
}

.-left-bottom- .datepicker--pointer,
.-left-center- .datepicker--pointer,
.-left-top- .datepicker--pointer {
  left: calc(100% - 0.25rem);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.-bottom-left- .datepicker--pointer,
.-top-left- .datepicker--pointer {
  left: 0.625rem;
}

.-bottom-right- .datepicker--pointer,
.-top-right- .datepicker--pointer {
  right: 0.625rem;
}

.-bottom-center- .datepicker--pointer,
.-top-center- .datepicker--pointer {
  left: calc(50% - 0.625rem / 2);
}

.-left-top- .datepicker--pointer,
.-right-top- .datepicker--pointer {
  top: 0.625rem;
}

.-left-bottom- .datepicker--pointer,
.-right-bottom- .datepicker--pointer {
  bottom: 0.625rem;
}

.-left-center- .datepicker--pointer,
.-right-center- .datepicker--pointer {
  top: calc(50% - 0.625rem / 2);
}

.datepicker--body.active {
  display: block;
}

.datepicker--cell-day.-other-month-,
.datepicker--cell-year.-other-decade- {
  color: #dedede;
}

.datepicker--cell-day.-other-month-:hover,
.datepicker--cell-year.-other-decade-:hover {
  color: #c5c5c5;
}

.-disabled-.-focus-.datepicker--cell-day.-other-month-,
.-disabled-.-focus-.datepicker--cell-year.-other-decade- {
  color: #dedede;
}

.-selected-.datepicker--cell-day.-other-month-,
.-selected-.datepicker--cell-year.-other-decade- {
  color: #fff;
  background: #a2ddf6;
}

.-selected-.-focus-.datepicker--cell-day.-other-month-,
.-selected-.-focus-.datepicker--cell-year.-other-decade- {
  background: #8ad5f4;
}

.-in-range-.datepicker--cell-day.-other-month-,
.-in-range-.datepicker--cell-year.-other-decade- {
  background-color: rgba(92, 196, 239, 0.1);
  color: #ccc;
}

.-in-range-.-focus-.datepicker--cell-day.-other-month-,
.-in-range-.-focus-.datepicker--cell-year.-other-decade- {
  background-color: rgba(92, 196, 239, 0.2);
}

.datepicker--cell-day.-other-month-:empty,
.datepicker--cell-year.-other-decade-:empty {
  background: 0 0;
  border: none;
}

.datepicker--nav-action:hover,
.datepicker--nav-title:hover {
  background: #f0f0f0;
}

.datepicker--nav {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-bottom: 0.0625rem solid #efefef;
  min-height: 2rem;
  padding: 0.25rem;
}

.datepicker--nav-action,
.datepicker--nav-title {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  cursor: pointer;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.datepicker--nav-action {
  width: 2rem;
  border-radius: 0.25rem;
  color: #000;
  font-weight: 700;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.datepicker--nav-action svg {
  width: 2rem;
  height: 2rem;
}

.datepicker--nav-action path {
  fill: none;
  stroke: #9c9c9c;
  stroke-width: 0.125rem;
}

.datepicker--nav-title {
  border-radius: 0.25rem;
  padding: 0 0.5rem;
}

.datepicker--nav-title i {
  color: #9c9c9c;
  margin-left: 0.3125rem;
}

.datepicker--nav-title.-disabled- {
  cursor: default;
  background: 0 0;
}

.datepicker--buttons {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 0.25rem;
  border-top: 0.0625rem solid #efefef;
}

.datepicker--button {
  color: #4eb5e6;
  cursor: pointer;
  border-radius: 0.25rem;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  height: 2rem;
}

.datepicker--button:hover {
  color: #4a4a4a;
  background: #f0f0f0;
}

#contentSearch {
  clear: both;
}

.hero-slider .hero-banner {
  margin-bottom: 0;
}

.modal-form {
  display: block;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.85);
  z-index: 200;
}

.modal-dialog {
  width: 70%;
  max-width: 350px;
  min-width: 320px;
  margin: 0 auto;
  background: #fff;
  position: absolute;
  top: 5%;
  left: 50%;
  transform: translateX(-50%);
  padding: 16px;
  border-radius: 0.25rem;
  box-shadow: 0 0.125rem 1.0625rem 0 rgba(0, 0, 0, 0.18);
  z-index: 1000;
}

.modal-dialog .modal-title {
  text-align: left;
  font-size: 24px;
  margin-bottom: 10px;
  font-weight: 700;
}

.modal-dialog .modal-footer {
  margin-top: 1.625rem;
  text-align: center;
}

.modal-dialog .modal-footer.coupon-footer {
  margin-top: 0;
  padding-top: 16px;
}

.modal-dialog .modal-footer .component__button {
  max-width: 300px;
  margin: 16px auto 0;
}

.modal-dialog .modal-footer.coupon-footer .component__button {
  max-width: 100%;
  margin-top: 24px;
}

.modal-dialog .modal-footer .component__button span {
  color: var(--primary-color);
}

.modal-dialog .modal-footer .component__button.confirmButton span {
  color: #fff;
}

.modal-dialog .modal-footer .component__button:first-of-type {
  border: 1px solid var(--primary-color);
}

.modal-dialog .modal-footer .component__button.confirmButton {
  background-color: var(--primary-color);
}

.modal-dialog .modal-footer .component__button:last-of-type {
  margin-bottom: 16px;
}

.modal-dialog .modal-footer .component__button.redemption-button-cancel {
  background-color: transparent;
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
}

[data-tns-role="wrapper"] {
  padding: 0 !important;
}

[data-tns-role="wrapper"].ms-touch {
  overflow-x: scroll;
  overflow-y: hidden;
  -ms-overflow-style: none;
  -ms-scroll-chaining: none;
  -ms-scroll-snap-type: mandatory;
  -ms-scroll-snap-points-x: snapInterval(0, 100%);
}

[data-tns-role="content"] {
  position: relative;
  -webkit-transition: all 0s;
  -moz-transition: all 0s;
  transition: all 0s;
}

[data-tns-role="content-wrapper"] [data-tns-role="nav"] {
  display: none;
}

[data-tns-mode="carousel"][data-tns-axis="horizontal"] {
  left: 0;
}

[data-tns-mode="carousel"][data-tns-axis="horizontal"]:after {
  content: "";
  display: table;
  clear: both;
}

[data-tns-mode="carousel"][data-tns-axis="horizontal"]>a,
[data-tns-mode="carousel"][data-tns-axis="horizontal"]>div,
[data-tns-mode="carousel"][data-tns-axis="horizontal"]>li,
[data-tns-mode="carousel"][data-tns-axis="horizontal"]>span {
  float: left;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
}

fieldset h4,
fieldset label {
  float: none;
  clear: both;
}

.modal .modal-dialog,
fieldset {
  box-sizing: border-box;
}

[data-tns-mode="gallery"] {
  overflow: hidden;
}

[data-tns-mode="gallery"]>a,
[data-tns-mode="gallery"]>div,
[data-tns-mode="gallery"]>li,
[data-tns-mode="gallery"]>span {
  position: absolute;
  -webkit-transition: transform 0s, opacity 0s;
  -moz-transition: transform 0s, opacity 0s;
  transition: transform 0s, opacity 0s;
}

[data-tns-role="nav"] {
  text-align: center;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -1.875rem;
}

[data-tns-role="nav"] button {
  vertical-align: middle;
  background-color: #d4dadc;
  height: 0.75rem;
  width: 0.75rem;
  margin: 0 0.3125rem;
}

[data-tns-role="nav"] button[aria-selected="true"] {
  background-color: #fff;
  border: 0.125rem solid #d4dadc;
}

[data-tns-role="lazy-img"] {
  -webkit-transition: opacity 0.6s;
  -moz-transition: opacity 0.6s;
  transition: opacity 0.6s;
  opacity: 0.6;
}

[data-tns-role="lazy-img"].loaded {
  opacity: 1;
}

[hidden] {
  display: none !important;
}

fieldset .customer-address-col2,
fieldset label {
  display: inline-block;
}

[data-action],
[data-controls],
[data-nav] {
  cursor: pointer;
  border-width: 0;
  padding: 0;
}

[data-tns-hidden="x"] {
  overflow-x: hidden;
}

[data-tns-hidden="y"] {
  overflow-y: hidden;
}

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 999;
}

@media screen and (min-width: 421px) and (max-width: 768px) {
  h1 {
    line-height: 1em;
  }

  .modal .modal-dialog ul {
    padding-left: 1.25rem;
  }
}

@media screen and (max-width: 420px) {
  h1 {
    line-height: 1em;
  }

  .modal .modal-dialog {
    top: 0;
    right: 0;
    left: 0;
    transform: none;
    padding: 4rem 1.25rem;
    overflow: scroll;
  }

  .modal .modal-dialog ul {
    padding-left: 1.25rem;
  }
}

@media screen and (min-width: 976px) {
  .modal .model-pals {
    overflow: auto;
  }

  fieldset {
    margin: 0 0 1.875rem;
  }

  fieldset.plain {
    margin: auto;
    text-align: center;
  }
}

@media screen and (min-width: 769px) and (max-width: 975px) {
  .modal .model-pals {
    overflow: auto;
  }

  fieldset {
    margin: 0 0 1.875rem;
    padding: 1.625rem;
  }

  fieldset.plain {
    margin: auto;
    text-align: center;
  }
}

#modal-neareststores .modal-form,
.imgContainer {
  overflow: hidden;
}

@keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

fieldset {
  width: auto;
  height: auto;
  border-radius: 0.3125rem;
  background-color: #fff;
}

fieldset.plain {
  border-radius: 0;
  box-shadow: none;
  background-color: transparent;
}

fieldset h3 {
  padding: 0 0 0.5rem;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.25rem;
  letter-spacing: 0.0125rem;
  color: #333;
}

@media screen and (min-width: 421px) and (max-width: 768px) {
  fieldset {
    margin: 1rem 0 0;
    padding: 0.75rem;
  }

  fieldset h3 {
    text-align: center;
  }

  fieldset .option-box .card-buttons-box {
    width: 100%;
  }
}

@media screen and (max-width: 420px) {
  fieldset {
    margin: 1rem 0 0;
    padding: 0.75rem;
  }

  fieldset h3 {
    text-align: center;
  }

  fieldset .option-box .card-buttons-box {
    width: 100%;
  }
}

fieldset h4 {
  padding: 0.25rem 0 0.625rem;
  font-size: 0.8125rem;
  font-weight: 700;
  line-height: 1.38;
  letter-spacing: 0.00625rem;
  color: #8e9da2;
}

#bopusSelectEnabledBopusAttrOff .popover .popover-content span,
#modal-neareststores #petco-store-locator-modal .popover .popover-content span,
#spc-bopus-store-info .lsa-hours .popover .popover-content span,
#spc-bopus-store-info .lsa-phone .popover .popover-content span,
#store-hours .popover .popover-content span,
#store-pickup-dropdown .lsa-hours .popover .popover-content span,
#store-pickup-dropdown .lsa-phone .popover .popover-content span {
  float: right;
}

fieldset .option-box {
  margin: 0 0.75rem 0.75rem;
  padding: 0.75rem 0;
  background-color: #f4f6f6;
  border-radius: 0.1875rem;
  clear: both;
}

fieldset .option-box .card-buttons-box {
  margin-top: 0.5625rem;
  line-height: 2.8125rem;
}

fieldset .customer-address-col1 {
  display: inline-block;
  margin-right: 0.375rem;
  vertical-align: top;
}

fieldset .notice-box {
  margin: 1rem 0;
  padding: 0.625rem;
  color: #fc951f;
  background-color: #fff4e8;
  border: 0.0625rem solid #fedcb6;
  border-radius: 0.3125rem;
}

fieldset .notice-box h4,
fieldset .notice-box p {
  color: #fc951f;
}

fieldset .notice-box h4 {
  padding-bottom: 0.25rem;
}

fieldset .notice-box p {
  font-size: 0.8em;
}

@media print {
  #header {
    display: block;
  }

  #footer,
  #mobile-cart,
  #mobile-menu,
  #mobile-search,
  #mobile-stores,
  .banner-promo,
  .copyright,
  .order-confirm .container .col-4.desktop-only {
    display: none;
  }
}

#overlay {
  background-repeat: no-repeat;
  background-color: #fff;
  width: 21.25rem;
  height: 25.5625rem;
  box-shadow: 0 0 0.625rem 0.3125rem rgba(0, 0, 0, 0.1);
  position: absolute;
  left: 105%;
  top: 0;
  z-index: -1;
  opacity: 0;
  transition: opacity 0.3s;
  transition-delay: 0.2s;
}

@media screen and (min-width: 421px) and (max-width: 768px) {
  #overlay {
    display: none;
  }
}

@media screen and (max-width: 420px) {
  #overlay {
    display: none;
  }
}

#overlay.active {
  opacity: 1;
  visibility: visible;
  z-index: 100;
}

.imgContainer {
  display: inline-block;
}

.modal {
  display: none;
}

.store-locator-modal {
  border: none;
}

.store-locator-modal .store-locator-modal-logo img {
  width: 80%;
}

@media screen and (max-width: 420px) {
  .store-locator-modal .store-locator-modal-logo img {
    width: 35%;
  }

  .store-locator-modal button {
    margin-bottom: 1.25rem;
  }
}

@media screen and (min-width: 421px) and (max-width: 768px) {
  .store-locator-modal .store-locator-modal-logo img {
    width: 35%;
  }

  .store-locator-modal button {
    margin-bottom: 1.25rem;
  }
}

.store-locator-modal .store-locator-modal-address p {
  line-height: 1.2em;
}

.store-locator-modal button {
  padding: 0.875rem 1.25rem;
  width: 100%;
}

.store-locator-modal .enter-zip {
  margin: 01.25rem;
}

@media screen and (max-width: 420px) {
  .store-locator-modal .enter-zip {
    margin: 0;
  }
}

@media screen and (min-width: 976px) {
  .store-locator-modal {
    min-width: 59.6875rem;
  }

  .store-locator-modal button {
    width: auto;
  }

  .store-locator-modal .enter-zip {
    width: 46%;
    margin: auto;
  }
}

.store-locator-modal .enter-zip .form-control {
  display: inline-block;
  position: relative;
  width: 100%;
  margin-bottom: 0.4375rem;
}

@media screen and (min-width: 976px) {
  .store-locator-modal .enter-zip .form-control {
    margin-bottom: 1.25rem;
  }

  .store-locator-modal .mobile-second-col {
    display: none;
  }
}

.store-locator-modal .enter-zip .form-control .icon-zip {
  position: absolute;
  right: 0;
  top: 0;
  margin: 0.625rem;
}

.store-locator-modal .enter-zip .form-control input {
  background-color: #f4f6f6;
  border: 0.0625rem solid #8e9da2;
}

.store-locator-modal .enter-zip .form-control input.invalid {
  border-color: #ee3524;
}

.store-locator-modal .btn-disabled {
  color: #666;
}

.store-locator-modal .mobile-second-col {
  float: right;
  margin-top: 2%;
}

.store-locator-modal-logo {
  margin-top: 1.25rem;
}

@media screen and (max-width: 420px) {
  .store-locator-modal-logo {
    margin-top: 0;
  }
}

@media screen and (min-width: 421px) and (max-width: 768px) {
  .store-locator-modal-logo {
    margin-top: 0;
  }
}

.store-locator-modal-address .my-store {
  color: #8e9da2;
}

.store-locator-modal-distance-desktop {
  display: none;
}

@media screen and (min-width: 976px) {
  .store-locator-modal-address a[href^="tel:"] {
    color: #666;
    font-weight: 400;
    cursor: default;
  }

  .store-locator-modal-address a[href^="tel:"] :hover {
    color: #666;
  }

  .store-locator-modal-distance-desktop {
    display: block;
  }
}

.store-locator-modal-distance-mobile {
  margin-top: 0.625rem;
}

.store-locator-modal-availability-desktop {
  display: none;
}

.store-locator-modal-availability-mobile {
  margin-top: 0.375rem;
}

#modal-neareststores {
  max-width: 60.625rem;
  padding: 1.25rem 0.625rem;
}

#modal-neareststores #petco-store-locator-modal {
  margin: 0;
  width: 100%;
}

#modal-neareststores #petco-store-locator-modal .store-hours-message {
  font-size: 0.865em;
}

#modal-neareststores #petco-store-locator-modal a {
  text-decoration: none;
  font-size: 1em;
  font-weight: 400;
}

@media screen and (min-width: 769px) and (max-width: 975px) {
  #modal-neareststores #petco-store-locator-modal .left-column-modal {
    margin: 0;
    width: 43%;
  }

  #modal-neareststores #petco-store-locator-modal .right-column-modal {
    margin: 0;
    width: 57%;
  }
}

@media screen and (min-width: 976px) {
  .store-locator-modal-availability-desktop {
    display: block;
  }

  #modal-neareststores #petco-store-locator-modal .left-column-modal {
    margin: 0;
    width: 43%;
  }

  #modal-neareststores #petco-store-locator-modal .right-column-modal {
    margin: 0;
    width: 57%;
  }

  #modal-neareststores #petco-store-locator-modal .popover {
    width: 22%;
  }
}

#bopusSelectEnabledBopusAttrOff .popover .popover-content strong,
#modal-neareststores #petco-store-locator-modal .popover .popover-content strong,
#spc-bopus-store-info .lsa-hours .popover .popover-content strong,
#spc-bopus-store-info .lsa-phone .popover .popover-content strong,
#store-hours .popover .popover-content strong,
#store-pickup-dropdown .lsa-hours .popover .popover-content strong,
#store-pickup-dropdown .lsa-phone .popover .popover-content strong {
  margin-right: 10%;
}

@media screen and (min-width: 769px) and (max-width: 975px) {
  #modal-neareststores #petco-store-locator-modal .popover {
    width: 22%;
  }
}

@media screen and (min-width: 421px) and (max-width: 768px) {
  #modal-neareststores #petco-store-locator-modal .popover {
    width: 32%;
    left: -0.68375rem !important;
  }
}

@media screen and (max-width: 420px) {
  #modal-neareststores #petco-store-locator-modal .popover {
    width: 57%;
    left: -0.27875rem !important;
  }
}

#bopusSelectEnabledBopusAttrOff .popover,
#store-hours .popover {
  width: 54%;
}

@media screen and (min-width: 421px) and (max-width: 768px) {

  #bopusSelectEnabledBopusAttrOff .popover,
  #store-hours .popover {
    width: 30%;
  }
}

#spc-bopus-store-info.open,
#store-pickup-dropdown.open {
  overflow: visible;
}

#spc-bopus-store-info .lsa-hours .popover,
#spc-bopus-store-info .lsa-phone .popover,
#store-pickup-dropdown .lsa-hours .popover,
#store-pickup-dropdown .lsa-phone .popover {
  width: 59%;
}

@media screen and (min-width: 421px) and (max-width: 768px) {

  #spc-bopus-store-info .lsa-hours .popover,
  #spc-bopus-store-info .lsa-phone .popover,
  #store-pickup-dropdown .lsa-hours .popover,
  #store-pickup-dropdown .lsa-phone .popover {
    width: 31%;
  }
}

.custom-dropdown {
  display: flex;
  width: 100%;
  box-shadow: 0 0 0.4375rem 0 rgba(0, 0, 0, 0.2);
}

.custom-dropdown .text-primary {
  margin-top: -0.3125rem;
  font-size: 0.7em;
  font-weight: 400;
}

.custom-dropdown span {
  display: flex;
}

.custom-dropdown li {
  list-style: none;
  padding: 0 0.9375rem;
  clear: both;
  border-top: 0.0625rem solid #d4dadc;
}

.custom-dropdown li:first-child {
  border-top: transparent;
}

.custom-dropdown .js-pet-img {
  width: 2.1875rem;
  height: 2.1875rem;
  float: left;
  margin: 0.625rem;
  border-radius: 50%;
  clip-path: circle(0.9375rem at center);
}

.custom-dropdown__center {
  align-self: center;
}

.custom-dropdown__dropdown {
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 37.5rem;
  max-width: 100%;
  background: #fff;
  border-radius: 0.3125rem;
  box-shadow: 0 0 1.0625rem 0.125rem rgba(0, 0, 0, 0.25);
  z-index: 100;
}

.custom-dropdown__dropdown .js-pet-img {
  width: 3.4375rem;
  height: 3.4375rem;
  border-radius: 50%;
  clip-path: circle(2.5rem at center);
}

.custom-dropdown__cat-option {
  display: flex;
  height: 4.6875rem;
}

.center-content>div {
  margin: auto;
}

.reset-line-height {
  line-height: 1.2;
}

.no-margin-left {
  margin-left: 0 !important;
}

.no-margin-right {
  margin-right: 0 !important;
}

.no-padding-left {
  padding-left: 0 !important;
}

.no-padding-right {
  padding-right: 0 !important;
}

.line-height-fix {
  line-height: 1.25rem;
}

.bottom-adjustment {
  margin-bottom: -0.625rem;
}

@media screen and (max-width: 420px) {
  .bottom-adjustment {
    margin-bottom: -0.3125rem;
  }
}

.fifteen-px {
  font-size: 0.9375rem;
}

.product-tile .padding-sm {
  padding: 0.625rem;
}