.petCard {
  align-items: center;
  border-radius: 0.3125rem;
  border: 0.0625rem #ccc solid;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  font-size: 0.9375rem;
  height: 6.25rem;
  justify-content: flex-end;
  margin: 0.625rem 0;
  padding: 0.9375rem;
  width: 6.25rem;
}

.petCards {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.marked-pet {
  border: 0.0625rem;
  background-color: blue;
}

.pet-selection-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}
