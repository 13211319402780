.StoreLocator-v2 #button-link {
    background-color: transparent;
    padding: 0;
    border: unset;
    font-family: var(--primary-font);
    font-weight: bold;
    text-decoration: underline;
    font-size: 16px;
    letter-spacing: -0.03em;
    text-align: left;
    color: var(--primary-color-two);
    cursor: pointer;
}

.StoreLocator-v2 .tell-text {
    margin: 0.5rem 0;
    font-size: 0.875rem;
}

.StoreLocator-v2 .zip-code input {
    border: none;
    height: 54px;
    width: 100%;
    margin: 0;
    padding: 0 1rem;
    text-indent: 0;
    color: #656565;
}

.StoreLocator-v2 .zip-code input::placeholder {
    overflow: visible;
}

.StoreLocator-v2 .input-wrapper {
    max-width: 99%;
}

.StoreLocator-v2 .InputWrapper {
    width: 100%;
    height: 100%;
    margin: 0;
}

.StoreLocator-v2 .zip-code {
    display: block;
    padding: 0px;
}

.StoreLocator-v2 .select-store-button {
    margin-bottom: 10px;
}

.StoreLocator-v2 .store-card {
    border: 1px solid #CCCCCC;
    border-radius: 6px;
    margin-bottom: 17px;
}

.StoreLocator-v2 .store-card-input {
    position: relative;
}

.StoreLocator-v2 .store-card-input .icon-dropdown {
    pointer-events: none;
}

.StoreLocator-v2 .store-card-input .Icon {
    position: absolute;
    top: 1.2rem;
    right: 1rem;
}

.StoreLocator-v2 .store-list {
    padding: 0 1rem;
}

.StoreLocator-v2 .store-list-section {
    border: 1px solid #656565;
}

.StoreLocator-v2 .store-list-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 1.25rem 0 0.6rem;
    border-top: 1px solid #E5E8F1;
}

.StoreLocator-v2 .store-list-title {
    color: var(--primary-color);
    font-size: 1rem;
    font-weight: 700;
    min-width: 100px;
    line-height: 1.125rem;
    letter-spacing: 0.025rem;
}

.StoreLocator-v2 .store-list-item .distinguished-address {
    width: 150px;
    font-size: 12px;
    font-family: var(--primary-font);
    font-weight: 400;
}

.StoreLocator-v2 .store-list-item span {
    color: #656565;
    min-width: 50px;
    margin-left: 10px;
    line-height: 1.125rem;
    letter-spacing: 0.025rem;
}

.StoreLocator-v2 .store-list-actions {
    padding: 1rem 0;
    display: flex;
    justify-content: center;
    border-top: 1px solid #E5E8F1;
}

.StoreLocator-v2 .selected-store-title {
    padding: 0;
    margin-bottom: 0;
}



.StoreLocator-v2 .selected-store-header {
    display: grid;
    width: 100%;
    grid-auto-flow: column;
    grid-template-columns: 3.75rem auto;
    align-items: center;
    grid-gap: 1.3125rem;
    margin-left: 0px;
}

.StoreLocator-v2 .store-not-found-msg {
    margin-top: 0.9375rem;
    text-align: center;
    color: var(--primary-color);
}

.StoreLocator-v2 .load-more-button {
    font-size: 1rem;
    font-weight: 700;
    color: var(--primary-color);
    border-bottom: 1px solid var(--primary-color);
    cursor: pointer;
    line-height: 1.375rem;
    letter-spacing: 0.5px;
}

.StoreLocator-v2 .Step__Header-v2 {
    border-bottom: none;
    margin-bottom: 0;
}

@media (min-width: 930px) {
    .StoreLocator-v2 .store-container {
        margin: 1rem 0;
        height:  3.5rem;
        position: relative;
    }

    .StoreLocator-v2 .store-card {
        position: absolute;
        z-index: 50;
        background-color: #fff;
        width: 28rem;
    }

    .StoreLocator-v2 .store-container .Button {
        font-family: var(--primary-font-bold);
        margin: 0 0 0 1rem;
        float: right;
    }
}