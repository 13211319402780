.bg-layer {
  position: fixed;
  background: rgb(255 255 255 / 80%);
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 999;
}
 
.savings-modal.modal-popup {
  position: fixed;
  top: 20vh;
  left: 0;
  right: 0;
  margin-top: 20vh;
  margin: 0 auto;
  max-width: 521px;
  width: 100%;
  height: auto;
  max-height: 70vh;
  background: #fff;
  border-radius: 0.25rem;
  box-shadow: 0 0.125rem 1.0625rem 0 rgb(0 0 0 / 18%);
  padding: 38px 45px 155px 45px;
  overflow: auto;
  z-index: 1000;
  box-sizing: border-box;
}
 
.savings-modal .close-icon {
  cursor: pointer;
  position: absolute;
  right: 45px;
  top: 26px;
  z-index: 12;
  height: 24px;
  width: 24px;
}
.savings-modal .close-icon img{
  height: 24px;
  width: 24px;
}

.savings-modal .modal-header {
  display: flex;
  justify-content: center;
  margin-bottom: 52px;
}

.savings-modal .modal-title {
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1.5rem;
  text-align: center;
  color: #000000;
}

.savings-modal .modal-body h5 {
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 1.5rem;
  color: #000000;
  margin-bottom: 12px;
}

.savings-modal .modal-body p {
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.375rem;
  color: #333333;
  margin-bottom: 19px;
}

.savings-modal .modal-footer {
  display: flex;
  grid-gap: 10px;
  justify-content: flex-end;
}

.savings-modal .modal-footer .Button-v2{
  margin: 0;
}

@media only screen and (max-width: 576px){
  .savings-modal.modal-popup {
    max-width: 350px;
    padding: 33px 36px 33px 28px;
  }
  .savings-modal .modal-header {
    margin-bottom: 28px;
  }
  .savings-modal .modal-body h5 {
    margin-bottom: 8px;
    font-size: 1rem;
  }
  .savings-modal .modal-body p {
    margin-bottom: 10px;
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
  .savings-modal .close-icon {
    right: 20px;
    top: 20px;
    height: 16px;
    width: 16px;
  }
  .savings-modal .close-icon img{
    height: 16px;
    width: 16px;
  }
}